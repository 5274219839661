import "./styles.scss";
import { Container } from "react-bootstrap";
import { Timeline } from "antd";
import Banner from "../../Components/Banner";
import { Helmet } from "react-helmet";

function HowtoBook() {
  return (
    <div className="howtobooking column-padding-b">
      <Helmet>
        <title>How to Book</title>
        <meta
          name="description"
          content="Our guide on 'How to Book' walks you through the steps for reserving your cruise, from selecting a package to finalizing your booking."
        />
        <meta property="og:title" content="How to Book" />
        <meta
          property="og:description"
          content="Our guide on 'How to Book' walks you through the steps for reserving your cruise, from selecting a package to finalizing your booking."
        />
      </Helmet>
      <Banner link="How to Book" link2=""/>
      <Container className="column-padding-b">
        <Timeline
          items={[
            {
              children: (
                <h4 className="font-regular basic-texts font-faded-black">
                  Book your cruise online or contact your preferred Cruise
                  Specialist to book one of the Cruise Vacations featured on
                  this site. In order to process your request quicker, we need
                  following information:
                </h4>
              ),
            },
            {
              children: (
                <h4 className="font-regular bold-700 basic-texts font-faded-black">
                  Name of Ship and departure date
                </h4>
              ),
              dot: <p className="font-regular mb-4">1</p>,
            },
            {
              children: (
                <h4 className="font-regular bold-700 basic-texts font-faded-black">
                  Accommodation type :{" "}
                  <span className="font-regular bold-400">
                    {" "}
                    &nbsp;Suite, Balcony or Ocean-View.
                  </span>
                </h4>
              ),
              dot: <p className="font-regular mb-4">2</p>,
            },
            {
              children: (
                <h4 className="font-regular bold-700 basic-texts font-faded-black">
                  Guest Information :{" "}
                  <span className="font-regular bold-400">
                    {" "}
                    &nbsp; Full names (as it appears on the passport), date of
                    birth, nationality and passport details.
                  </span>
                </h4>
              ),
              dot: <p className="font-regular mb-4">3</p>,
            },
            {
              children: (
                <h4 className="font-regular bold-700 basic-texts font-faded-black">
                  Contact Details :{" "}
                  <span className="font-regular bold-400">
                    {" "}
                    &nbsp; The name and number of who can be contacted in the
                    event of a holiday emergency.
                  </span>
                </h4>
              ),
              dot: <p className="font-regular mb-4">4</p>,
            },
            {
              children: (
                <h4 className="font-regular bold-700 basic-texts font-faded-black">
                  Dinner Seating Preferences :{" "}
                  <span className="font-regular bold-400">
                    {" "}
                    &nbsp; Table size, time of sitting and whether you are
                    travelling with another party. Please note that while every
                    effort will be made to meet these preferences, we cannot
                    guarantee to do so in all cases.
                  </span>
                </h4>
              ),
              dot: <p className="font-regular mb-4">5</p>,
            },
            {
              children: (
                <h4 className="font-regular bold-700 basic-texts font-faded-black">
                  Special Requirements :{" "}
                  <span className="font-regular bold-400">
                    {" "}
                    &nbsp; Special dietary or medical requirements.
                  </span>
                </h4>
              ),
              dot: <p className="font-regular mb-4">6</p>,
            },
            {
              children: (
                <h4 className="font-regular bold-700 basic-texts font-faded-black">
                  Mobility :{" "}
                  <span className="font-regular bold-400">
                    {" "}
                    &nbsp; Please make us aware of guests who are not fully
                    mobile or who use wheelchairs at any time.
                  </span>
                </h4>
              ),
              dot: <p className="font-regular mb-4">7</p>,
            },
            {
              children: (
                <h4 className="font-regular bold-700 basic-texts font-faded-black">
                  Special Occasions :{" "}
                  <span className="font-regular bold-400">
                    {" "}
                    &nbsp; Birthday, Honeymoon or Anniversary or Family Get
                    Together.
                  </span>
                </h4>
              ),
              dot: <p className="font-regular mb-4">8</p>,
            },
            {
              children: (
                <h4 className="font-regular bold-700 basic-texts font-faded-black">
                  Deposit :{" "}
                  <span className="font-regular bold-400">
                    {" "}
                    &nbsp; After you have booked, a booking reference number is
                    provided. At this time a deposit is required or the full
                    amount for bookings made within 90 days of departure.
                  </span>
                </h4>
              ),
            },
            {
              children: (
                <h4 className="font-regular bold-700 basic-texts font-faded-black">
                  Final Payment :{" "}
                  <span className="font-regular bold-400">
                    {" "}
                    &nbsp; Cruise Lines must receive the balance of your fare as
                    per the time frame given in the initial letter of offer or
                    confirmation failing which Cruise lines reserve the right to
                    resell the accommodation held in your name. This will also
                    result in cancellation charges.
                  </span>
                </h4>
              ),
            },
            {
              children: (
                <h4 className="font-regular bold-700 basic-texts font-faded-black">
                  What happens next ?{" "}
                  <span className="font-regular bold-400">
                    {" "}
                    &nbsp; Complete your pre-voyage information, set up your
                    onboard expense account and print your boarding pass. If you
                    experience any difficulty, please contact your Cruise
                    Specialist.
                  </span>
                </h4>
              ),
            },
          ]}
        />
      </Container>
    </div>
  );
}

export default HowtoBook;
