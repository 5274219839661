import viking from "../../assets/logos/viking.png";
import quark from "../../assets/logos/quark.png";
import hurtigruten_exped from "../../assets/logos/hurtigruten_exped.png";
import silversea_exped from "../../assets/logos/silversea_exped.png";
import aurora from "../../assets/logos/aurora.jpg";
import albatros from "../../assets/logos/albatros.jpg";
import { CDN_BASE_URL } from "../../Config/apiConfig";

export const expeditionCruiseLines = [
  {
    title: "Albatros Expeditions",
    logo: albatros,
    online: false,
    resUrl: "",
    url: "/other_cruise_lines/albatros_expeditions",
    cruiseid: "0",
    urlName: "albatros_expeditions",
    banner: `${CDN_BASE_URL}/img/ships/Albatros/albatros.jpg`,
    overview:
      "Our expedition cruise ships have been carefully selected to provide the ultimate expedition experience in polar regions. With an established reputation and access to superior expedition vessels, we have been sending travellers to the Polar regions for three decades.",

    aboutships:
      "Our expedition cruise ships have been carefully selected to provide the ultimate expedition experience in polar regions. With an established reputation and access to superior expedition vessels, we have been sending travellers to the Polar regions for three decades.",
    brochures: [],
    cruises: [
      {
        shipname: "Ocean Albatros",
        resShipUrl: "",
      },
      {
        shipname: "Ocean Victory",
        resShipUrl: "",
      },
    ],
  },
  {
    title: "Aurora Expeditions",
    logo: aurora,
    online: false,
    resUrl: "",
    url: "/other_cruise_lines/aurora_expeditions",
    cruiseid: "0",
    urlName: "aurora_expeditions",
    banner: `${CDN_BASE_URL}/img/ships/Aurora/aurora.jpg`,
    overview:
      "Our vessels are small enough to go where others simply can’t. Designed in close consultation with our expedition team, we drew on centuries of collective experience to map out our ships: purpose-built for expeditions to the most remote places on earth.With a maximum of 130 passengers on the Greg Mortimer and the Sylvia Earle, and the Douglas Mawson (setting sail in 2025) takes a maximum of 154 passengers on our Small Ship cruises and a maximum of 130 adventurers onboard our expeditions, our fleet is redefining expedition cruising for the future.",

    aboutships:
      "Our vessels are small enough to go where others simply can’t. Designed in close consultation with our expedition team, we drew on centuries of collective experience to map out our ships: purpose-built for expeditions to the most remote places on earth.With a maximum of 130 passengers on the Greg Mortimer and the Sylvia Earle, and the Douglas Mawson (setting sail in 2025) takes a maximum of 154 passengers on our Small Ship cruises and a maximum of 130 adventurers onboard our expeditions, our fleet is redefining expedition cruising for the future.",
    brochures: [],
    cruises: [
      {
        shipname: "The Douglas Mawson",
        resShipUrl: "",
      },
      {
        shipname: "The Sylvia Earle",
        resShipUrl: "",
      },
      {
        shipname: "The Greg Mortimer",
        resShipUrl: "",
      },
    ],
  },
  {
    title: "Hurtigruten Expeditions",
    logo: hurtigruten_exped,
    online: false,
    resUrl: "",
    url: "/other_cruise_lines/hurtigrutenexpeditions",
    cruiseid: "0",
    urlName: "hurtigrutenexpeditions",
    banner: `${CDN_BASE_URL}/img/ships/Hurtigruten/HX_ML.jpg`,
    overview:
      "Since 1896, HX have been taking curious travellers on mindful expeditions to the most remote and awe-inspiring places on the planet. To travel with HX is to open yourself to a world of awe. From cultural encounters to wildlife wonders, what you see and learn on your trip will inspire and energise you. And when you come back home, you’ll have a deeper, richer love for the planet we all share. On HX, adventure is all-inclusive meaning daily adventures come at no extra cost, on top of all-inclusive meals, drinks, WiFi and more.",

    aboutships:
      "Since 1896, HX have been taking curious travellers on mindful expeditions to the most remote and awe-inspiring places on the planet. To travel with HX is to open yourself to a world of awe. From cultural encounters to wildlife wonders, what you see and learn on your trip will inspire and energise you. And when you come back home, you’ll have a deeper, richer love for the planet we all share. On HX, adventure is all-inclusive meaning daily adventures come at no extra cost, on top of all-inclusive meals, drinks, WiFi and more.",
    brochures: [],
    cruises: [
      {
        shipname: "MS Maud",
        resShipUrl: "",
      },
      {
        shipname: "MS Santa Cruz II",
        resShipUrl: "",
      },
      {
        shipname: "MS Spitsbergen",
        resShipUrl: "",
      },
      {
        shipname: "MS Fridtjof Nansen",
        resShipUrl: "",
      },
      {
        shipname: "MS Roald Amundsen",
        resShipUrl: "",
      },
      {
        shipname: "MS Fram",
        resShipUrl: "",
      },
    ],
  },
  {
    title: "Quark Expeditions",
    logo: quark,
    online: true,
    resUrl:
      "https://res.cruisemaster-me.com/swift/cruise/cruiseline/quark-expeditions-?siid=177652&cruiseline=8161",
    url: "/other_cruise_lines/quark_expeditions",
    cruiseid: "8161",
    urlName: "quark_expeditions",
    banner: `${CDN_BASE_URL}/img/ships/Quark Expeditions/Quark_ML.jpg`,
    overview:
      "We’ve spent the last three decades taking travelers to remote parts the Arctic and Antarctic. Since the first tourist transit of the Northeast Passage in 1991, we’ve continued to deliver industry breaking firsts, including the discovery of the Emperor penguin rookery at Snow Hill Island and the launch of our new game-changing ship Ultramarine designed to go beyond the familiar in polar expedition, to discover new places, and immerse you in the best the regions have to offer. ",
    aboutships:
      "Quark Expeditions operates more ships in the polar regions than any other operator. Our vessels, which are optimized for polar travel, range from 128 to 199 passengers and provide an excellent home base for your polar expedition. Our fleet features ice-strengthened hulls for superior navigation in ice-packed polar waters and boast exceptional levels of comfort and services. ",
    brochures: [],
    cruises: [
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/quark-expeditions--ocean-adventurer?ship=13594",
        shipname: "Ocean Adventurer",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/quark-expeditions--ocean-explorer?ship=15349",
        shipname: "Ocean Explorer",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/quark-expeditions--ultramarine?ship=14649",
        shipname: "Ultramarine",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/quark-expeditions--world-explorer?ship=14177",
        shipname: "World Explorer",
      },
    ],
  },
  {
    title: "Silversea Expeditions",
    logo: silversea_exped,
    online: false,
    resUrl: "",
    url: "/other_cruise_lines/silverseaexpeditions",
    cruiseid: "8115",
    urlName: "silverseaexpeditions",
    banner: `${CDN_BASE_URL}/img/ships/Silversea/Silversea.jpg`,
    overview:
      "Silversea’s purpose-built expedition ships can transport you to the furthermost boundaries of the planet",

    aboutships:
      "There is something uniquely satisfying about a smaller ship. The intimacy. The friendly camaraderie. The excitement of exploring secluded harbours where others cannot go. Our intimate, ultra-luxury ships can sail up narrow waterways into the heart of a city, or tie up right at the pier while others must anchor off shore. And for those who yearn to explore the new and unknown, Silversea’s 4 expedition ships can transport you to the furthermost boundaries of the planet. Travel is the elixir of life at Silversea and every cruise is a voyage of discovery. Come explore the possibilities on a Silversea cruise.",
    brochures: [],
    cruises: [
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/silversea--silver-cloud-expedition?ship=13758",
        shipname: "Silver Cloud",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/silversea--silver-endeavour?ship=15220",
        shipname: "Silver Endeavour",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/silversea--silver-origin?ship=14504",
        shipname: "Silver Origin",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/silversea--silver-wind?ship=13286",
        shipname: "Silver Wind",
      },
    ],
  },
  {
    title: "Viking Expedition Cruises",
    logo: viking,
    online: false,
    resUrl: "",
    url: "/other_cruise_lines/viking_expedition",
    cruiseid: "8115",
    urlName: "viking_expedition",
    banner: `${CDN_BASE_URL}/img/ships/Viking/viking_exped_wide.jpg`,
    overview:
      "First, we invented modern river cruising. Then, we redefined ocean cruising. Now, experience all the comfort and elegance of our award-winning fleet with an expedition ship built specifically to explore the world’s most remote destinations and allow you to immerse yourself in these regions.",

    aboutships:
      "There is something uniquely satisfying about a smaller ship. The intimacy. The friendly camaraderie. The excitement of exploring secluded harbours where others cannot go. Our intimate, ultra-luxury ships can sail up narrow waterways into the heart of a city, or tie up right at the pier while others must anchor off shore. And for those who yearn to explore the new and unknown, Silversea’s 4 expedition ships can transport you to the furthermost boundaries of the planet. Travel is the elixir of life at Silversea and every cruise is a voyage of discovery. Come explore the possibilities on a Silversea cruise.",
    brochures: [],
    cruises: [
      {
        resShipUrl: "",
        shipname: "Viking Octantis",
      },
      {
        resShipUrl: "",
        shipname: "Viking Polaris",
      },
    ],
  },
];
