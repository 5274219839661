import React from "react";
import { Col, Row } from "react-bootstrap";
import "./styles.scss";
import { useNavigate } from "react-router-dom";

interface CruiseListProps {
  heading: string;
  cruiseType: string;
  CruiseLine: Array<{
    urlName: string;
    url: string;
    logo: string;
  }>;
}

const baseClass = "general-shadow flex center card-animated";

const getClassName = (urlName: string) => {
  const specificClass =
    {
      pnocruises: "cruise-card-pno",
      costa: "cruise-card-costa",
      disneycruiseline: "cruise-card-disney",
      hollandamerica: "holland-card-long",
      msccruises: "cruise-card-msc",
      resortsworldcruises: "cruise-card-long",
      oceania: "cruise-card-oceana",
      regent_seven: "cruise-card-regent",
      viking_ocean: "cruise-card-viking",
      windstar_cruise: "cruise-card-windstar",
      amawaterways: "cruise-card-ama",
      viking_cruises: "cruise-card-viking",
      viking_expedition: "cruise-card-viking",
      celestyal_cruises: "cruise-card-celestyal",
      variety_cruises: "cruise-card-variety",
      albatros_expeditions: "cruise-card-celestyal",
      royalcaribbeaninternational:"cruise-card-costa",
      theritzcarlton:"holland-card-long"
    }[urlName] || "cruise-card";

  return `${specificClass} ${baseClass}`;
};

const CruiseListComponent: React.FC<CruiseListProps> = ({
  heading,
  CruiseLine,
}) => {
  const navigate = useNavigate();

  const gotoCruiseDetails = (cruise: { url: string }) => {
    window.scrollTo(0, 0);
    navigate(cruise.url, { state: { CruiseLine: cruise } });
  };

  return (
    <Row className="full-width flex center">
      <div className="flex center mt-16 mb-16">
        <div className="more-divider" />
        <div className="cruise-divider-btn flex center nowrap font-medium font-primary mh-16">
          {heading}
        </div>
        <div className="more-divider" />
      </div>
      {CruiseLine.map((item, index) => (
        <Col
          lg={2}
          md={3}
          sm={6}
          xs={12}
          key={index}
          className={getClassName(item.urlName)}
          onClick={() => gotoCruiseDetails(item)}
        >
          <img
            src={item.logo}
            style={{ backgroundSize: "contain" }}
            alt="ship logo"
          />
        </Col>
      ))}
    </Row>
  );
};

export default CruiseListComponent;
