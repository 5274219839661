// import "./styles.scss";
// // import { Button } from "antd";
// import { PortList } from "../../DataStore/ports";
// import { Col, Container, Row } from "react-bootstrap";
// import "./styles.scss";
// import { DestinationList } from "../../DataStore/destinationList";
// // import { Divider } from "antd";
// import { Input } from "antd";
// import { FiSearch } from "react-icons/fi";
// import { useEffect, useRef, useState } from "react";
// import empty from "../../assets/img/empty.png";
// // import { Pagination } from "antd";
// import Banner from "../../Components/Banner";
// import BookNow from "../../Components/BookNow";
// import { Helmet } from "react-helmet";

// function DeparturePorts() {
//   const searchDiv = useRef<HTMLDivElement>(null);

//   const [currentPage, setCurrentPage] = useState(1);
//   const [initialRender, setInitialRender] = useState(true);
//   // const [searchQuery, setSearchQuery] = useState("");

//   useEffect(() => {
//     setInitialRender(false);
//   }, []);

//   useEffect(() => {
//     if (!initialRender) {
//       scrollToDiv();
//     }
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [currentPage]);

//   const scrollToDiv = () => {
//     if (searchDiv.current) {
//       searchDiv.current.scrollIntoView({ behavior: "smooth" });
//     }
//   };

//   // const onPageChange = (page: number) => {
//   //   setCurrentPage(page);
//   // };

//   // const filteredDestinations = DestinationList?.filter((item) =>
//   //   item.to.toLowerCase().includes(searchQuery.toLowerCase())
//   // );

//   // const startIndex = (currentPage - 1) * 9;
//   // const endIndex = Math.min(startIndex + 9, filteredDestinations.length);

//   // const currentDestinations = filteredDestinations.slice(startIndex, endIndex);

//   return (
//     <>
//       <div className="destinations-screen column-padding-b">
//         <Helmet>
//           <title>Departure Ports</title>
//           {/* <meta
//             name="description"
//             content="Discover the different themes of each destination. Cruise to the most sought-after landmarks in Europe, Alaska, Japan, the Caribbean, the Mediterranean, Canada & New England, and more. Whether you are looking forward to a city break, a romantic getaway, a family or beach holiday, a cultural or gastronomic trip, alone or with friends, you will be spoiled for choice. Each port of call offers its own unique blend of history, culture, scenic beauty, geography, adventure, and activities."
//           />
//           <meta property="og:title" content="Explore Destinations" /> */}
//           {/* <meta
//             property="og:image"
//             content={currentDestinations[22]?.imagepath}
//           />
//           <script type="application/ld+json">
//             {JSON.stringify(structuredData)}
//           </script> */}
//           {/* <meta
//             property="og:description"
//             content="Discover the different themes of each destination. Cruise to the most sought-after landmarks in Europe, Alaska, Japan, the Caribbean, the Mediterranean, Canada & New England, and more. Whether you are looking forward to a city break, a romantic getaway, a family or beach holiday, a cultural or gastronomic trip, alone or with friends, you will be spoiled for choice. Each port of call offers its own unique blend of history, culture, scenic beauty, geography, adventure, and activities."
//           /> */}
//         </Helmet>
//         <Banner link="Departure Ports" />
//         <Container>
//           <div className="flex flex-column center">
//             <h2 className="font-regular para font-para-black mb-32 text-align-center">
//               Discover the different themes of each destination. Cruise to the
//               most sought-after landmarks in Europe, Alaska, Japan, the
//               Caribbean, the Mediterranean, Canada & New England, and more.
//               Whether you are looking forward to a city break, a romantic
//               getaway, a family or beach holiday, a cultural or gastronomic
//               trip, alone or with friends, you will be spoiled for choice. Each
//               port of call offers its own unique blend of history, culture,
//               scenic beauty, geography, adventure, and activities
//             </h2>
//           </div>
//           <div className="full-width flex center">
//             <div className="bg-white mb-32 dest-input" ref={searchDiv}>
//               <Input
//                 size="large"
//                 placeholder="Search ports"
//                 prefix={<FiSearch style={{ marginRight: "10px" }} />}
//                 onChange={(e) => {
//                   setSearchQuery(e.target.value);
//                   setCurrentPage(1);
//                 }}
//                 style={{
//                   borderRadius: 0,
//                   borderColor: "white",
//                   outline: "none",
//                   boxShadow: "none",
//                   backgroundColor: "white",
//                 }}
//               />
//             </div>
//           </div>
//           <Row>
//             {PortList?.length > 0 ? (
//               PortList?.map((item: any, key: number) => {
//                 return (
//                   <Col
//                     sm={12}
//                     md={4}
//                     lg={4}
//                     className="mb-32"
//                     key={key}
//                     onClick={() => {
//                       window.open(`${item?.moreInfo}`, "_self");
//                     }}
//                   >
                    
//                   </Col>
//                 );
//               })
//             ) : (
//               <div className="flex center flex-column">
//                 <img src={empty} alt="empty-result" height={100} />
//                 <h4 className="description-text font-medium font-faded-black">
//                   No Result found..!
//                 </h4>
//               </div>
//             )}
//           </Row>
//           {/* <Divider type="horizontal" /> */}
//           {/* <div className="flex center">
//             <Pagination
//               defaultCurrent={1}
//               current={currentPage}
//               total={filteredDestinations.length}
//               pageSize={9}
//               onChange={onPageChange}
//               responsive
//               showSizeChanger={false}
//             />
//           </div> */}
//         </Container>
//       </div>
//       <BookNow />
//     </>
//   );
// }


const DeparturePorts = () => {
  return (
    <div>Ports</div>
  )
}

export default DeparturePorts;
