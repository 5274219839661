import { Col, Container, Row } from "react-bootstrap";
import { Button, Divider } from "antd";
import { useState } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { OfflineCruiseModal } from "../../Components/Modals/OfflineCruiseModal";
import BrochureSlides from "../../Components/Slides/BrochureSlides";
import { CruiseLineHeader } from "./CruiseLineHeader";
import "./styles.scss";
import { expeditionCruiseLines } from "../../DataStore/CruiseLines/expeditionCruiseLines";
import { luxuryCruiseLines } from "../../DataStore/CruiseLines/luxuryCruiseLines";
import { nicheCruiseLines } from "../../DataStore/CruiseLines/nicheCruiseLines";
import { popularCruiseLines } from "../../DataStore/CruiseLines/popularCruiseLines";
import { riverCruiseLines } from "../../DataStore/CruiseLines/riverCruiseLines";

interface CruisesType {
  resShipUrl: string;
  shipname: string;
}

function CruiseLine() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [expand, setExpand] = useState(false);

  const { urlName } = useParams();

  const CruiseLines = [
    ...expeditionCruiseLines,
    ...luxuryCruiseLines,
    ...nicheCruiseLines,
    ...popularCruiseLines,
    ...riverCruiseLines,
  ];

  const CruiseLine = CruiseLines?.filter((item: any) => {
    return item.urlName === urlName;
  });

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const structuredData = CruiseLine[0]?.cruises.map(
    (breadcrumb: CruisesType) => ({
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": [
        {
          "@type": "ListItem",
          "position": 1,
          "name": breadcrumb?.shipname,
          "item": breadcrumb?.resShipUrl,
        },
      ],
    })
  );

  return (
    <div className="cruiseline">
      <Helmet>
        <title>{CruiseLine[0]?.title}</title>
        <meta name="description" content={CruiseLine[0]?.overview} />
        <meta property="og:title" content={CruiseLine[0]?.title} />
        {structuredData?.map((item: any) => (
          <script type="application/ld+json">{JSON.stringify(item)}</script>
        ))}
        <meta property="og:image" content={CruiseLine[0]?.banner} />
        <meta property="og:description" content={CruiseLine[0]?.overview} />
      </Helmet>
      <div
        className="ships-intro-common mb-32"
        style={{ position: "relative" }}
      >
        <img
          src={CruiseLine[0]?.banner}
          className="ship-image-large"
          alt="ship"
        />
      </div>

      <div className="column-padding-b">
        <Container>
          <div className="mb-32">
            <CruiseLineHeader CruiseLine={CruiseLine[0]} />
            {CruiseLine[0]?.brochures &&
            CruiseLine[0]?.brochures?.length > 0 ? (
              <Row className="mb-32">
                <Col lg={"8"} md="12">
                  <div className="flex full-height flex-row">
                    <h2 className="font-regular para font-para-black opacity-80 text-align-left m-0 flex center">
                      {CruiseLine[0]?.overview}
                    </h2>
                  </div>
                </Col>
                <Col lg={"1"} md="1" className="flex center">
                  <Divider type="vertical" className="full-height" />
                </Col>
                <Col lg={"3"} md="12">
                  <BrochureSlides Brochures={CruiseLine[0]?.brochures} />
                </Col>
              </Row>
            ) : (
              <Row className="mb-32">
                <div className="flex full-height flex-row">
                  <h2 className="font-regular para font-para-black opacity-80 justified m-0 flex center">
                    {CruiseLine[0]?.overview}
                  </h2>
                </div>
              </Row>
            )}

            <h1 className="font-medium font-black mb-32">Ships</h1>
            {CruiseLine[0]?.urlName === "nicko_cruises" ? (
              <>
                <div className="flex center flex-row mt-16 mb-32">
                  <div className="more-divider" />
                  <div className="cruise-divider-btn flex center nowrap font-medium font-primary mh-16">
                    RIVER
                  </div>
                  <div className="more-divider" />
                </div>
                <div className="flex center">
                  <Row
                    className={
                      CruiseLine[0]?.cruises?.length > 4
                        ? "full-width flex"
                        : "full-width flex center"
                    }
                    style={{
                      maxWidth: "1164px",
                    }}
                  >
                    {CruiseLine[0]?.cruises
                      ?.slice(0, 19)
                      ?.map((item: CruisesType, index: number) => {
                        return (
                          <Col xs={12} sm={6} md={4} lg={4} xl={3} key={index}>
                            <div
                              className={
                                CruiseLine[0]?.online
                                  ? "ship-card flex center p-24 pointer  card-animated-ships"
                                  : "ship-card flex center p-24"
                              }
                              onClick={() =>
                                window.open(`${item?.resShipUrl}`, "_self")
                              }
                            >
                              <h4
                                className="font-regular opacity80 text-ships text-align-center"
                                style={{ marginBottom: "-1px" }}
                              >
                                {item?.shipname}
                              </h4>
                            </div>
                          </Col>
                        );
                      })}
                  </Row>
                </div>
                <div className="flex center flex-row mt-16 mb-32">
                  <div className="more-divider" />
                  <div className="cruise-divider-btn flex center nowrap font-medium font-primary mh-16">
                    OCEAN
                  </div>
                  <div className="more-divider" />
                </div>
                <div className="flex center">
                  <Row
                    className={
                      CruiseLine[0]?.cruises?.length > 4
                        ? "full-width flex"
                        : "full-width flex center"
                    }
                    style={{
                      maxWidth: "1164px",
                    }}
                  >
                    {CruiseLine[0]?.cruises
                      ?.slice(19, 20)
                      ?.map((item: any, index: number) => {
                        return (
                          <Col xs={12} sm={6} md={4} lg={4} xl={3}>
                            <div
                              className={
                                CruiseLine[0]?.online
                                  ? "ship-card flex center p-24 pointer  card-animated-ships"
                                  : "ship-card flex center p-24"
                              }
                              onClick={() =>
                                window.open(`${item?.resShipUrl}`, "_self")
                              }
                            >
                              <h4
                                className="font-regular opacity80 text-ships text-align-center"
                                style={{ marginBottom: "-1px" }}
                              >
                                {item?.shipname}
                              </h4>
                            </div>
                          </Col>
                        );
                      })}
                  </Row>
                </div>
              </>
            ) : (
              <div className="flex center">
                <Row
                  className={
                    CruiseLine[0]?.cruises?.length > 4
                      ? "full-width flex"
                      : "full-width flex center"
                  }
                  style={{
                    maxWidth: "1164px",
                  }}
                >
                  {CruiseLine[0]?.cruises?.length > 12 ? (
                    <>
                      {CruiseLine[0]?.cruises
                        .slice(0, 12)
                        ?.map((item: any, index: number) => {
                          return (
                            <Col xs={12} sm={6} md={4} lg={4} xl={3}>
                              <div
                                className={
                                  CruiseLine[0]?.online
                                    ? "ship-card flex center p-24 pointer  card-animated-ships"
                                    : "ship-card flex center p-24"
                                }
                                onClick={() =>
                                  window.open(`${item?.resShipUrl}`, "_self")
                                }
                              >
                                <h4
                                  className="font-regular opacity80 text-ships text-align-center"
                                  style={{ marginBottom: "-1px" }}
                                >
                                  {item?.shipname}
                                </h4>
                              </div>
                            </Col>
                          );
                        })}

                      {expand
                        ? CruiseLine[0]?.cruises
                            .slice(12, CruiseLine[0]?.cruises?.length)
                            ?.map((item: any, index: number) => {
                              return (
                                <Col xs={12} sm={6} md={4} lg={4} xl={3}>
                                  <div
                                    className={
                                      CruiseLine[0]?.online
                                        ? "ship-card flex center p-24 pointer card-animated-ships"
                                        : "ship-card flex center p-24"
                                    }
                                    onClick={() =>
                                      window.open(
                                        `${item?.resShipUrl}`,
                                        "_self"
                                      )
                                    }
                                  >
                                    <h4
                                      className="font-regular opacity80 text-ships text-align-center"
                                      style={{ marginBottom: "-1px" }}
                                    >
                                      {item?.shipname}
                                    </h4>
                                  </div>
                                </Col>
                              );
                            })
                        : null}

                      <div className="flex center flex-row mt-32 mb-32">
                        <div className="more-divider" />
                        <Button
                          type="default"
                          shape="round"
                          className="more-btn font-regular font-primary font-14"
                          onClick={() => setExpand(!expand)}
                        >
                          {expand ? (
                            <>
                              Show Less{" "}
                              <IoIosArrowUp style={{ marginLeft: "2px" }} />
                            </>
                          ) : (
                            <>
                              More Ships
                              <IoIosArrowDown style={{ marginLeft: "2px" }} />
                            </>
                          )}
                        </Button>
                        <div className="more-divider" />
                      </div>
                    </>
                  ) : (
                    CruiseLine[0]?.cruises?.map((item: any, index: number) => {
                      return (
                        <Col lg={3} key={index}>
                          <div
                            className={
                              CruiseLine[0]?.online
                                ? "ship-card flex center p-24 pointer card-animated-ships"
                                : "ship-card flex center p-24"
                            }
                            onClick={() =>
                              window.open(`${item?.resShipUrl}`, "_self")
                            }
                          >
                            <h4
                              className="font-regular opacity80 text-ships text-align-center"
                              style={{ marginBottom: "-1px" }}
                            >
                              {item?.shipname}
                            </h4>
                          </div>
                        </Col>
                      );
                    })
                  )}
                </Row>
              </div>
            )}
          </div>
          <div className="flex center button-container">
            {CruiseLine[0]?.resUrl ? (
              <Button
                type="default"
                size="large"
                className="btn-small card-button font-regular font-primary border-primary mr-8"
                onClick={() => window.open(`${CruiseLine[0]?.resUrl}`, "_self")}
                rel="noopener noreferrer"
                aria-label="Book Now Button"
              >
                More About {CruiseLine[0]?.title}
              </Button>
            ) : null}
            <Button
              type="primary"
              size="large"
              className="btn-small btn-primary card-button font-regular font-white"
              onClick={() => {
                if (CruiseLine[0]?.resUrl) {
                  window.open(
                    `https://res.cruisemaster-me.com/swift/cruise?advancedsearch=true&siid=177652&lang=1&cruiseline=${CruiseLine[0]?.cruiseid}&destinationtype=All`
                  );
                } else {
                  showModal();
                }
              }}
              rel="noopener noreferrer"
              aria-label="Book Now Button"
            >
              {CruiseLine[0]?.online
                ? `Book Your ${CruiseLine[0]?.title}`
                : `Enquire About ${CruiseLine[0]?.title}`}
            </Button>
          </div>

          <OfflineCruiseModal
            cruiseLine={CruiseLine[0]}
            isModalOpen={isModalOpen}
            handleOk={handleOk}
            handleCancel={handleCancel}
          />
        </Container>
      </div>
    </div>
  );
}

export default CruiseLine;
