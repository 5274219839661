import { Container } from "react-bootstrap";
import "./styles.scss";
import CruiseListComponent from "../CruiseListComponent/CruiseListComponent";
import { popularCruiseLines } from "../../DataStore/CruiseLines/popularCruiseLines";
import { luxuryCruiseLines } from "../../DataStore/CruiseLines/luxuryCruiseLines";
import { riverCruiseLines } from "../../DataStore/CruiseLines/riverCruiseLines";
import { expeditionCruiseLines } from "../../DataStore/CruiseLines/expeditionCruiseLines";
import { nicheCruiseLines } from "../../DataStore/CruiseLines/nicheCruiseLines";

function CruiseLines() {
  return (
    <>
      <div className="cruises-component column-padding-b column-padding-t">
        <Container className="flex flex-column center">
          <div className="flex flex-column center">
            <h1 className="font-medium font-black">Our Partner Cruise Lines</h1>
            <p className="font-regular font-para-black mb-32 text-align-center">
              Choose from a wide variety of the world’s best contemporary,
              premium, luxury, river, and niche cruise lines
            </p>
          </div>
          <CruiseListComponent
            CruiseLine={popularCruiseLines}
            heading={"POPULAR CRUISE LINES"}
            cruiseType="popular"
          />
          <CruiseListComponent
            CruiseLine={luxuryCruiseLines}
            heading={"LUXURY CRUISE LINES"}
            cruiseType="luxury"
          />
          <CruiseListComponent
            CruiseLine={riverCruiseLines}
            heading={"RIVER CRUISE LINES"}
            cruiseType="river"
          />
          <CruiseListComponent
            CruiseLine={expeditionCruiseLines}
            heading={"EXPEDITION CRUISE LINES"}
            cruiseType="expedition"
          />
          <CruiseListComponent
            CruiseLine={nicheCruiseLines}
            heading={"NICHE CRUISE LINES"}
            cruiseType="niche"
          />
        </Container>
      </div>
    </>
  );
}

export default CruiseLines;
