import React from "react";

const NotFound = () => {
  return (
    <div
      className="flex center flex-column font-regular"
      style={{ height: "50vh" }}
    >
      <h1>404 - Page Not Found</h1>
      <p>Sorry, the page you are looking for does not exist.</p>
    </div>
  );
};

export default NotFound;
