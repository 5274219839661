import { Alert } from "antd";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Modal } from "antd";
import { Form } from "antd";
import { notification } from "antd";
import { Input } from "antd";
import { Button } from "antd";
// import { useApp } from "../../util/AppProvider";

type NotificationType = "success" | "info" | "warning" | "error";

interface LoginModalProps {
  isModalOpen: boolean;
  handleCancel: () => void;
}

interface credentialsProps {
  username: string;
  password: string;
}

const LoginModal: React.FC<LoginModalProps> = ({
  isModalOpen,
  handleCancel,
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  // const { login } = useApp();

  const [api, contextHolder] = notification.useNotification();

  const openNotificationWithIconError = (type: NotificationType) => {
    api[type]({
      message: "Login Failed!",
      description: "Please Try Again Later.",
    });
  };

  const validateMessages = {
    required: "This field is required!",
  };
  /* eslint-enable no-template-curly-in-string */

  const [form] = Form.useForm();

  const onFinish = async (values: credentialsProps) => {
    try {
      // setLoading(true);
      // setError(false);
      // await axios
      //   .get("https://cruisemaster-me.com/api/GET_USERS.php", {
      //     params: {
      //       username: values?.username,
      //       password: values?.password,
      //     },
      //   })
      //   .then((res: any) => {
      //     console.log(res?.data[0], "here");
      //     if (res?.data && res?.data?.length > 0) {
      //       login(res?.data[0]);
      //       setError(false);
      //       setLoading(false);
      //       form.resetFields();
      //       handleCancel();
      //       window.open("https://res.cruisemaster-me.com/Admin/Login.aspx?p1=USERNAME&p2=PASSWORD", "_blank");
      //     } else {
      //       setError(true);
      //       setLoading(false);
      //       form.resetFields();
      //     }
      //   });
      const p1 = encodeURIComponent(values?.username);
      const p2 = encodeURIComponent(values?.password);
      window.open(
        `https://res.cruisemaster-me.com/Admin/Login.aspx?p1=${p1}&p2=${p2}&search=cruise`,
        "_blank"
      );
      form.resetFields();
      handleCancel();
    } catch (error) {
      setLoading(false);
      setError(false);
      console.error(error);
      openNotificationWithIconError("error");
      form.resetFields();
    }
  };

  return (
    <>
      {contextHolder}
      <Modal
        title={null}
        width={400}
        centered
        open={isModalOpen}
        footer={[]}
        onCancel={() => {
          handleCancel();
          form.resetFields();
        }}
      >
        <div style={{ padding: "12px" }} className="flex center">
          <h4
            style={{
              marginBottom: "32px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontWeight: "bold",
              letterSpacing: "1px",
            }}
          >
            Travel Agent Portal
          </h4>
          <Form
            name="LoginForm"
            onFinish={onFinish}
            layout="vertical"
            form={form}
            validateMessages={validateMessages}
          >
            <Row>
              <Col sm={12} md={12} lg={12}>
                <label style={{ fontWeight: 500, color: "#292929" }}>
                  Username
                </label>
                <Form.Item
                  name="username"
                  rules={[
                    { required: true, message: "Please enter your Username" },
                  ]}
                >
                  <Input
                    placeholder="Username"
                    className="bold-500 login-input"
                    style={{
                      borderColor: "white",
                      outline: "none",
                      boxShadow: "none",
                      borderRadius: 6,
                      padding: 4,
                    }}
                  />
                </Form.Item>
              </Col>
              <Col sm={12} md={12} lg={12} style={{ marginBottom: "4px" }}>
                <label style={{ fontWeight: 500, color: "#292929" }}>
                  Password
                </label>
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your password",
                    },
                  ]}
                >
                  <Input.Password
                    placeholder="Password"
                    className="bold-500 login-input"
                    style={{
                      borderColor: "white",
                      outline: "none",
                      boxShadow: "none",
                      borderRadius: 6,
                      padding: 12,
                    }}
                  />
                </Form.Item>{" "}
                <Alert
                  style={error ? { marginBottom: "16px" } : { display: "none" }}
                  className="mb-16"
                  description="Invalid Username Or Password..!"
                  type="error"
                />
              </Col>

              <div
                className="full-width"
                style={{
                  alignItems: "flex-end",
                  justifyContent: "flex-end",
                  display: "flex",
                }}
              >
                <Button
                  htmlType="submit"
                  style={{ width: "100%" }}
                  type="primary"
                  loading={loading}
                  className="btn-small card-button btn-small full-width"
                  size="large"
                >
                  Login
                </Button>
              </div>
            </Row>
          </Form>
        </div>
      </Modal>
    </>
  );
};

export default LoginModal;
