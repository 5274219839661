function SearchForm() {
  return (
    <div className="home ody-form-container-custom">
      <div id="option-eight">
        <ody-search-form search-form-settings="OVExplore8"> </ody-search-form>
      </div>
    </div>
  );
}

export default SearchForm;
