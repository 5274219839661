import "./styles.scss";
import { useNavigate } from "react-router-dom";
import { MdOutlineArrowRight } from "react-icons/md";
import { IoMdHome } from "react-icons/io";
import { Container } from "react-bootstrap";

type BannerProps = {
  link: string;
  link2: string;
};

function Banner(props: BannerProps) {
  const navigate = useNavigate();

  return (
    <Container>
      <div className="screen-banner flex flex-column center">
        <div className="font-faded-black font-regular font-14 bold-500 opacity80 mb-8">
          <span
            className="pointer home-link"
            onClick={() => {
              setTimeout(() => {
                window.scrollTo(0, 0);
              }, 100);
              navigate("/");
            }}
          >
            <IoMdHome
              className="basic-texts"
              style={{ margin: "0px 4px 5px 0px" }}
            />
            Home
          </span>{" "}
          <MdOutlineArrowRight className="basic-texts" /> {props?.link}
        </div>
        <h1 className="font-medium font-black mb-32">
          {props?.link2 ? props?.link2 : props?.link}
        </h1>
      </div>
    </Container>
  );
}

export default Banner;
