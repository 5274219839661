import { useEffect } from "react";
import { useApp } from "../util/AppProvider";

const useInactivityTimeout = (timeout = 300000) => {
  const { currentUser, setCurrentUser, logout } = useApp();

  useEffect(() => {
    let timer: NodeJS.Timeout;

    const resetTimer = () => {
      clearTimeout(timer);
      timer = setTimeout(logout, timeout);
    };

    const eventHandler = () => {
      resetTimer();
    };

    window.addEventListener("mousemove", eventHandler);
    window.addEventListener("keydown", eventHandler);
    window.addEventListener("click", eventHandler);
    window.addEventListener("scroll", eventHandler);

    if (currentUser) {
      resetTimer();
    }

    return () => {
      clearTimeout(timer);
      window.removeEventListener("mousemove", eventHandler);
      window.removeEventListener("keydown", eventHandler);
      window.removeEventListener("click", eventHandler);
      window.removeEventListener("scroll", eventHandler);
    };
  }, [currentUser, setCurrentUser, logout, timeout]);
};

export default useInactivityTimeout;
