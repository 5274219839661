import "./styles.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import { Button } from "antd";
import SearchForm from "./SearchForm";
import { SlideList } from "../../DataStore/HeroSlides";
// import { useEffect } from "react";
// import axios from "axios";

// interface SlideType {
//   SlideId: Number;
//   SlidePath: String;
// }

function HeroSection() {
  // useEffect(() => {
  //   getHeroSlides();
  // }, []);

  // const getHeroSlides = async () => {
  //   await axios
  //     .get("https://cruisemaster-me.com/api/GET_HERO_SLIDES.php")
  //     .then((res: any) => {
  //       console.log(res?.data);
  //     });
  // };

  // Custom Previous Button component
  const CustomPrevButton: React.FC<{ onClick: () => void }> = ({ onClick }) => (
    <Button
      type="primary"
      shape="circle"
      icon={<IoIosArrowBack className="font-white opacity80 heading mh-32" />}
      className="custom-prev-button"
      onClick={onClick}
    />
  );

  // Custom Next Button component
  const CustomNextButton: React.FC<{ onClick: () => void }> = ({ onClick }) => (
    <Button
      type="primary"
      shape="circle"
      icon={
        <IoIosArrowForward className="font-white opacity80 heading mh-32" />
      }
      className="custom-next-button"
      onClick={onClick}
    />
  );

  const settings = {
    dots: false,
    infinite: true,
    fade: true,
    cssEase: 'linear',
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 5500,
    prevArrow: <CustomPrevButton onClick={() => {}} />,
    nextArrow: <CustomNextButton onClick={() => {}} />,
    centerMode: false,
    pauseOnHover: false
  };

  return (
    <div className="hero-container">
      <Slider {...settings}>
        {SlideList?.map((item) => {
          return (
            <div className="slider-container">
              <img
                src={item?.imagepath}
                className="carousel-image"
                alt="Slide"
              />
            </div>
          );
        })}
      </Slider>
      <div className="full-width flex center flex-column">
        <div className="search-form-lg">
          <SearchForm />
          <h1 className="font-medium font-black mt-16 sm-d-none">Destinations</h1>
        </div>
      </div>
    </div>
  );
}

export default HeroSection;
