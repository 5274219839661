import princess from "../../assets/logos/pcl.png";
import celebrity from "../../assets/logos/celebrity.png";
import costa from "../../assets/logos/costa.png";
import disney from "../../assets/logos/disney.png";
import msc from "../../assets/logos/msc.png";
import ncl from "../../assets/logos/ncl.png";
import pnouk from "../../assets/logos/p&ouk.png";
import rc from "../../assets/logos/rc.png";
import cunard from "../../assets/logos/cunard.png";
import HollandAmerica from "../../assets/logos/HollandAmerica-logos.png";
import resort from "../../assets/logos/resort.png";
import { CDN_BASE_URL } from "../../Config/apiConfig";

export const popularCruiseLines = [
  {
    title: "Princess",
    logo: princess,
    online: true,
    resUrl:
      "https://res.cruisemaster-me.com/swift/cruise/cruiseline/princess-cruise-line?siid=177652&cruiseline=7",
    url: "/major_cruise_lines/princess",
    cruiseid: "7",
    urlName: "princess",
    banner: `${CDN_BASE_URL}/img/ships/Princess/StarPrincess_ML.jpg`,
    overview:
      "There are many ways to travel, but no one helps you truly discover the world’s great destinations, its best-kept secrets, and the soul of its people, like Princess Cruises – the Destination Leader.\nPrincess has been sailing around the globe since 1965, taking millions of guests on life-transforming journeys on board a fleet of 18 magnificent ships, to over 380 destinations. On board, you’ll be immersed in the culture, colors and flavors of the places you’ll visit, with authentic folkloric shows, regional cuisine and enlightening presentations by local experts. On shore, Princess introduces the bucket-list sights you want to see most – as well as hidden jewels not found in travel books – with our award-winning Discovery™ and Animal Planet™ Exclusive shore excursions. And now, Princess has ushered in the next wave of vacation travel with MedallionClass Vacations™, which offer you a more effortless and personalized experience than you could ever imagine.\n",

    aboutships:
      "Every Princess ship is a destination in itself with wide-ranging amenities, elevated culinary experiences and delightful activities. Below you'll find the deck plans, stateroom details, ship dining information and more for every vessel in our fleet. Visit the links, and get to know what we offer and what makes the Princess experience unique.",
    brochures: [],
    cruises: [
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/princess-cruise-line-star-princess?ship=15302&siid=177652",
        shipname: "Star Princess",
        overview:
          "The second of the next generation of cruise ships, Star Princess®, arriving 2025. There's no sibling rivalry here. Sun Princess® can't wait to welcome her sister ship, Star Princess®, to the fleet - the third ship with this celestial name. She'll feature the new Dome, out-of-this-world views in the Piazza, Signature Collection Suites and more.",
        img: "",
        shipFacts: {
          maidenVoyage: "2025",
          tonnage: "175,500",
          shipLength: "1,133 feet",
          passengerCapacity: "4,300",
          numberOfDecks: "21",
          numberOfElevators: "16",
          numberOfStaterooms: "2,150",
          numberOfSuites: "471",
          numberOfSoloCabins: "4",
          diningRestaurants: "Yes",
          barsLounges: "Yes",
          numberOfPools: "2",
          spa: "Yes",
          kidsFriendly: "Yes",
          wheelchairAccessible: "Yes",
        },
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/princess-cruise-line-sun-princess?ship=15222&siid=177652",
        shipname: "Sun Princess",
        overview:
          "Sun Princess® leads the next generation of ships. Sun Princess® is the first ship in the fleet to be powered by Liquified Natural Gas (LNG). This cleaner burning fuel will significantly reduce air emissions and marine gasoil to minimize the environmental footprint. She is the third ship to be honored with the Sun Princess® name, adding to the legacy of the first ship sailing from 1974 to 1988 and the second ship sailing from 1995 to 2020. At that time, she had the largest number of balcony cabins and the first 24-hour cafe.",
        img: "",
        shipFacts: {
          maidenVoyage: "2024",
          tonnage: "175,500",
          shipLength: "1,133 feet",
          passengerCapacity: "4,300",
          numberOfDecks: "21",
          numberOfElevators: "16",
          numberOfStaterooms: "2,150",
          numberOfSuites: "487",
          numberOfSoloCabins: "4",
          spa: "Yes",
          teenClub: "Yes",
          kidsFriendly: "Yes",
          wheelchairAccessible: "Yes",
        },
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/princess-cruise-line-caribbean-princess?ship=57&siid=177652",
        shipname: "Caribbean Princess",
        overview:
          "Exciting adventures await aboard Caribbean Princess following her multi-million-dollar array of luxurious enhancements. From staterooms that offer a more restful sleep, to endless tempting new dining options, the newly reimagined Reef Splash Zone for kids and families plus other upgrades, you’ll find Caribbean Princess herself has come back new.",
        img: "../images/ships/princess/Caribbean.jpg",
        shipFacts: {
          maidenVoyage: "2004",
          lastRefurbished: "2019",
          registry: "Bermuda",
          grossTonnage: "112,894",
          beam: "158 feet",
          maximumSpeed: "24 knots",
          shipLength: "952 feet",
          passengerCapacity: "3,140",
          numberOfCrews: "1,200",
          crewNationality: "International",
          officersNationality: "British / Italian",
          numberOfDecks: "19",
          elevators: "14",
          numberOfStaterooms: "1,556",
          numberOfSuites: "214",
          diningRestaurants: "18",
          barsLounges: "6",
          numberOfPools: "5",
          spa: "Yes",
          fitnessCenter: "Yes",
          kidsFriendly: "Yes",
          wheelchairAccessible: "Yes",
        },
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/princess-cruise-line-coral-princess?ship=58&siid=177652",
        shipname: "Coral Princess",
        overview:
          "Coral Princess, with more than 700 balcony staterooms, was custom built to navigate the historic Panama Canal. Be sure to try the Bayou Café and Steakhouse, which features New Orleans-inspired Cajun and Creole cuisine, join friends for cocktails and Movies Under the Stars® by the pool or try some decadent treats at the International Café.",
        img: "../images/ships/princess/Coral.jpg",
        shipFacts: {
          maidenVoyage: "January, 2003",
          lastRefurbished: "January, 2019",
          registry: "Bermuda",
          grossTonnage: "91,627",
          beam: "203 feet",
          maximumSpeed: "21.5 knots",
          shipLength: "965 feet",
          passengerCapacity: "2,000",
          numberOfCrew: "895",
          crewNationality: "International",
          officersNationality: "British / Italian",
          numberOfDecks: "16",
          elevators: "14",
          numberOfStaterooms: "1,000",
          numberOfSuites: "208",
          diningRestaurants: "13",
          barsLounges: "2",
          numberOfPools: "4",
          spa: "Yes",
          fitnessCenter: "Yes",
          kidsFriendly: "Yes",
          wheelchairAccessible: "Yes",
        },
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/princess-cruise-line-crown-princess?ship=114&siid=177652",
        shipname: "Crown Princess",
        overview:
          "Enjoy sweeping views of the world while sailing on Crown Princess. From her nearly 900 balconies to the breathtaking three-story Atrium, you'll discover a relaxing atmosphere filled with an array of world class entertainment and dining options that will greet you each day when you return from making fascinating discoveries ashore.",
        img: "../images/ships/princess/Crown.jpg",
        shipFacts: {
          maidenVoyage: "June, 2006",
          lastRefurbished: "April, 2018",
          registry: "Bermuda",
          grossTonnage: "113,561",
          beam: "195 feet",
          maximumSpeed: "21.5 knots",
          shipLength: "952 feet",
          passengerCapacity: "3,080",
          numberOfCrew: "1,200",
          crewNationality: "International",
          officersNationality: "British/Italian",
          numberOfDecks: "19",
          elevators: "14",
          numberOfStaterooms: "1,541",
          numberOfSuites: "206",
          diningRestaurants: "15",
          barsLounges: "2",
          numberOfPools: "4",
          spa: "Yes",
          fitnessCenter: "Yes",
          kidsFriendly: "Yes",
          wheelchairAccessible: "Yes",
        },
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/princess-cruise-line-diamond-princess?ship=60&siid=177652",
        shipname: "Diamond Princess",
        overview:
          "Diamond Princess is a treasure trove of exceptional delights waiting to be discovered. Dine on freshly prepared sashimi in Kai Sushi, watch street performers in the dazzling Atrium, or take in a lavish production show in our state-of-the-art theater. And for a unique treat visit the Izumi Japanese Bath, the largest of its kind at sea.",
        img: "../images/ships/princess/Diamond.jpg",
        shipFacts: {
          maidenVoyage: "March, 2004",
          lastRefurbished: "Feb 2019",
          registry: "Bermuda",
          grossTonnage: "115,875",
          beam: "205 feet",
          maximumSpeed: "23 knots",
          shipLength: "952 feet",
          passengerCapacity: "2,670",
          numberOfCrew: "1,100",
          crewNationality: "International",
          officersNationality: "British / Italian",
          numberOfDecks: "18",
          elevators: "14",
          numberOfStaterooms: "1,337",
          numberOfSuites: "214",
          diningRestaurants: "11",
          barsLounges: "5",
          numberOfPools: "4",
          spa: "Yes",
          fitnessCenter: "Yes",
          kidsFriendly: "Yes",
          wheelchairAccessible: "Yes",
        },
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/princess-cruise-line-discovery-princess?ship=14718&siid=177652",
        shipname: "Discovery Princess",
        overview:
          "Discovery Princess℠, our third vessel designed from the ground up with Princess MedallionClassTM and the final Royal-class ship, will continue to deliver an array of innovative new experiences. Enjoy 270-degree sweeping views from the largest balconies at sea, unwind in ultimate comfort at The Sanctuary, and indulge your senses with world-class dining options from Michelin-star chefs. Plus, Princess Live Entertainment presents spectacular new productions that can only be seen in our state-of-the-art Princess Theater.",
        img: "../images/ships/princess/Discovery.jpg",
        shipFacts: {
          maidenVoyage: "2021",
          grossTonnage: "141,000",
          shipLength: "1,083 Feet",
          passengerCapacity: "3,660",
          numberOfCrew: "1,346",
          numberOfDecks: "19",
          elevators: "16",
          numberOfStaterooms: "1,830",
          numberOfSuites: "430",
          diningRestaurants: "16",
          barsLounges: "14",
          numberOfPools: "2",
          spa: "Yes",
          fitnessCenter: "Yes",
          kidsFriendly: "Yes",
          wheelchairAccessible: "Yes",
        },
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/princess-cruise-line-emerald-princess?ship=1098&siid=177652",
        shipname: "Emerald Princess",
        overview:
          "Your culinary journey at sea reaches new heights with an abundance of fresh restaurant choices and dining options aboard Emerald Princess. From revitalized top deck venues like Salty Dog Grill, Slice Pizzeria and Coffee & Cones to brand new casual restaurants Steamers Seafood and Planks BBQ there’s something for every palate. Let the kids play, explore, huddle and create at the newly re-imagined Camp Discovery Youth & Teen Centers and unwind at the refreshed Sanctuary, a tranquil haven reserved for adults.",
        img: "../images/ships/princess/Emerald.jpg",
        shipFacts: {
          maidenVoyage: "April, 2007",
          lastRefurbished: "April, 2019",
          registry: "Bermuda",
          grossTonnage: "113,561",
          beam: "195 feet",
          maximumSpeed: "21.5 knots",
          shipLength: "951 feet",
          passengerCapacity: "3,080",
          numberOfCrew: "1,200",
          crewNationality: "International",
          officersNationality: "British/Italian",
          numberOfDecks: "19",
          elevators: "14",
          numberOfStaterooms: "1,539",
          numberOfSuites: "206",
          diningRestaurants: "16",
          barsLounges: "3",
          numberOfPools: "4",
          spa: "Yes",
          fitnessCenter: "Yes",
          kidsFriendly: "Yes",
          wheelchairAccessible: "Yes",
        },
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/princess-cruise-line-enchanted-princess?ship=14304&siid=177652",
        shipname: "Enchanted Princess",
        overview:
          "Enchanted Princess℠ shares the spectacular style and luxury of our fleet and introduces new attractions all her own. Get ready for exquisite, one-of-a-kind dining experiences. The most pools and whirlpool hot tubs in our fleet. World-class entertainment venues hosting dazzling performances. As well as the breathtaking new Sky Suite, with expansive views from our largest balcony.",
        img: "../images/ships/princess/Enchanted.jpg",
        shipFacts: {
          maidenVoyage: "November 10, 2021",
          grossTonnage: "145,281",
          beam: "217 feet",
          shipLength: "1,083 feet",
          passengerCapacity: "3,660",
          numberOfCrew: "1,346",
          numberOfDecks: "19",
          elevators: "16",
          numberOfStaterooms: "1,830",
          numberOfSuites: "428",
          diningRestaurants: "17",
          barsLounges: "2",
          numberOfPools: "3",
          spa: "Yes",
          fitnessCenter: "Yes",
          kidsFriendly: "Yes",
          wheelchairAccessible: "Yes",
        },
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/princess-cruise-line-grand-princess?ship=62&siid=177652",
        shipname: "Grand Princess",
        overview:
          "With brand new top deck dining venues, Slice Pizzeria, The Mix, Salty Dog Grill and Coffee & Cones you’ll have even more tantalizing options to choose from during your stay on board Grand Princess. Relax at The Sanctuary, a tranquil haven reserved for adults, enjoy Movies Under the Stars® poolside or see an award-winning Broadway-style production show in the upgraded Princess Theater, showcasing an even more immersive audio experience.",
        img: "../images/ships/princess/Grand.jpg",
        shipFacts: {},
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/princess-cruise-line-island-princess?ship=63&siid=177652",
        shipname: "Island Princess",
        overview:
          "Island Princess is your own private retreat on the sea. Whether you crave relaxation or exhilaration, you'll find the soothing Lotus Spa®, live entertainment, gourmet cuisine, casino gaming and more. And for a special treat, try the Bayou Café and Steakhouse, which features New Orleans-inspired Cajun and Creole cuisine.",
        img: "../images/ships/princess/Island.jpg",
        shipFacts: {
          maidenVoyage: "2003",
          lastRefurbished: "2019",
          registry: "Bermuda",
          grossTonnage: "92,822",
          beam: "204 feet",
          maximumSpeed: "21.5 knots",
          shipLength: "964 feet",
          passengerCapacity: "2,200",
          numberOfCrew: "900",
          crewNationality: "International",
          officersNationality: "British / Italian",
          numberOfDecks: "11",
          elevators: "14",
          numberOfStaterooms: "987",
          numberOfSuites: "227",
          diningRestaurants: "13",
          barsLounges: "5",
          numberOfPools: "3",
          spa: "Yes",
          fitnessCenter: "Yes",
          kidsFriendly: "Yes",
          wheelchairAccessible: "Yes",
        },
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/princess-cruise-line-majestic-princess?ship=13762&siid=177652",
        shipname: "Majestic Princess",
        overview:
          "Sail aboard Majestic Princess® and enjoy the signature favorites you’ve always loved as well as exciting additions including two specialty restaurants with dinner menus crafted by two Michelin-star awarded chefs. Come aboard and find out what makes this ship so special!",
        img: "../images/ships/princess/Majestic.jpg",
        shipFacts: {
          maidenVoyage: "2017",
          registry: "United Kingdom",
          grossTonnage: "143,700",
          beam: "224 feet",
          shipLength: "1,083 feet",
          passengerCapacity: "3,560",
          numberOfCrew: "1,346",
          numberOfDecks: "19",
          elevators: "16",
          numberOfStaterooms: "1,780",
          numberOfSuites: "409",
          diningRestaurants: "11",
          bars: "14",
          numberOfPools: "7",
          kidsFriendly: "Yes",
          wheelchairAccessible: "Yes",
        },
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/princess-cruise-line-regal-princess?ship=65&siid=177652",
        shipname: "Regal Princess",
        overview:
          "Enjoy sweeping views from one of more than 1,400 balconies on Regal Princess or stroll on the SeaWalk®, a glass-floor walkway extending 28 feet beyond the edge of the ship! From the tranquil Sanctuary, a retreat reserved for adults, to the dazzling Princess WaterColor Fantasy light and water show and more, you'll find diversions for every mood.",
        img: "../images/ships/princess/Regal.jpg",
        shipFacts: {
          maidenVoyage: "May, 2014",
          registry: "Bermuda",
          grossTonnage: "141,000",
          beam: "217 feet",
          maximumSpeed: "22 knots",
          shipLength: "1,083 feet",
          passengerCapacity: "3,560",
          numberOfCrew: "1,346",
          numberOfDecks: "19",
          numberOfStaterooms: "1,780",
          numberOfSuites: "353",
          diningRestaurants: "18",
          bars: "2",
          numberOfPools: "4",
          spa: "Yes",
          fitnessCenter: "Yes",
          kidsFriendly: "Yes",
          wheelchairAccessible: "Yes",
        },
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/princess-cruise-line-royal-princess?ship=66&siid=177652",
        shipname: "Royal Princess",
        overview:
          "Enjoy sweeping views from one of more than 1,400 balconies on Royal Princess or stroll on the SeaWalk®, a glass-floor walkway extending 28 feet beyond the edge of the ship! From the tranquil Sanctuary, a retreat reserved for adults, to the dazzling Princess WaterColor Fantasy light and water show and more, you'll find diversions for every mood",
        img: "../images/ships/princess/Royal.jpg",
        shipFacts: {
          maidenVoyage: "June, 2013",
          lastRefurbished: "Dec 2018",
          registry: "Bermuda",
          grossTonnage: "142,229",
          beam: "217 feet",
          maximumSpeed: "17 knots",
          shipLength: "1,083 feet",
          passengerCapacity: "3,560",
          numberOfCrews: "1,346",
          crewNationality: "International",
          officersNationality: "International",
          numberOfDecks: "19",
          numberOfElevators: "16",
          numberOfStaterooms: "1,780",
          numberOfSuites: "353",
          diningRestaurants: "16",
          barsLounges: "14",
          numberOfPools: "4",
          spa: "Yes",
          fitnessCenter: "Yes",
          kidsFriendly: "Yes",
          wheelchairAccessible: "Yes",
        },
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/princess-cruise-line-ruby-princess?ship=1162&siid=177652",
        shipname: "Ruby Princess",
        overview:
          "Fresh from a multi-million dollar renovation, Ruby Princess is a luminous jewel on the seas and a romantic destination in itself. She offers nearly 900 balconies from which to view the world, an array of entertainment options including Movies Under the Stars®, now with the industry's best 7.1 surround sound for an immersive audio experience, and sumptuous dining options from Crown Grill to exciting new top deck dining venues including Slice Pizzeria and Coffee & Cones.",
        img: "../images/ships/princess/Ruby.jpg",
        shipFacts: {
          maidenVoyage: "November, 2008",
          lastRefurbished: "October, 2018",
          registry: "Bermuda",
          grossTonnage: "113,561",
          beam: "159 feet",
          maximumSpeed: "21.5 knots",
          shipLength: "951 feet",
          passengerCapacity: "3,080",
          crewNationality: "International",
          officersNationality: "British / Italian",
          numberOfCrew: "1,200",
          numberOfDecks: "19",
          numberOfElevators: "14",
          numberOfStaterooms: "1,542",
          numberOfSuites: "206",
          pool: "Yes",
          diningRestaurants: "11",
          barsLounges: "4",
          spa: "Yes",
          fitnessCenter: "Yes",
          kidsFriendly: "Yes",
          wheelchairAccessible: "Yes",
        },
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/princess-cruise-line-sapphire-princess?ship=67&siid=177652",
        shipname: "Sapphire Princess",
        overview:
          "As you set your sights on the wonders of the world, let Sapphire Princess indulge your every whim. Over 700 balconies, world-class gourmet dining and dazzling entertainment options are just the beginning of your sea-going adventure. Dance the night away, enjoy casino gaming or relax and rejuvenate body and mind at the Lotus Spa®.",
        img: "../images/ships/princess/Sapphire.jpg",
        shipFacts: {
          maidenVoyage: "May, 2004",
          lastRefurbished: "March, 2018",
          registry: "Bermuda",
          grossTonnage: "115,875",
          beam: "205 feet",
          maximumSpeed: "23 knots",
          shipLength: "952 feet",
          passengerCapacity: "2,670",
          numberOfCrew: "1,100",
          crewNationality: "International",
          officersNationality: "British / Italian",
          numberOfDecks: "18",
          numberOfElevators: "14",
          numberOfStaterooms: "1,337",
          numberOfSuites: "214",
          numberOfPools: "5",
          diningRestaurants: "13",
          barsLounges: "2",
          spa: "Yes",
          fitnessCenter: "Yes",
          kidsFriendly: "Yes",
          wheelchairAccessible: "Yes",
        },
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/princess-cruise-line-sky-princess?ship=14053&siid=177652",
        shipname: "Sky Princess",
        overview:
          "Sky Princess®, the newest addition to our fleet, elevates the distinctive, contemporary design and luxurious attractions of our renowned Royal-class ships to even loftier heights. You can look forward to our most exciting entertainment venues yet, our newest dining choices and award-winning chef partnerships, as well as more staterooms than ever to relax in. And that’s just the start!",
        img: "../images/ships/princess/Sky.jpg",
        shipFacts: {
          maidenVoyage: "2019",
          grossTonnage: "141,000",
          beam: "217 feet",
          shipLength: "1,083 feet",
          passengerCapacity: "3,660",
          numberOfCrew: "1,346",
          numberOfDecks: "19",
          numberOfElevators: "16",
          numberOfStaterooms: "1,830",
          numberOfSuites: "428",
          diningRestaurants: "16",
          barsLounges: "14",
          numberOfPools: "2",
          spa: "Yes",
          fitnessCenter: "Yes",
          kidsFriendly: "Yes",
          wheelchairAccessible: "Yes",
        },
      },
    ],
  },
  {
    title: "Cunard",
    cruiseid: "12",
    logo: cunard,
    online: true,
    resUrl:
      "https://res.cruisemaster-me.com/swift/cruise/cruiseline/cunard-line?siid=177652&cruiseline=12",
    url: "/major_cruise_lines/cunard",
    urlName: "cunard",
    banner: `${CDN_BASE_URL}/img/ships/Cunard/Cunard_ML.jpg`,
    overview:
      "With a fascinating history dating back 180 years, our sea-faring expertise and destination knowledge is incomparable – no one knows the world, or our ships, better than we do. They have always been The Most Famous Ocean Liners in the World®. \nThe most glamorous names of yesterday and today have made champagne toasts in our salons, waltzed the night away in grand ballrooms, savoured grand feasts of the finest flavours in elegant dining rooms, restaurants and cafés, and whiled away hours immersed in fascinating conversation on our many decks, bars, lounges and pubs.\n",

    aboutships:
      "From fabled vessels of the past to her present royal court - Queen Mary 2, also known as QM2, Queen Victoria, also known as QV and Queen Elizabeth, also known as QE - Cunard has carried guests across the great oceans and to the far points of the globe in unparalleled style.",
    brochures: [],
    cruises: [
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/cunard-line-queen-anne?ship=15168",
        shipname: "Queen Anne",
        overview:
          "On a Queen Anne voyage feel inspired by the blend of classic and contemporary. Enjoy the freedom to do as little or as much as you choose and indulge in everything that makes a Cunard ship so special. With luxurious flourishes enhanced by stylish décor, every day on Queen Anne offers a chance to sail the world in unparalleled comfort. Let tension dissipate in the tranquility that awaits at the Mareel Spa, delight in the ceremony of the signature Afternoon Tea, and enjoy that extra special feeling as you descend into the Grand Lobby in your Gala Evening finery. Above all, rest easy in the knowledge that the finer details have been taken care of.",
        img: "",
        shipFacts: {},
        guests: "2691",
        crew: "1292",
        decks: "13",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/cunard-line-queen-mary-2?ship=118",
        shipname: "Queen Mary 2",
        overview:
          "Queen Mary 2 is a remarkable flagship; her style and elegance are legendary. Above all, it‘s the space she offers and the luxury for you to do as little or as much as you wish which sets her apart. \nWelcome to a true icon. Our flagship is a truly astonishing liner and you’ll find an incredible sense of freedom from the space she offers. Whether you’re in the mood for complete relaxation or something more active, you can find it. Immerse yourself in enthralling entertainment, be indulged in the on board spa, or simply relax with a book in her vast library. You’ll discover remarkable craftsmanship in the elegant staterooms and suites and savouring her many sumptuous restaurants and bars is always a pleasure.\n",
        img: "../images/ships/cunard/QueenMary2.jpg",
        shipFacts: {},
        guests: "2691",
        crew: "1292",
        decks: "13",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/cunard-line-queen-victoria?ship=135",
        shipname: "Queen Victoria",
        overview:
          "Queen Victoria will delight you with her special appeal, where elegance and unique features combine seamlessly with outstanding hospitality. You’ll discover an extraordinary way to see the world.\nTake a voyage on Queen Victoria and you’ll soon discover why she’s established herself as a favourite among many of our guests. You’ll find her unique atmosphere and warmth encourages a sense of escapism. This special appeal has recently been elevated still further with the introduction of an expanded sun deck and Britannia Club dining, as well as a newly enhanced Chart Room and Winter Garden. These new levels of refinement combine perfectly to offer you an extraordinary base for your holiday\n",
        img: "../images/ships/cunard/QueenVictoria.jpg",
        shipFacts: {},
        guests: "2061",
        crew: "981",
        decks: "12",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/cunard-line-queen-elizabeth?ship=13231",
        shipname: "Queen Elizabeth",
        overview:
          "Join us on Queen Elizabeth and immerse yourself in her evocative art deco elegance. Our newest Queen exudes style and has an especially refined feel. Prepare yourself for a truly remarkable voyage.\nQueen Elizabeth invites you to an uncrowded world where spacious decks set the perfect backdrop for you to savour a flawless Cunard experience. Immerse yourself in endless entertainment, luxurious accommodation and an array of dining choices. Her art deco features and gleaming chandeliers blend with contemporary attractions to ensure a remarkable holiday. Enjoy croquet on her Games Deck, stunning entertainment in her Royal Court Theatre or relax in her elegant conservatory. The choice is yours.\n\n",
        img: "../images/ships/cunard/QueenElizabeth.jpg",
        shipFacts: {},
        guests: "2081",
        crew: "1005",
        decks: "12",
      },
    ],
  },
  {
    title: "P&O Cruises",
    logo: pnouk,
    online: true,
    resUrl:
      "https://res.cruisemaster-me.com/swift/cruise/cruiseline/p-o-cruises-uk?siid=177652&cruiseline=16",
    url: "/major_cruise_lines/pnocruises",
    cruiseid: "16",
    urlName: "pnocruises",
    banner: `${CDN_BASE_URL}/img/ships/Pno/pno.jpg`,
    overview:
      "Whether you’re new to cruising or a seasoned pro, it’s good to know that when you holiday with P&O Cruises you’re in safe and trusted hands. Whenever you book with us, you’ll have reassurance and expert support when needed, so you can focus on enjoying your holiday. We’ve been creating authentic travel experiences on land and sea for over 180 years. Our history and expertise combined brings a detailed knowledge of our ships and holidays, so you’ll always be in safe hands. Wherever your next destination, we have the local knowledge to help you make the best of your experience. We design our holidays so that the great life on board is infused with British style, quality and innovation. And we offer full days in almost all destinations, as well as evening and overnight calls so that you have time to truly experience a destination, enjoy authentic encounters and immerse yourself in the culture.",

    aboutships:
      "On board every cruise ship you'll enjoy the same P&O Cruises experience. But what you'll also find is that each cruise ship has a number of unique features and facilities. Our fleet of ships epitomises the best of modern British cruising and offers an unrivalled holiday experience. Family friendly or exclusively for adults, larger or a little more modest - we've a ship for every occasion and style. Follow your heart's desire on our larger, family-friendly ships, which have been designed to cater for your every whim.",
    brochures: [],
    cruises: [
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/p-o-cruises-uk-arcadia?ship=128",
        shipname: "Arcadia",
        img: "../images/ships/p&o/Arcadia.jpg",
        overview:
          "Arcadia’s signature features such as her exterior glass-fronted lifts and expansive art collection (featuring no less than 3,000 works of art) create a sophisticated air. A mid-sized ship with chic design, Arcadia offers more than a hint of extravagance.",
        shipFacts: {},
        guests: "3078",
        crew: "1205",
        decks: "19",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/p-o-cruises-uk-arvia?ship=15107",
        shipname: "Arvia",
        img: "../images/ships/p&o/Arvia.jpg",
        overview:
          "Iona’s eagerly-anticipated sister ship, Arvia, joins the fleet in December 2022 and is heading for the sunshine. Like Iona, Arvia is another Excel class ship, with even more freedom, innovation and variety in store than ever before. So get ready for your ultimate P&O Cruises holiday.",
        shipFacts: {},
        guests: "TBD",
        crew: "TBD",
        decks: "TBD",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/p-o-cruises-uk-aurora?ship=130",
        shipname: "Aurora",
        img: "../images/ships/p&o/Aurora.jpg",
        overview:
          "Aurora is a world-class ship, bringing you the joys of classic cruise travel with a slightly more intimate feel. With a retractable roof over her inviting Crystal pool, you’ll enjoy stylish surrounds whatever the weather. At her heart, a 35 foot sculpture towers over the atrium while The Crow’s Nest offers panoramic views.",
        shipFacts: {},
        guests: "2094",
        crew: "866",
        decks: "11",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/p-o-cruises-uk-azura?ship=13257",
        shipname: "Azura",
        img: "../images/ships/p&o/Azura.jpg",
        overview:
          "Azura offers the perfect balance, bringing the opportunity to enjoy quality time together as well as time separately to indulge your passions. From the sports court to the ship’s spa, pools to family entertainment, hassle-free meal times to carefree days out, we really have got it all covered.",
        shipFacts: {},
        guests: "3647",
        crew: "1350",
        decks: "18",
      },

      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/p-o-cruises-uk-iona?ship=14277",
        shipname: "Iona",
        img: "",
        overview:
          "Inspired by our past. Designed for the future. There has never been a more stylish way to see the world! Nothing beats the feeling of stepping on board a new ship for the first time. Whether you're new to cruising, or a veteran of the high seas, your moments on board Britannia are sure to be truly memorable.",
        shipFacts: {},
        guests: "5200",
        crew: "1800",
        decks: "19",
      },

      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/p-o-cruises-uk-britannia?ship=13684",
        shipname: "Britannia",
        img: "../images/ships/p&o/Britannia.jpg",
        overview:
          "Inspired by our past. Designed for the future. There has never been a more stylish way to see the world! Nothing beats the feeling of stepping on board a new ship for the first time. Whether you're new to cruising, or a veteran of the high seas, your moments on board Britannia are sure to be truly memorable.",
        shipFacts: {},
        guests: "5200",
        crew: "1800",
        decks: "19",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/p-o-cruises-uk-ventura?ship=13258",
        shipname: "Ventura",
        img: "../images/ships/p&o/Venture.jpg",
        overview:
          "There’s always something happening on Ventura. Enjoy delicious fine dining, a tranquil spa, quiet spaces to unwind, great sports facilities and four pools. Evenings on your cruise are particularly memorable thanks to the lavish two-tier theatre where you’ll find elaborate shows and stand-up comedy.\n\n",
        shipFacts: {},
        guests: "3100",
        crew: "1250",
        decks: "19",
      },
    ],
  },
  {
    title: "Celebrity Cruises",
    logo: celebrity,
    online: true,
    resUrl:
      "https://res.cruisemaster-me.com/swift/cruise/cruiseline/celebrity-cruises?siid=177652&cruiseline=2",
    url: "/other_cruise_lines/celebrity",
    cruiseid: "2",
    urlName: "celebrity",
    banner: `${CDN_BASE_URL}/img/ships/Celebrity/celebrity.jpg`,
    overview:
      "We encourage people to sail beyond their perceptions and expand their horizons. Our award winning ships are impeccably designed, serve up exquisitely prepared culinary experiences, and offer The World at Your Service in more than 300 destinations on all seven continents. From iconic sites to personalized local experiences, we cover the seven seas like no one else. World-renowned architects and designers created our entire fleet of mid-size ships featuring luxurious, spacious accommodations, including The Retreat®. The World at Your Service: personalized, genuine, knowledgeable. With staff from over 50 diverse cultures dedicated to creating a world class experience for each guest, every time.",

    aboutships:
      "Set sail on the vacation of a lifetime aboard one of our award-winning cruise ships. Our luxurious fleet merges cutting-edge technology with welcoming interiors and revolutionary venues. Make yourself at home in our sophisticated staterooms. Discover the unrivaled luxury of The Retreat®. Experience visionary spaces, like the Magic Carpet®, that are like nothing you’ve seen before. On our cruise ships, you’ll travel the world while enjoying the height of style, comfort, and service throughout your journey.",
    brochures: [],
    cruises: [
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/celebrity-cruises-celebrity-ascent?ship=15141",
        shipname: "Celebrity Ascent",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/celebrity-cruises-celebrity-beyond?ship=14948",
        shipname: "Celebrity Beyond",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/celebrity-cruises-celebrity-apex?ship=14393",
        shipname: "Celebrity Apex",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/celebrity-cruises-celebrity-edge?ship=13858",
        shipname: "Celebrity Edge",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/celebrity-cruises-celebrity-millennium?ship=26",
        shipname: "Celebrity Millennium",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/celebrity-cruises-celebrity-summit?ship=27",
        shipname: "Celebrity Summit",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/celebrity-cruises-celebrity-equinox?ship=1176",
        shipname: "Celebrity Equinox",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/celebrity-cruises-celebrity-silhouette?ship=13281",
        shipname: "Celebrity Silhouette",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/celebrity-cruises-celebrity-constellation?ship=21",
        shipname: "Celebrity Constellation",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/celebrity-cruises-celebrity-infinity?ship=24",
        shipname: "Celebrity Infinity",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/celebrity-cruises-celebrity-eclipse?ship=13232",
        shipname: "Celebrity Eclipse",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/celebrity-cruises-celebrity-solstice?ship=1117",
        shipname: "Celebrity Solstice",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/celebrity-cruises-celebrity-reflection?ship=13486",
        shipname: "Celebrity Reflection",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/celebrity-cruises-celebrity-xpedition?ship=97",
        shipname: "Celebrity Xpedition",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/celebrity-cruises-celebrity-flora?ship=14014",
        shipname: "Celebrity Flora",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/celebrity-cruises-celebrity-xploration?ship=13786",
        shipname: "Celebrity Xploration",
      },
    ],
  },
  {
    title: "Costa Cruises",
    logo: costa,
    online: true,
    resUrl:
      "https://res.cruisemaster-me.com/swift/cruise/cruiseline/costa-cruises?siid=177652&cruiseline=10",
    url: "/other_cruise_lines/costa",
    cruiseid: "10",
    urlName: "costa",
    banner: `${CDN_BASE_URL}/img/ships/Costa/costa.jpg`,
    overview:
      "For 70 years Costa Crociere has embodied Italian style and hospitality. We have always made it our business to introduce our guests to the world’s most stunning places, giving them the chance to explore different destinations and discover the different cultures in the five continents with complete respect for their identities. As the only cruise company flying the Italian flag, it is our responsibility and duty to act as an ambassador for Italian values in the world and to represent our country at our ports of call. At Costa responsible innovation is a key factor when we design the ships of the future and develop the onboard experience, a process carried out with maximum attention to detail in order to offer a holiday geared towards discovering local cultures and experiencing them as responsible travellers.",
    aboutships:
      "Artistic, elegant, with low environmental impact: explore the diversity of our fleet, from the most prestigious cruise ships to the latest generation of flagships, designed to respect the sea. The Costa Cruise fleet of ships is the pride of the company, they all have a unique character and exclusive services, but they are united by an unmistakable style: the Costa style.",
    brochures: [],
    cruises: [
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/costa-cruises-costa-smeralda?ship=14074",
        shipname: "Costa Smeralda",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/costa-cruises-costa-toscana?ship=14833",
        shipname: "Costa Toscana",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/costa-cruises-costa-deliziosa?ship=13238",
        shipname: "Costa Deliziosa",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/costa-cruises-costa-diadema?ship=13648",
        shipname: "Costa Diadema",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/costa-cruises-costa-fascinosa?ship=13488",
        shipname: "Costa Fascinosa",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/costa-cruises-costa-favolosa?ship=13282",
        shipname: "Costa Favolosa",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/costa-cruises-costa-pacifica?ship=1177",
        shipname: "Costa Pacifica",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/costa-cruises-costa-fortuna?ship=104",
        shipname: "Costa Fortuna",
      },

      {
        resShipUrl: "",
        shipname: "Costa Serena",
      },
    ],
  },
  {
    title: "Disney Cruise Line",
    logo: disney,
    online: true,
    resUrl:
      "https://res.cruisemaster-me.com/swift/cruise/cruiseline/disney-cruise-line?siid=177652&cruiseline=4",
    url: "/major_cruise_lines/disneycruiseline",
    cruiseid: "4",
    urlName: "disneycruiseline",
    banner: `${CDN_BASE_URL}/img/ships/Disney/DCL_ML.jpg`,
    overview:
      "At Disney Cruise Line, you can set sail on the job opportunity of a lifetime! Disney Cruise Line has established itself as a world-renowned leader in hospitality and entertainment, and has been recognized as the top cruise line for families. Our crew members bring the magic to life to deliver exceptional guest service and create family-friendly fun onboard the Disney Magic, Disney Wonder, Disney Dream, Disney Fantasy, and on our private island paradise in the Bahamas – Castaway Cay! With incredible itineraries that visit exciting destinations around the world, crew and officers can chart the course for an unforgettable adventure with Disney Cruise Line. A Disney cruise brings families together to create long-lasting memories with original, musical live shows, spectacular fireworks at sea, incredible destinations like our private island and so much more. Imaginative play in whimsical kids clubs, spectacular Disney-themed entertainment and unique activities featuring popular Disney characters are just some of the unforgettable offerings that keep kids having fun.",

    aboutships:
      "Stunning vessels that pay homage to the grand era of the luxury ocean liner, Disney’s 5 distinguished ships pamper Guests with state-of-the-art comforts, legendary service and Disney-exclusive activities that bring families together en route to exotic ports of call. From bow to stern, each magnificent ship brims with restaurants, nightclubs, shops and recreational facilities that make cruising a pleasure for Guests of all ages. With so much to experience built right in, getting there is half the fun!\n\n",
    brochures: [],
    cruises: [
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/disney-cruise-line-disney-magic?ship=32",
        shipname: "Disney Magic",
        overview:
          "Created with your entire family in mind, the Disney Magic combines classic nostalgia and modern amenities with Disney’s signature service. Step aboard for spacious staterooms that ensure everyone’s comfort. Get lost in original Broadway-quality entertainment. Indulge in first-rate dining. From bow to stern, set sail for unforgettable storytelling that only Disney could bring to life.",
        img: "../images/ships/disney/Disney-Magic.jpg",
        shipFacts: {},
        guests: "0",
        crew: "0",
        decks: "11",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/disney-cruise-line-disney-wonder?ship=33",
        shipname: "Disney Wonder",
        overview:
          "Imagine a place where families can reconnect, adults can recharge and kids can immerse themselves in fantastical worlds only Disney could create. Renowned for its classic design, modern innovation and timeless whimsy, the Disney Wonder is home to 11 decks overflowing with restaurants, pools, recreation and more—ready to transport you to exotic locales waiting to be explored.",
        img: "../images/ships/disney/Disney-wonder.jpg",
        shipFacts: {},
        guests: "0",
        crew: "0",
        decks: "11",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/disney-cruise-line-disney-dream?ship=13259",
        shipname: "Disney Dream",
        overview:
          "Let your imagination set sail. Be front-and-center at original Broadway-caliber live shows, dine on artfully prepared meals in wondrously themed restaurants and make a splash on the AquaDuck. Combining classic beauty and modern luxury with legendary Disney storytelling, the award-winning Disney Dream invites you to embark on an unforgettable voyage where dreams really do come true.",
        img: "../images/ships/disney/Disney_dream.jpg",
        shipFacts: {},
        guests: "0",
        crew: "0",
        decks: "14",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/disney-cruise-line-disney-fantasy?ship=13372 ",
        shipname: "Disney Fantasy",
        overview:
          "Chart a course for adventure and wonder, where elegance and sophistication mingle with storytelling and whimsy. Sister ship to the Disney Dream, the Disney Fantasy is a masterpiece of design and engineering boasting Art Nouveau allure alongside modern technological advancements. Set sail for exotic destinations aboard this 130,000-ton marvel while enjoying Disney magic at sea.",
        img: "../images/ships/disney/DisneyFantasy.JPG",
        shipFacts: {},
        guests: "0",
        crew: "0",
        decks: "14",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/disney-cruise-line-disney-wish?ship=15090",
        shipname: "Disney Wish",
        overview:
          "Our newest ship brings to life the fantastical stories you know and love like never before. Be transported to the worlds of Frozen, Marvel and Star Wars inside wondrously themed dining and lounge spaces. Meet royalty and train alongside heroes at imaginative kids clubs. Rest and play at adult-exclusive spaces, and ride the first-ever Disney attraction at sea.",
        img: "../images/ships/disney",
        shipFacts: {},
        guests: "0",
        crew: "0",
        decks: "15",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/disney-cruise-line-disney-treasure?ship=15311",
        shipname: "Disney Treasure",
        overview: "",
        img: "",
        shipFacts: {},
        guests: "0",
        crew: "0",
        decks: "15",
      },
    ],
  },
  {
    title: "Holland America Line ",
    logo: HollandAmerica,
    online: true,
    resUrl:
      "https://res.cruisemaster-me.com/swift/cruise/cruiseline/holland-america-line?siid=177652&cruiseline=5",
    url: "/other_cruise_lines/hollandamerica",
    cruiseid: "5",
    urlName: "hollandamerica",
    banner: `${CDN_BASE_URL}/img/ships/Holland America/Rotterdam_ML.jpg`,
    overview:
      "At Holland America Line, we believe travel has the power to change the world. We consider it our higher purpose to help make the world a better place through opening minds, building connections, and inspiring a shared humanity. For 147 years, Holland America Line has been a recognized leader in cruising. If you are looking for some of the most spacious and comfortable ships at sea, award-winning service, exquisite dining, extensive activities and enrichment programs and compelling worldwide itineraries, you've come to the right place. ",

    aboutships:
      "Revel in spacious cruise ship rooms, breathe in expansive views and relax in easy elegance. With stately fine dining and enriching activities, Holland America Line's cruise ships welcome you aboard. Our fleet of 11 modern classic ships offers more than 500 sailings a year visiting all seven continents. Cruises include both popular and less-traveled ports in the Caribbean, Alaska, Europe, Mexico, South America, the Panama Canal, Australia, New Zealand and Asia — as well as unique voyages to the Amazon, Antarctica and our extended Grand Voyages.",
    brochures: [],
    cruises: [
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/holland-america-line-rotterdam?ship=14723",
        shipname: "Rotterdam",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/holland-america-line-eurodam?ship=1161",
        shipname: "Eurodam",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/holland-america-line-koningsdam?ship=13708",
        shipname: "Koningsdam",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/holland-america-line-nieuw-amsterdam?ship=13250",
        shipname: "Nieuw Amsterdam",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/holland-america-line-nieuw-statendam?ship=13993",
        shipname: "Nieuw Statendam",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/holland-america-line-noordam?ship=36",
        shipname: "Noordam",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/holland-america-line-oosterdam?ship=37",
        shipname: "Oosterdam",
      },

      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/holland-america-line-volendam?ship=43",
        shipname: "Volendam",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/holland-america-line-westerdam?ship=44",
        shipname: "Westerdam",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/holland-america-line-zaandam?ship=45",
        shipname: "Zaandam",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/holland-america-line-zuiderdam?ship=46",
        shipname: "Zuiderdam",
      },
    ],
  },
  {
    title: "MSC Cruises",
    logo: msc,
    online: true,
    resUrl:
      "https://res.cruisemaster-me.com/swift/cruise/cruiseline/msc-cruises?siid=177652&cruiseline=982",
    url: "/major_cruise_lines/msccruises",
    cruiseid: "982",
    urlName: "msccruises",
    banner: `${CDN_BASE_URL}/img/ships/Msc/msc.jpg`,
    overview:
      "We are the world’s third-largest cruise brand as well as the leader in Europe, South America, the Gulf region and Southern Africa, with more market share in addition to deployed capacity than any other player. We are also the fastest-growing global cruise brand with a strong presence in the Caribbean, North America and the Far East markets. At MSC Cruises, our love for the sea goes back many generations. That’s why we are dedicated to making each of our cruises a voyage of discovery and unforgettable emotions for every guest. We offer an enriching, immersive and safe cruise experience, where you can enjoy international dining, world-class entertainment, award-winning family programmes and the latest user-friendly technology on board. We want you to discover the world in absolute style, comfort and safety. That’s why we are constantly expanding and improving our fleet, not only to give you a greater choice of destinations, but also to offer you an amazing state-of-art cruising experience wherever you go. ",
    aboutships:
      "Explore MSC Cruises’ fleet and discover the unique features of each MSC Cruises' Ship. Blending traditional elegance and cutting-edge innovation, our cruise ships offer the utmost in comfort, dining, innovation, entertainment and on-board experience, with a special dedication to the environment’s safeguard. Choose your MSC Cruises' ship and discover a spectacular way to sail the seas!",
    brochures: [],
    cruises: [
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/msc-cruises-msc-armonia?ship=1104",
        shipname: "MSC Armonia",
        overview:
          "Even before MSC Armonia sets sail, stepping on board is like embarking on a voyage of discovery. With its impressive array of new features and facilities, the ship is now even better equipped to satisfy every need.",
        img: "../images/ships/msc/Armonia.jpg",
        shipFacts: {},
        guests: "0",
        crew: "0",
        decks: "11",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/msc-cruises-msc-bellissima?ship=13983",
        shipname: "MSC Bellissima",
        overview:
          "MSC Bellissima will offer a stunning array of features, from the innovative MSC for Me technology which provides an enhanced onboard experience to Zoe, a voice-enabled Artificial Intelligence device fitted in every cabin. The 96 m central promenade is filled with boutiques, restaurants and tranquil spots for shopping, eating and relaxing... as well as socialising. And in the evening, it comes alive with music, parties and entertainment.",
        img: "../images/ships/msc/Bellissima.jpg",
        shipFacts: {},
        guests: "0",
        crew: "0",
        decks: "11",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/msc-cruises-msc-divina?ship=13496",
        shipname: "MSC Divina",
        overview:
          "Inspired by Sophia Loren, MSC Divina brings you all the elegance and glamour of the golden age of cruise lines in an ecological new way. On board you’ll find every modern comfort, a variety of sports, leisure facilities and world-class entertainment, including Kids and Teen Clubs to ensure everyone’s happy.",
        img: "../images/ships/msc/Divina.jpg",
        shipFacts: {},
        guests: "0",
        crew: "0",
        decks: "14",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/msc-cruises-msc-euribia?ship=15174",
        shipname: "MSC Euribia",
        overview:
          "Inspired by Sophia Loren, MSC Divina brings you all the elegance and glamour of the golden age of cruise lines in an ecological new way. On board you’ll find every modern comfort, a variety of sports, leisure facilities and world-class entertainment, including Kids and Teen Clubs to ensure everyone’s happy.",
        img: "../images/ships/msc/Divina.jpg",
        shipFacts: {},
        guests: "0",
        crew: "0",
        decks: "14",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/msc-cruises-msc-fantasia?ship=1183",
        shipname: "MSC Fantasia",
        overview:
          "The MSC Fantasia is stylish and ecological, balancing the warmth and comfort of the Mediterranean atmosphere with cutting edge design. At its heart you’ll find a real stone piazza complete with an espresso bar serving freshly-baked pastries and authentic gelati - the perfect place to take a moment away from bargain hunting in the chic boutiques and duty-free centre nearby.",
        img: "../images/ships/msc/Fantasia.jpg",
        shipFacts: {},
        guests: "0",
        crew: "0",
        decks: "14",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/msc-cruises-msc-grandiosa?ship=14091",
        shipname: "MSC Grandiosa",
        overview:
          "MSC Grandiosa offers even more public space than her sister ships, along with a series of exciting innovations. The extraordinary design, wide array of facilities and entertainment make these ships ideal for the whole family. At the heart of MSC Grandiosa is a breathtaking indoor promenade covered by a dramatic LED dome. It is home to superb shopping options, an extra-large and super-friendly French bistrot, and more exciting venues to welcome all our guests.",
        img: "../images/ships/msc/Grandiosa.jpg",
        shipFacts: {},
        guests: "0",
        crew: "0",
        decks: "15",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/msc-cruises-msc-lirica?ship=1105",
        shipname: "MSC Lirica",
        overview:
          "MSC Lirica brings you a distinctive cruise experience along classic lines, centred on elegance, comfort and hospitality in the finest traditions of MSC Cruises’ Mediterranean way of life. \nAn atmosphere of relaxed spaciousness pervades, with magnificent internal vistas from the foyer and unimpeded ocean views from the lounges’ floor-to-ceiling picture windows. But you’ll also find an abundance of intimate spaces, like the swish Beverly Hills Bar or traditional English-style Lord Nelson Pub, the perfect haven for a pre-theatre drink. Modernity and tradition mingle on this ecological ship. \n",
        img: "../images/ships/msc/Lirica.jpg",
        shipFacts: {},
        guests: "0",
        crew: "0",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/msc-cruises-msc-magnifica?ship=13240",
        shipname: "MSC Magnifica",
        overview:
          "Masterfully combining the relaxed refinement of the Musica Class with the variety and opulence of our Fantasia Class flagships, MSC Magnifica brings cruise travellers the best of both worlds. Marrying traditional craftsmanship with ground-breaking design, the superb venues on this ship spoil you for choice, including 5 gourmet restaurants serving food from around the world and 12 designer-themed bars. Kids and teens love their own themed venues and special clubs that help break the ice with new friends. ",
        img: "../images/ships/msc/Magnificia.jpg",
        shipFacts: {},
        guests: "0",
        crew: "0",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/msc-cruises-msc-meraviglia?ship=13725",
        shipname: "MSC Meraviglia",
        overview:
          'MSC Meraviglia is packed with extraordinary features that offer the perfect experience at sea in every season. On board you can enjoy outstanding, authentic dining options and out-of-this-world entertainment with new panoramic areas, an innovative ocean-view aft lounge, a two-deck "inside promenade" with a 480 square metres LED dome and a spectacular amusement area connected to an outdoor water park.',
        img: "../images/ships/msc/Meraviglia.jpg",
        shipFacts: {},
        guests: "0",
        crew: "0",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/msc-cruises-msc-musica?ship=1108",
        shipname: "MSC Musica",
        overview:
          "MSC Musica not only launched a new class of ecological cruise ship, she launched a new class of cruise - a spacious world of graceful lines and superlative choice, enriched by the creativity, fine natural materials and attention to detail that have always distinguished MSC Cruises. \nLife aboard is beautiful from the moment you step on board; from the central foyer’s three-tier waterfall to its see-through piano, floating suspended on a crystal floor above a pool of shimmering water. \n\n",
        img: "../images/ships/msc/Musica.jpg",
        shipFacts: {},
        guests: "0",
        crew: "0",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/msc-cruises-msc-opera?ship=1109",
        shipname: "MSC Opera",
        overview:
          "Even before MSC Opera sets sail, stepping on board is like embarking on a voyage of discovery. With its impressive array of new features and facilities, the ship is now even better equipped to satisfy every need. We’ve added spacious new cabins with balconies to delight our guests with superb panoramic views. We’ve enlarged the restaurant and buffet areas to serve up an even greater variety of culinary treats.  ",
        img: "../images/ships/msc/Opera.jpg",
        shipFacts: {},
        guests: "0",
        crew: "0",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/msc-cruises-msc-orchestra?ship=1110",
        shipname: "MSC Orchestra",
        overview:
          "Combining spacious, elegant interiors with the variety for which our Musica Class is renowned, MSC Orchestra promises your dream cruise. Because life aboard can be just what you want it to be. If relaxation is your goal, there’s ample deck space to unwind in the sun or in the superb Body and Mind Spa, with pampering Turkish baths, saunas and a myriad of magical massages. Active types have a Sports Centre with Gym, 5 stunning pools and even a jogging track that lets you keep a constant pace, undisturbed out in the fresh sea breeze.",
        img: "../images/ships/msc/Orchestra.jpg",
        shipFacts: {},
        guests: "0",
        crew: "0",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/msc-cruises-msc-poesia?ship=1181",
        shipname: "MSC Poesia",
        overview:
          "MSC Poesia is an innovative cruise ship with elegant style that brings traditional craftsmanship to creative designs. Step aboard and you’ll enter a refined world of comfort, from the spectacular foyer waterfall to the Zen Garden, authentic Japanese Sushi bar and opulent MSC Aurea Spa wellness centre with steam room, sauna and divine massages to pamper body and mind, it’s the perfect place to unwind. There are many sports and fitness activities available on board, including a basketball court, tennis court, shuffleboard, state-of-the-art gym and minigolf. Kids and teens are equally well served, with their own clubs and parties, a dedicated Stone Age and Dinosaur Play Area, stunning video games and even a DJ disco! Plus there are naturally all the resort amenities you could wish for, including 3 swimming pools, 4 whirlpools and a giant poolside cinema screen.",
        img: "../images/ships/msc/Poesia.jpg",
        shipFacts: {},
        guests: "0",
        crew: "0",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/msc-cruises-msc-preziosa?ship=13510",
        shipname: "MSC Preziosa",
        overview:
          "Cruise all round the world in luxury on the eco-ship MSC Preziosa, savouring the pleasures of the Mediterranean way of life. The classic design and detailed craftsmanship of our ships include a real stone piazza and spectacular features such as sweeping Swarovski crystal grand staircases and a magical ‘infinity’ pool. Award winning favourites such as the MSC Aurea Spa with beauty and wellness treatments melt the tension away. And the secluded luxury of MSC Yacht Club, a ship-within-a-ship of exclusive suites, butler service, dedicated facilities and private decks - all offer privileged access to the world-class leisure on board, including a full-gaming casinoand Broadway -scale theatre.",
        img: "../images/ships/msc/Preziosa.jpg",
        shipFacts: {},
        guests: "0",
        crew: "0",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/msc-cruises-msc-seascape?ship=15127",
        shipname: "MSC Seascape",
        overview:
          "Cruise all round the world in luxury on the eco-ship MSC Preziosa, savouring the pleasures of the Mediterranean way of life. The classic design and detailed craftsmanship of our ships include a real stone piazza and spectacular features such as sweeping Swarovski crystal grand staircases and a magical ‘infinity’ pool. Award winning favourites such as the MSC Aurea Spa with beauty and wellness treatments melt the tension away. And the secluded luxury of MSC Yacht Club, a ship-within-a-ship of exclusive suites, butler service, dedicated facilities and private decks - all offer privileged access to the world-class leisure on board, including a full-gaming casinoand Broadway -scale theatre.",
        img: "../images/ships/msc/Preziosa.jpg",
        shipFacts: {},
        guests: "0",
        crew: "0",
      },

      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/msc-cruises-msc-seashore?ship=14538",
        shipname: "MSC Seashore",
        overview:
          "The first of two enriched Seaside EVO Class ships. Building on the groundbreaking and pioneering design of the Seaside class of ship, aimed at bringing guests closer to the sea, MSC Seashore has been extended and enhanced with a variety of brand-new features, spaces and experiences for guests.",
        img: "../images/ships/msc/Seashore.jpg",
        shipFacts: {},
        guests: "0",
        crew: "0",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/msc-cruises-msc-seaside?ship=13757",
        shipname: "MSC Seaside",
        overview:
          "MSC Seaside rewrites the rule book of cruise ship design, combining indoor and outdoor areas to connect you with the sea like never before. Located as low as deck 8 is a unique seafront promenade lined with places to eat, drink, shop, swim and sunbathe. And you can enjoy superb views from the two glass-floored catwalks and panoramic elevators.",
        img: "../images/ships/msc/Seaside.jpg",
        shipFacts: {},
        guests: "0",
        crew: "0",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/msc-cruises-msc-seaview?ship=13788",
        shipname: "MSC Seaview",
        overview:
          "MSC Seaview rewrites the rule book of cruise ship design, blending indoor and outdoor areas to connect you with the sea like never before. Circling the ship as low as Deck 7 is a unique seafront promenade lined with places to eat, drink, shop, swim and sunbathe. And you can enjoy superb views from the two glass-floored catwalks and panoramic elevators.",
        img: "../images/ships/msc/Seaview.jpg",
        shipFacts: {},
        guests: "0",
        crew: "0",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/msc-cruises-msc-sinfonia?ship=1112",
        shipname: "MSC Sinfonia",
        overview:
          "Even before MSC Sinfonia sets sail, stepping on board is like embarking on a voyage of discovery. With its impressive array of new features and facilities, the ship is now even better equipped to satisfy every need. We’ve added spacious new cabins with balconies to delight our guests with superb panoramic views. We’ve enlarged the restaurant and buffet areas to serve up an even greater variety of culinary treats. We’ve included a new 319 m2 area to fill with music and dancing. With the help of prestigious partners like Chicco, LEGO® and Namco®, we’ve created fabulous new play areas for children of all ages, starting with an exciting spray park packed with fun water features. And to offer even more routes to relaxation, we’ve enhanced the lavish MSC Aurea Spa with more space for massages.",
        img: "../images/ships/msc/Sinfonia.jpg",
        shipFacts: {},
        guests: "0",
        crew: "0",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/msc-cruises-msc-splendida?ship=13239       ",
        shipname: "MSC Splendida",
        overview:
          "Eco-ship MSC Splendida carries you in stylish luxury to the most desirable cruise destinations in the world, while being a beautiful destination in her own right. You’ll love the live music and the extraordinary variety of designer-themed bars and restaurants with many gourmet choices to explore, from authentic Mediterranean specialities to American steakhouse beef cuts. Complete the night with a world-class show in The Strand Theatre, a winning streak in the Royal Palm Casino or dancing in the Club 33 Disco. There’s also a vast array of sports facilities including four swimming pools, a jogging track, squash court and ultramodern gym.",
        img: "../images/ships/msc/Splendida.jpg",
        shipFacts: {},
        guests: "0",
        crew: "0",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/msc-cruises-msc-virtuosa?ship=14390",
        shipname: "MSC Virtuosa",
        overview:
          "MSC Virtuosa’s namesake comes from the word virtuoso, meaning someone highly skilled in any field of activity, and so MSC Virtuosa was named to pay homage to the skill and expertise of the MSC Cruises architects and shipyard partners at Les Chantiers de l’Atlantique who designed and built this innovative class of ships. This new cruise ship is one of the two largest in MSC Cruises’ fleet, along with her sister ship MSC Grandiosa, boasting an impressive array of guest features all coming together in perfect harmony to deliver the ultimate cruise holiday experience.",
        img: "../images/ships/msc",
        shipFacts: {},
        guests: "0",
        crew: "0",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/msc-cruises-msc-world-america?ship=15240",
        shipname: "MSC World America",
        overview:
          "MSC Virtuosa’s namesake comes from the word virtuoso, meaning someone highly skilled in any field of activity, and so MSC Virtuosa was named to pay homage to the skill and expertise of the MSC Cruises architects and shipyard partners at Les Chantiers de l’Atlantique who designed and built this innovative class of ships. This new cruise ship is one of the two largest in MSC Cruises’ fleet, along with her sister ship MSC Grandiosa, boasting an impressive array of guest features all coming together in perfect harmony to deliver the ultimate cruise holiday experience.",
        img: "../images/ships/msc",
        shipFacts: {},
        guests: "0",
        crew: "0",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/msc-cruises-msc-world-europa?ship=14964",
        shipname: "MSC World Europa",
        overview:
          "Imagine the future of cruising on board the new revolutionary MSC World Europa, the first ship in the trailblazing MSC World class fleet. LNG powered – one of the world’s cleanest marine fuels, and featuring breakthrough green technologies MSC World Europa symbolizes the beginning of a new era of cruising. Yet another example of MSC Cruises’ firm long-term environmental commitment and responsibility to the future, this unique prototype features a ground-breaking design, where every detail is conceived to offer you a taste of a new cruising experience. It’s a class of emotions, for a ship never seen before.",
        img: "../images/ships/msc",
        shipFacts: {},
        guests: "0",
        crew: "0",
      },
      {
        resShipUrl: "",
        shipname: "Explora I",
        overview: "",
        img: "../images/ships/msc",
        shipFacts: {},
        guests: "0",
        crew: "0",
      },
      {
        resShipUrl: "",
        shipname: "Explora II",
        overview: "",
        img: "../images/ships/msc",
        shipFacts: {},
        guests: "0",
        crew: "0",
      },
    ],
  },
  {
    title: "Norwegian Cruise Line",
    logo: ncl,
    online: true,
    resUrl:
      "https://res.cruisemaster-me.com/swift/cruise/cruiseline/norwegian-cruise-line?siid=177652&cruiseline=6",
    url: "/major_cruise_lines/norwegiancruiseline",
    cruiseid: "6",
    urlName: "norwegiancruiseline",
    banner: `${CDN_BASE_URL}/img/ships/NCL/NCL_ML.jpg`,
    overview:
      "Norwegian Cruise Line has been the premier innovator in the cruise line industry for over 54 years. We were the first to eliminate set dining times and provide guests with the freedom to cruise on their schedule to over 450 incredible destinations around the world. Guests are welcomed aboard with a wide variety of complimentary and specialty dining options, entertainment that ranges from live music and comedy to shows straight from Broadway and our wide range of accommodation that are perfect for any type of traveller. In the coming years, Norwegian’s commitment to innovation will continue to raise the bar for the cruise industry. Our award-winning fleet is constantly being improved and expanded. From our newest ships, like Norwegian Encore and Norwegian Bliss, to vessels that are being updated from bow to stern and beyond with Norwegian Edge, our guests are provided with the best on board experience. ",

    aboutships:
      "When you sail with Norwegian Cruise Line, you are cruising on the most innovative and accommodating fleet on the high seas. Our award-winning fleet was built for Freestyle, designed to give you freedom and flexibility. Choose your ship, choose your destination and step on board for the perfect holiday.",
    brochures: [],
    cruises: [
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/norwegian-cruise-line-norwegian-aqua?ship=15329",
        shipname: "Norwegian Aqua",
        overview: "",
        img: "../images/ships/ncl/Bliss.jpeg",
        shipFacts: {},
        guests: "0",
        crew: "0",
        decks: "0",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/norwegian-cruise-line-norwegian-bliss?ship=13670",
        shipname: "Norwegian Bliss",
        overview:
          "Custom-built for the spectacular, Norwegian Bliss features a revolutionary Observation Lounge for you to soak in every stunning moment, from bald eagles soaring over glaciers to dolphins splashing through warm turquoise waters. Come aboard and experience the best dining, entertainment, and amenities at sea against a backdrop of unrivaled natural beauty. Whether you choose to go tropical or a little wild, there’s one word to describe the experiences awaiting you on one of Norwegian’s newest ships: Bliss.",
        img: "../images/ships/ncl/Bliss.jpeg",
        shipFacts: {},
        guests: "0",
        crew: "0",
        decks: "0",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/norwegian-cruise-line-norwegian-breakaway?ship=13507",
        shipname: "Norwegian Breakaway",
        overview:
          "Named Best Cruise Ship for Families by USA Today, there’s something on board for everyone. Breathe in the fresh ocean air and connect with the sea like never before along The Waterfront, a quarter-mile oceanfront promenade lined with restaurants, bars, and spectacular views. Indulge in more than 25 dining experiences, including three new, dedicated seafood venues. And enjoy dazzling performances - Six, Burn the Floor, and Velvet. Norwegian Breakaway offers magnificent amenities at sea.",
        img: "../images/ships/ncl/Breakaway.jpeg",
        shipFacts: {},
        guests: "0",
        crew: "0",
        decks: "0",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/norwegian-cruise-line-norwegian-dawn?ship=48",
        shipname: "Norwegian Dawn",
        overview:
          "Norwegian Dawn offers the ultimate freedom and flexibility for your cruise holiday. On board, dine at Los Lobos Cantina, a sophisticated Mexican restaurant celebrating traditional flavours with a modern twist. Drop by The Cellars, a Michael Mondavi Family Wine Bar for a wine tasting. Or take Lady Luck for a spin in the expansive Dawn Club Casino. Offering a wide range of spacious accommodation, there are staterooms to fit every family size and budget. Let Norwegian Dawn whisk you away to some of the dreamiest destinations around the world.",
        img: "../images/ships/ncl/Dawn.jpeg",
        shipFacts: {},
        guests: "0",
        crew: "0",
        decks: "0",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/norwegian-cruise-line-norwegian-encore?ship=14090",
        shipname: "Norwegian Encore",
        overview:
          'Repeat perfect moments on our newest, most incredible ship, Norwegian Encore — tailor-made for “Again! Again!" Whip around the Norwegian Encore Speedway, the largest race track at sea. Experience an otherwordly virtual reality wonderland at Galaxy Pavilion. Raise the roof every night at our spectacular shows: winner of six Tony Awards®, Kinky Boots is a must-see Broadway hit, or sing along to classic rock hits at The Choir Of Man. Give your taste buds a double dose of delicious at the many dining options, including the brand-new Onda by Scarpetta. Do all this and so much more on Norwegian Encore. Because once is never enough.',
        img: "../images/ships/ncl/Encore.jpeg",
        shipFacts: {},
        guests: "0",
        crew: "0",
        decks: "0",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/norwegian-cruise-line-norwegian-epic?ship=13236",
        shipname: "Norwegian Epic",
        overview:
          "Awarded Best Cruise Ship Entertainment by Frommer’s, Norwegian Epic keeps the bar high with two dazzling shows: Burn the Floor and Priscilla, Queen of the Desert. Not only is Norwegian Epic offering world-class performers but a new wave of accommodation as well - from Studios, designed and priced for the solo traveller, to privacy in The Haven. Add a wide variety of dining options and you'll understand why this is Freestyle Cruising on a truly grand scale.",
        img: "../images/ships/ncl/Epic.jpeg",
        shipFacts: {},
        guests: "0",
        crew: "0",
        decks: "0",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/norwegian-cruise-line-norwegian-escape?ship=13669",
        shipname: "Norwegian Escape",
        overview:
          "Get ready to sail on one of the most exciting ships on the seas! Indulging in every whim is what unforgettable holidays are made of. Get ready to chase sunsets on The Waterfront. Sip on some of Napa's best blends at The cellars, A Michael Mondavi Family Wine Bar. Or try our unique specialty hand-crafted cocktails. Be dazzled by Broadway hits like After Midnight. Discover a new world of freedom and flexibility on Norwegian Escape.",
        img: "../images/ships/ncl/Escape.jpeg",
        shipFacts: {},
        guests: "0",
        crew: "0",
        decks: "0",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/norwegian-cruise-line-norwegian-gem?ship=1100",
        shipname: "Norwegian Gem",
        overview:
          "This sparkling cruise ship is the perfect choice for year-round cruises in style. With tons of dining choices and no set dining times, Norwegian Gem has it all. Chill out by the pool, get lucky in the casino, unwind at the spa, and make the kids happy with spaces built with them in mind. Accommodation range from the luxurious multi-room or romantic suites to spacious and affordable staterooms.",
        img: "../images/ships/ncl/Gem.jpeg",
        shipFacts: {},
        guests: "0",
        crew: "0",
        decks: "0",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/norwegian-cruise-line-norwegian-getaway?ship=13608",
        shipname: "Norwegian Getaway",
        overview:
          "Norwegian Getaway combines the most magnificent amenities Norwegian has to offer with unforgettable destinations. Stroll The Waterfront, an innovative, industry-first open-air promenade designed to connect guests with the ocean like no other cruise line. Indulge in 20 dining options, experience the thrill of five water slides, and three levels of action-packed activities in the sports complex. The excitement and entertainment continues with Broadway musical Million Dollar Quartet.",
        img: "../images/ships/ncl/Getaway.jpeg",
        shipFacts: {},
        guests: "0",
        crew: "0",
        decks: "0",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/norwegian-cruise-line-norwegian-jade?ship=1159",
        shipname: "Norwegian Jade",
        overview:
          "As you cruise from port to port along stunning coastlines, we'll keep you entertained day and night with exciting nightclubs, award-winning restaurants, a grand casino, swimming pools, a pampering spa and so much more. There's tons of fun for kids too, so bring the whole family and enjoy the adventure of a lifetime. It's no wonder we are the World Travel Award winner for \"Europe's Leading Cruise Line\" 13 years in a row.",
        img: "../images/ships/ncl/Jade.jpeg",
        shipFacts: {},
        guests: "0",
        crew: "0",
        decks: "0",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/norwegian-cruise-line-norwegian-jewel?ship=95",
        shipname: "Norwegian Jewel",
        overview:
          "As the namesake of our \"Jewel Class\" cruise ships, you can bet Norwegian Jewel has it all. As well as offering some of the largest suites at sea, there are staterooms to accommodate every style. When it comes to entertainment, this ship's filled with excitement: a large variety of dining options, vibrant bars and nightclubs, and that's just the beginning.",
        img: "../images/ships/ncl/Jewel.jpeg",
        shipFacts: {},
        guests: "0",
        crew: "0",
        decks: "0",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/norwegian-cruise-line-norwegian-joy?ship=13867",
        shipname: "Norwegian Joy",
        overview:
          "Enjoy a rush like no other as you race in the middle of the ocean on a thrilling two–level race track. Immerse yourself in all the wonder of a virtual world in the Galaxy Pavilion. Be pampered in luxury in our spacious and beautifully appointed accommodation. Explore the wilds of Alaska including pods of whales up close in untouched Icy Strait Point. Enjoy holiday experiences that will delight your senses and calm your soul on Norwegian Joy.",
        img: "../images/ships/ncl/Joy.jpeg",
        shipFacts: {},
        guests: "0",
        crew: "0",
        decks: "0",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/norwegian-cruise-line-norwegian-pearl?ship=1097",
        shipname: "Norwegian Pearl",
        overview:
          "We invite you to experience the wonders of getting there aboard Norwegian Pearl. Her 16 chic dining options, 15 bars and lounges, dazzling casino, tranquil spa, and spacious Garden Villas are just a few things that make this Jewel Class cruise ship a destination of her own.",
        img: "../images/ships/ncl/Pearl.jpeg",
        shipFacts: {},
        guests: "0",
        crew: "0",
        decks: "0",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/norwegian-cruise-line-norwegian-prima?ship=15089",
        shipname: "Norwegian Prima",
        overview:
          "Welcome aboard the first of a new class of ships designed to elevate every expectation. Be the first to explore the greater wide open and enjoy the expansive outdoor deck space on the most spacious new cruise ship at sea. Dip into the horizon at Infinity Beach, walk over water on our new glass bridge and discover many more amazing experiences. Never wait a second for that second round - or anything else. Revel in the highest staff levels of any new ship and always feel prioritized.",
        img: "../images/ships/ncl/Pride of America.jpeg",

        shipFacts: {},
        guests: "0",
        crew: "0",
        decks: "0",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/norwegian-cruise-line-norwegian-sky?ship=1179",
        shipname: "Norwegian Sky",
        overview:
          "From the moment you step aboard, you'll see that Norwegian Sky, refurbished from bow to stern in 2019, offers something for everyone. Your options are nearly limitless with 10 dining options, a wide variety of bars and lounges, an onboard spa, and a cruise casino that has all of your favourite games and slots. And, if you decide to bring the kids, we'll keep them entertained all day, while you lounge by the pool, play in the casino, or enjoy dinner at one of many restaurants.",
        img: "../images/ships/ncl/Sky.jpeg",
        shipFacts: {},
        guests: "0",
        crew: "0",
        decks: "0",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/norwegian-cruise-line-norwegian-spirit?ship=94",
        shipname: "Norwegian Spirit",
        overview:
          "Get ready to experience laid-back luxury as you set sail on the all-new Norwegian Spirit, modernized from bow-to-stern with the adult cruiser in mind. Our most extensive ship renovation ever, harmonized to offer the best of Norwegian Cruise Line's exceptional fleet. Every stateroom modernized. Every bar and lounge redesigned. Every dining experience elevated, featuring our new fine Italian restaurant Onda by Scarpetta.",
        img: "../images/ships/ncl/Spirit.jpeg",
        shipFacts: {},
        guests: "0",
        crew: "0",
        decks: "0",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/norwegian-cruise-line-norwegian-star?ship=52",
        shipname: "Norwegian Star",
        overview:
          "Recently refurbished as part of The Norwegian Edge programme, Norwegian Star brings freedom and flexibility to cruises. Onboard, Norwegian Star features a large delicious dining options, bars and lounges, a sprawling spa, an always-exciting casino with VIP area, plus tons of fun for kids of every age.",
        img: "../images/ships/ncl/Star.jpeg",
        shipFacts: {},
        guests: "0",
        crew: "0",
        decks: "0",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/norwegian-cruise-line-norwegian-sun?ship=53",
        shipname: "Norwegian Sun",
        overview:
          "Where in the world do you want to cruise? While you're becoming a world traveller, you won't have to worry about what to do or where to go, as there are plenty of dining options, a wide variety of bars and lounges, the relaxing Mandara Spa, and the always-exciting Sun Club Casino.",
        img: "../images/ships/ncl/Sun.jpeg",
        shipFacts: {},
        guests: "0",
        crew: "0",
        decks: "0",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/norwegian-cruise-line-norwegian-viva?ship=15159",
        shipname: "Norwegian Viva",
        overview: "",
        img: "../images/ships/ncl/Sun.jpeg",
        shipFacts: {},
        guests: "0",
        crew: "0",
        decks: "0",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/norwegian-cruise-line-pride-of-america?ship=56",
        shipname: "Pride of America",
        overview:
          "Aloha! Come aboard Pride of America, our only cruise ship that sails round trip from Honolulu year-round so you can holiday on your schedule. As you journey from one exotic island to the next, learn about the Hawaiian culture through the eyes of native ambassadors on board our ship. Island hop Hawaii in style, with a wide variety of restaurants and bars and lounges, excellent family accommodation, spacious suites and balconies - perfect for whale watching, witnessing Kilauea Volcano or taking in the dramatic views of the Napali Coast. Come aboard and learn why no one does Hawai'i like Norwegian.",
        img: "../images/ships/ncl/Pride of America.jpeg",
        shipFacts: {},
        guests: "0",
        crew: "0",
        decks: "0",
      },
    ],
  },
  {
    title: "Resorts World Cruises",
    logo: resort,
    online: false,
    resUrl: "",
    url: "/major_cruise_lines/resortsworldcruises",
    cruiseid: "8",
    urlName: "resortsworldcruises",
    banner: `${CDN_BASE_URL}/img/ships/Resorts/resorts.jpg`,
    overview:
      "Welcome aboard the Genting Dream, the flagship vessel of Resorts World Cruises. For 50 years, we have been pioneers and trendsetters in the hospitality industry, offering unparalleled luxury cruising experiences. Prepare to indulge in innovative and unique experiences, with a diverse array of cuisine, entertainment, and themes from around the world, for a journey limited only by your imagination.",

    aboutships:
      "There are so many reasons to get excited about a Royal Caribbean® cruise. Choose from itineraries that take you to top-rated destinations, from sun-soaked Caribbean isles to ancient temples in Asia and everywhere in between. And make the most out of every moment at sea onboard the world’s best cruise ships, loaded from bow to stern with game-changing thrills, dazzling entertainment, next-level dining and activities for all kinds of adventure-seekers. No matter which ship you choose to sail on, this is a fleet filled with incredible feats.",
    brochures: [],
    cruises: [
      {
        resShipUrl: "",
        shipname: "Genting Dream",
        overview: "",
        img: "",
        shipFacts: {},
        guests: "0",
        crew: "0",
        decks: "15",
      },
      {
        resShipUrl: "",
        shipname: "Resorts World One",
        overview: "",
        img: "",
        shipFacts: {},
        guests: "0",
        crew: "0",
        decks: "15",
      },
    ],
  },
  {
    title: "Royal Caribbean International",
    logo: rc,
    online: true,
    resUrl:
      "https://res.cruisemaster-me.com/swift/cruise/cruiseline/royal-caribbean?siid=177652&cruiseline=8",
    url: "/major_cruise_lines/royalcaribbeaninternational",
    cruiseid: "8",
    urlName: "royalcaribbeaninternational",
    banner: `${CDN_BASE_URL}/img/ships/Royal Car/rc.jpg`,
    overview:
      "Royal Caribbean International is known for driving innovation at sea and has continuously redefined cruise vacationing since its launch in 1969. Each successive class of ships is an architectural marvel and pushes the envelope of what is possible on a cruise ship. Throughout, guests sail on exciting itineraries to some of the world’s most popular destinations and enjoy Royal Caribbean’s friendly and engaging service. There are so many reasons to get excited about a Royal Caribbean® cruise. Choose from itineraries that take you to top-rated destinations, from sun-soaked Caribbean isles to ancient temples in Asia and everywhere in between. And make the most out of every moment at sea onboard the world’s best cruise ships, loaded from bow to stern with game-changing thrills, dazzling entertainment, next-level dining and activities for all kinds of adventure-seekers. No matter which ship you choose to sail on, this is a fleet filled with incredible feats.",

    aboutships:
      "There are so many reasons to get excited about a Royal Caribbean® cruise. Choose from itineraries that take you to top-rated destinations, from sun-soaked Caribbean isles to ancient temples in Asia and everywhere in between. And make the most out of every moment at sea onboard the world’s best cruise ships, loaded from bow to stern with game-changing thrills, dazzling entertainment, next-level dining and activities for all kinds of adventure-seekers. No matter which ship you choose to sail on, this is a fleet filled with incredible feats.",
    brochures: [],
    cruises: [
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/royal-caribbean-adventure-of-the-seas?ship=71",
        shipname: "Adventure of the Seas",
        overview:
          "There’s something for every kind of seeker onboard Adventure of the Seas. You can get your adrenaline fix with a whip, splashing ride on The Perfect Storm℠ twin racer slides or satisfy your cravings with delicious dishes at world class restaurants. And when the sun goes down, light up the dancefloor to the sound of a live Latin band at Boleros or tune in to a guest performer at the Duck and Dog Pub.",
        img: "../images/ships/caribbean/Adventure.jpg",
        shipFacts: {},
        guests: "0",
        crew: "0",
        decks: "0",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/royal-caribbean-allure-of-the-seas?ship=13233",
        shipname: "Allure of the Seas",
        overview:
          "This Oasis Class favorite brings adventure to soaring new heights. Discover next-level thrills on every deck of Allure of the Seas®, from white-knuckle zip line rides ten decks up to totally splashworthy spaces for kids, like the interactive H20 Zone℠ Water Park. Get down after dark with more bars than any ship out there and savor a world of possibilities right onboard. Recognized for its dazzling onboard entertainment, thrilling attractions and wanderlust-fueled itineraries, this ship has already been named Best Large Cruise Ship, Best for Entertainment, and Best Individual Ship — and it’s just getting started.",
        img: "../images/ships/caribbean/Allure.jpg",
        shipFacts: {},
        guests: "0",
        crew: "0",
        decks: "0",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/royal-caribbean-anthem-of-the-seas?ship=13646",
        shipname: "Anthem of the Seas",
        overview:
          "No matter what side of the Atlantic you’re on, you’re guaranteed to find unbelievable adventure onboard Anthem of the Seas. If you sail from New York, expect sun-soaked tropical shores like Bermuda, St. Kitts, and the newly reimagined Royal Caribbean® private destination, Perfect Day at CocoCay. If you sail from Southampton, get ready for Mediterranean marvels, Iberian isles and the rugged coasts of the Canaries as this Quantum Class favorite returns to Europe in summer 2020. And in the fall, Anthem unlocks the scenic windswept towns of the Great White North on cruises to Canada and New England.",
        img: "../images/ships/caribbean/Anthem.jpg",
        shipFacts: {},
        guests: "0",
        crew: "0",
        decks: "0",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/royal-caribbean-brilliance-of-the-seas?ship=72",
        shipname: "Brilliance of the Seas",
        overview:
          "If you’re looking for one-of-a-kind ways to experience distinct cultures, savor exotic flavors and soak up views that’ll give even the most seasoned travelers wanderlust, this is the cruise ship for you. Exploring colorful coral reefs off the coast of Roatan on a winter cruise through the Western Caribbean, Brilliance of the Seas offers year-round adventures for every kind of explorer.",
        img: "../images/ships/caribbean/Brilliance.jpg",
        shipFacts: {},
        guests: "0",
        crew: "0",
        decks: "0",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/royal-caribbean-enchantment-of-the-seas?ship=73",
        shipname: "Enchantment of the Seas",
        overview:
          "Give your routine a rest and press pause on reality, because adventure is well within reach. Whether you’re chasing thrills on the rock climbing wall or the bungee trampoline, or soaking up the sunshine poolside at the adults-only Solarium, Enchantment of the Seas® is what getaway goals are made of.",
        img: "../images/ships/caribbean/Enchantment.jpg",
        shipFacts: {},
        guests: "0",
        crew: "0",
        decks: "0",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/royal-caribbean-explorer-of-the-seas?ship=74",
        shipname: "Explorer of the Seas",
        overview:
          "This is not your average vacation. This is adventure in a bold new light — on the fleet-favorite Explorer of the Seas®. Soak up the sunshine and chill vibes at the adults-only Solarium. Indulge in authentic Tuscan cuisine at Giovanni’s Table. Then catch a jaw-dropping original production in the Main Theater, or dance the night away at Club Twenty. On this ship, there’s no wrong way to vacay.",
        img: "../images/ships/caribbean/Explorer.jpg",
        shipFacts: {},
        guests: "0",
        crew: "0",
        decks: "0",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/royal-caribbean-freedom-of-the-seas?ship=116",
        shipname: "Freedom of the Seas",
        overview:
          "Top-rated coasts. Bow-to-stern boasts. The best of both worlds combine for an all-in-one adventure on the Amplified Freedom of the Seas®. Wherever you choose to wander, you’ll find plenty of unparalleled thrills onboard in between adventures ashore. Like adrenaline-pumping slides and rides right on deck. A new pool scene designed to level up your time in the sun. And fresh, authentic flavors that will satisfy any craving.",
        img: "../images/ships/caribbean/Freedom.jpg",
        shipFacts: {},
        guests: "0",
        crew: "0",
        decks: "0",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/royal-caribbean-grandeur-of-the-seas?ship=75",
        shipname: "Grandeur of the Seas",
        overview:
          "There’s a reason Grandeur of the Seas is Baltimore’s reigning favorite adventure. Like complimentary Broadway-style entertainment. A sprawling Vegas-style casino. A Rock Climbing Wall high above the waves. Poolside movies beneath the sun— or stars. And dining options that include succulent sushi and intimate chef-hosted dinners. Actually, there are a lot of reasons.",
        img: "../images/ships/caribbean/Grandeur.jpg",
        shipFacts: {},
        guests: "0",
        crew: "0",
        decks: "0",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/royal-caribbean-harmony-of-the-seas?ship=13716",
        shipname: "Harmony of the Seas",
        overview:
          "What do you get when you combine one of the biggest ships in the world with some of the boldest thrills at sea? A gravity defying, status-quo denying, electrifying expedition onboard Harmony of the Seas® that will set a new standard for family vacations. From first-at-sea feats to the favorites you know and love, you’ll find so many ways to make memories onboard — like the tallest slide at sea, Ultimate Abyss℠, deck-defying stunts at the AquaTheater, and the robot-helmed Bionic Bar. Not to mention dazzling Broadway performances and globetrotting gastronomic experiences that go from Asia to the Mediterranean, and beyond.",
        img: "../images/ships/caribbean/Harmony.jpg",
        shipFacts: {},
        guests: "0",
        crew: "0",
        decks: "0",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/royal-caribbean-icon-of-the-seas?ship=15128",
        shipname: "Icon of the Seas",
        overview: "",
        img: "../images/ships/caribbean/Harmony.jpg",
        shipFacts: {},
        guests: "0",
        crew: "0",
        decks: "0",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/royal-caribbean-independence-of-the-seas?ship=1116",
        shipname: "Independence of the Seas",
        overview:
          "Gear up for a glow-in-the-dark laser tag faceoff in Battle for Planet Z℠ and indulge your sweet tooth at Sugar Beach℠. Catch a wave on the FlowRider® surf simulator and race down The Perfect Storm℠ dueling waterslides. Independence of the Seas® is overflowing with bolder ways to fill your time between shores.",
        img: "../images/ships/caribbean/Independence.jpg",
        shipFacts: {},
        guests: "0",
        crew: "0",
        decks: "0",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/royal-caribbean-jewel-of-the-seas?ship=76",
        shipname: "Jewel of the Seas",
        overview:
          "Jewel of the Seas® takes you to some of the world’s most captivating destinations — but it’s also filled from bow to stern with plenty of unforgettable experiences. Fill sea days with poolside lounging in between visits to adventure-packed ports, and elevate every night with world-class dining, creative cocktails, and electrifying entertainment.",
        img: "../images/ships/caribbean/Jewel.jpg",
        shipFacts: {},
        guests: "0",
        crew: "0",
        decks: "0",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/royal-caribbean-liberty-of-the-seas?ship=1093",
        shipname: "Liberty of the Seas",
        overview:
          "From supercharged slides and adrenaline amping rides to delicious dishes, action-packed nightlife and jaw-dropping entertainment. No matter what kind of thrills you’re after, this is one unforgettable cruise getaway you’ll be bragging about for years to come.",
        img: "../images/ships/caribbean/Liberty.jpg",
        shipFacts: {},
        guests: "0",
        crew: "0",
        decks: "0",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/royal-caribbean-mariner-of-the-seas?ship=79",
        shipname: "Mariner of the Seas",
        overview:
          "The newly amped up Mariner of the Seas® is packed with more ways to play on the most maxed-out short getaway ever. Launch yourself into a virtual world on a new bungee trampoline experience — Sky Pad®. Hang ten on the newly added FlowRider® surf simulator or race down The Perfect Storm℠ waterslides. Bond over family-style Tuscan eats at Jamie’s Italian by Jamie Oliver, or kick off date night at The Bamboo Room",
        img: "../images/ships/caribbean/Mariner.jpg",
        shipFacts: {},
        guests: "0",
        crew: "0",
        decks: "0",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/royal-caribbean-navigator-of-the-seas?ship=81",
        shipname: "Navigator of the Seas",
        overview:
          "Calling all thrill seekers — you’re in for an unforgettable vacation adventure on a cruise ship unlike any other. Think tide-turning new rides, next level lounging, and a new pool deck with double the surface area for you to swim, splash and lounge. Plus craveable dining and new nightlife hotspots that dial the excitement way up.",
        img: "../images/ships/caribbean/Navigator.jpg",
        shipFacts: {},
        guests: "0",
        crew: "0",
        decks: "0",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/royal-caribbean-oasis-of-the-seas?ship=1191",
        shipname: "Oasis of the Seas",
        overview:
          "Beyond the first of its class, Oasis of the Seas® was also the ship that launched a vacation revolution. And now this Oasis Class favorite will introduce even more new thrills for couples and kids of all ages, upgraded ways to soak up the sun or enjoy the shade, more delicious dining options than ever to elevate date night, and buzzworthy new spots to light up every evening.",
        img: "../images/ships/caribbean/Oasis.jpg",
        shipFacts: {},
        guests: "0",
        crew: "0",
        decks: "0",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/royal-caribbean-odyssey-of-the-seas?ship=14698",
        shipname: "Odyssey of the Seas",
        overview:
          "There’s never been a cruise ship that maxes out memory making like Odyssey of the Seas℠. Whether you’re taming waves on the FlowRider®, kicking back at the totally reimagined pool deck, savoring bold global flavors onboard, or turning up the party vibes late at night — you’ll never run out of things to do onboard Odyssey of the Seas℠.",
        img: "../images/ships/caribbean/Odyssey.jpg",
        shipFacts: {},
        guests: "0",
        crew: "0",
        decks: "0",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/royal-caribbean-ovation-of-the-seas?ship=13717",
        shipname: "Ovation of the Seas",
        overview:
          "Pioneer America’s last frontier onboard one of the world’s most groundbreaking ships, Ovation of the Seas®, the biggest boldest ship to ever sail Alaska. It’s scenery scoping from the unrivaled perspective of North Star® — the highest vantage point on any cruise ship. Edge-of-your-seat entertainment, game-changing family fun you can enjoy in any climate, and breathtaking vistas from every stateroom — even interior staterooms offer real-time views from a Virtual Balcony℠. All between unforgettable destinations from Juneau to Skagway. No other cruise ship lets you see Alaska like this.",
        img: "../images/ships/caribbean/Ovation.jpg",
        shipFacts: {},
        guests: "0",
        crew: "0",
        decks: "0",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/royal-caribbean-quantum-of-the-seas?ship=13645",
        shipname: "Quantum of the Seas",
        overview:
          "The first of its class, Quantum of the Seas® is a groundbreaking ship filled with revolutionary feats from bow to stern. Think show-stopping entertainment that blends art and music with cutting-edge technology. Thrilling activities the whole family can enjoy in any weather. And unparalleled views from every room — even Interior staterooms let you scope out the sights in real time from a Virtual Balcony℠.",
        img: "../images/ships/caribbean/Qantum.jpg",
        shipFacts: {},
        guests: "0",
        crew: "0",
        decks: "0",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/royal-caribbean-radiance-of-the-seas?ship=83",
        shipname: "Radiance of the Seas",
        overview:
          "Linger longer in iconic destinations onboard a ship designed for exploration. Dazzling with the most glass of any Royal Caribbean® ship, Radiance of the Seas® is perfect for glacier gazing in Alaska and soaking up the sun in the tropics. When you aren’t chasing adventure onshore, unwind poolside while taking in the views, stretch your muscles on the Rock Climbing Wall, and sing along to show-stopping performances onboard.",
        img: "../images/ships/caribbean/Radiance.jpg",
        shipFacts: {},
        guests: "0",
        crew: "0",
        decks: "0",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/royal-caribbean-rhapsody-of-the-seas?ship=84",
        shipname: "Rhapsody of the Seas",
        overview:
          "Sea days aboard Rhapsody of the Seas are as exciting as each day in port. Get ready for a world of new discoveries on deck — like world class dining, award-winning entertainment, electrifying nightlife and unforgettable activities for every kind of traveler.",
        img: "../images/ships/caribbean/Rhapsody.jpg",
        shipFacts: {},
        guests: "0",
        crew: "0",
        decks: "0",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/royal-caribbean-serenade-of-the-seas?ship=85",
        shipname: "Serenade of the Seas",
        overview:
          "No matter where in the world you’re sailing, Serenade of the Seas® keeps you close to all the natural splendor. Once onboard, panoramic views and acres of glass ensure you’ll never miss a moment of the surrounding scenery. In between adventures onshore, you can soak up the sun poolside, tee off on the mini golf green, and dig into world-class flavors that will take you from Tuscany to Tokyo.",
        img: "../images/ships/caribbean/Serenade.jpg",
        shipFacts: {},
        guests: "0",
        crew: "0",
        decks: "0",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/royal-caribbean-spectrum-of-the-seas?ship=14118",
        shipname: "Spectrum of the Seas",
        overview: "",
        img: "../images/ships/caribbean/Serenade.jpg",
        shipFacts: {},
        guests: "0",
        crew: "0",
        decks: "0",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/royal-caribbean-star-of-the-seas?ship=15330",
        shipname: "Star of the Seas",
        overview: "",
        img: "../images/ships/caribbean/Serenade.jpg",
        shipFacts: {},
        guests: "0",
        crew: "0",
        decks: "0",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/royal-caribbean-symphony-of-the-seas?ship=13825",
        shipname: "Symphony of the Seas",
        overview:
          "Get ready for a perception remixing, memory maxing mic drop — the new Symphony of the Seas® is all that and more. It’s your favorite onboard hits, including the ten-story test of courage, Ultimate Abyss℠ and twin FlowRider® surf simulators, plus revolutionary new firsts, like glow-in-the-dark laser tag. And deck-defying attractions aren’t the only things to look forward to when you sail onboard the newest cruise ship addition to the Oasis Class. Symphony of the Seas® is also loaded with delicious new ways to tempt your taste buds — like the gameday game changing Playmakers℠ Sports Bar & Arcade — and larger-than-life ways to stay, like the Ultimate Family Suite. Go get your thrill on.",
        img: "../images/ships/caribbean/Symphony.jpg",
        shipFacts: {},
        guests: "0",
        crew: "0",
        decks: "0",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/royal-caribbean-utopia-of-the-seas?ship=15215",
        shipname: "Utopia of the Seas",
        overview: "",
        img: "../images/ships/caribbean/Spectrum.jpg",
        shipFacts: {},
        guests: "0",
        crew: "0",
        decks: "0",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/royal-caribbean-vision-of-the-seas?ship=88",
        shipname: "Vision of the Seas",
        overview:
          "There’s a great big world out there full of new places to explore, and Vision of the Seas® is the perfect ship to see it from. No matter where you wander, it’s full of ways to make memories in between onshore adventures — like Broadway-style shows, dining that always hits the spot, and activities that make every moment onboard wow-worthy.",
        img: "../images/ships/.jpg",
        shipFacts: {},
        guests: "0",
        crew: "0",
        decks: "0",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/royal-caribbean-voyager-of-the-seas?ship=89",
        shipname: "Voyager of the Seas",
        overview:
          "Take on three stories of twisting, turning adventure on two high-speed waterslides in The Perfect Storm℠ or go head to head in a glow-in-the-dark laser tag clash in Battle for Planet Z℠. Hang ten on the FlowRider℠ surf simulator or recharge and reinvigorate at the newly designed spa and adults-only solarium. Plus enjoy Voyager Dunes, ice-skating, rock-climbing wall, and totally reimagined spaces for every kid and teen.",
        img: "../images/ships/caribbean/Voyager.jpg",
        shipFacts: {},
        guests: "0",
        crew: "0",
        decks: "0",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/royal-caribbean-wonder-of-the-seas?ship=14719",
        shipname: "Wonder of the Seas",
        overview:
          "The all-new Wonder of the Seas brings epic Oasis Class feats to China for the first time ever. The biggest ship in the world and the first of its class to feature eight unique neighborhoods — including the luxurious new Suite Neighborhood — Wonder of the Seas offers even more room for onboard adventure, palate-pleasing dining and awe-inspiring entertainment.",
        img: "../images/ships/caribbean",
        shipFacts: {},
        guests: "0",
        crew: "0",
        decks: "0",
      },
    ],
  },
];
