import "./styles.scss";
import { Container } from "react-bootstrap";
import Banner from "../../Components/Banner";
import { Helmet } from "react-helmet";

function PrivacyPolicy() {
  return (
    <div className="privacy column-padding-b">
      <Helmet>
        <title>Privacy Policy</title>
        <meta
          name="description"
          content="Our Privacy Policy explains how we collect, use, and protect your personal information during your interactions with our services."
        />
        <meta property="og:title" content="Privacy Policy" />
        <meta
          property="og:description"
          content="Our Privacy Policy explains how we collect, use, and protect your personal information during your interactions with our services."
        />
      </Helmet>
      <Banner link="Privacy Policy" link2=""/>
      <Container>
        <p className="font-regular font-para-black  mb-32">
          Cruise Master Middle East (CME) is committed to protect your personal
          information and privacy. The purpose of this page is to inform you of
          the current website privacy policy of CME with respect to personal
          information collected through this website.
        </p>
        <p className="font-regular font-para-black  mb-32">
          By visiting our web site, you can view most of our pages without
          disclosing any information, whether private or otherwise.
        </p>
        <p className="font-regular font-para-black  mb-32">
          When requesting information from us, such as a price quote for a
          vacation or cruise package, opt-in to our newsletter, or to book a
          vacation, we need certain information to service your request
          efficiently. This includes, but is not limited to - name and address,
          phone number, e-mail address, date of birth, citizenship, and a credit
          card number for reservations.
        </p>
        <p className="font-regular font-para-black  mb-32">
          When booking a cruise vacation, some of your personal information
          needs to be made available to the respective supplier, who in turn
          provides the services you requested.
        </p>
        <p className="font-regular font-para-black  mb-32">
          CME does not trade, sell, share or distribute your personal
          information to anyone, except to service your request, or if dictated
          by law.
        </p>
        <p className="font-regular font-para-black  mb-32">
          CME reserves the right to modify and update this policy at any time,
          and such updates or modifications shall be effective immediately upon
          posting to this website.
        </p>
        <p className="font-regular font-para-black  mb-32">
          This website includes hyperlinks to other pages. CME makes no promises
          or guarantees regarding data collection at the hyperlinked pages and
          or privacy policies of sites that are not owned or controlled by CME.
          CME recommends you read and understand the privacy policies for each
          site that you visit.
        </p>
        <p className="font-regular font-para-black  mb-32">
          Should you have questions, comments or concern about our privacy
          policy, please send an e-mail to{" "}
          <a
            href="mailto:sales@cruisemaster-me.com?subject=Privacy Policy"
            className="font-primary"
          >
            sales@cruisemaster-me.com
          </a>
          .
        </p>
      </Container>
    </div>
  );
}

export default PrivacyPolicy;
