import "./styles.scss";
import Slider from "react-slick";
import { Button } from "antd";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";

interface brochureTypes {
  Brochures: string[];
}

const BrochureSlides: React.FC<brochureTypes> = ({ Brochures }) => {
  const CustomPrevButton: React.FC<{ onClick: () => void }> = ({ onClick }) => (
    <Button
      type="primary"
      shape="circle"
      icon={
        <IoIosArrowBack className="font-primary opacity80 heading mh-32 basic-texts" />
      }
      className="brochure-prev-button"
      onClick={onClick}
    />
  );

  // Custom Next Button component
  const CustomNextButton: React.FC<{ onClick: () => void }> = ({ onClick }) => (
    <Button
      type="primary"
      shape="circle"
      icon={
        <IoIosArrowForward className="font-primary opacity80 heading mh-32 basic-texts" />
      }
      className="brochure-next-button"
      onClick={onClick}
    />
  );

  const settings = {
    dots: true,
    infinite: true,
    cssEase: "linear",
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 3000,
    prevArrow: <CustomPrevButton onClick={() => {}} />,
    nextArrow: <CustomNextButton onClick={() => {}} />,
    centerMode: false,
    pauseOnHover: false,
  };

  return (
    <>
      {Brochures?.length > 1 ? (
        <Slider {...settings} className="cruiseline-brochure">
          {Brochures?.map((item) => (
            <div>
              <div className="flex center full-height">
                <img src={item} alt="" className="pointer" />
              </div>
            </div>
          ))}
        </Slider>
      ) : (
        <div className="flex center cruiseline-brochure full-height">
          <img src={Brochures[0]} alt="" className="pointer" />
        </div>
      )}
    </>
  );
};

export default BrochureSlides;
