import oceania from "../../assets/logos/oceana.png";
import regent from "../../assets/logos/regent.png";
import ritz from "../../assets/logos/ritz.jpg";
import seabourn from "../../assets/logos/seabourn.png";
import silversea from "../../assets/logos/silversea.png";
import windstar from "../../assets/logos/windstar.png";
import viking from "../../assets/logos/viking.png";
import explora from "../../assets/logos/Explora-Journeys.png";
import { CDN_BASE_URL } from "../../Config/apiConfig";

export const luxuryCruiseLines = [
  {
    title: "Explora Journeys",
    cruiseid: "0",
    logo: explora,
    online: false,
    resUrl: "",
    url: "/major_cruise_lines/explora",
    urlName: "explora",
    banner: `${CDN_BASE_URL}/img/ships/Explora/explora.jpg`,
    overview:
      "Delve into every aspect of the Explora Journeys ships, encompassing their design and architecture, as well as their safety and technology features.",

    aboutships: "",
    brochures: [],
    cruises: [
      {
        resShipUrl: "",
        shipname: "Explora I",
        overview: "",
        img: "",
        shipFacts: {},
        guests: "2691",
        crew: "1292",
        decks: "13",
      },
      {
        resShipUrl: "",
        shipname: "Explora II",
        overview: "",
        img: "",
        shipFacts: {},
        guests: "2691",
        crew: "1292",
        decks: "13",
      },
    ],
  },
  {
    title: "Oceania Cruises",
    logo: oceania,
    online: true,
    resUrl:
      "https://res.cruisemaster-me.com/swift/cruise/cruiseline/oceania-cruises?siid=177652&cruiseline=14",
    url: "/other_cruise_lines/oceania",
    cruiseid: "14",
    urlName: "oceania",
    banner: `${CDN_BASE_URL}/img/ships/Oceania/OceaniaCruises.jpg`,
    overview:
      "Oceania Cruises is the world’s leading culinary- and destination-focused cruise line. The line’s seven small, luxurious ships carry no more than 1,250 guests and feature the finest cuisine at sea and destination-rich itineraries that span the globe. Expertly curated travel experiences aboard the designer-inspired, small ships call on more than 450 marquee and boutique ports across Europe, Alaska, Asia, Africa, Australia, New Zealand, New England-Canada, Bermuda, the Caribbean, the Panama Canal, Tahiti and the South Pacific in addition to the epic 180-day Around the World Voyages. The brand has an additional 1,200-guest Allura Class ship on order for delivery in 2025.",

    aboutships: "",
    brochures: [],
    cruises: [
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/oceania-cruises-regatta?ship=122&siid=177652",
        shipname: "Regatta",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/oceania-cruises-insignia?ship=120&siid=177652",
        shipname: "Insignia",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/oceania-cruises-nautica?ship=121&siid=177652",
        shipname: "Nautica",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/oceania-cruises-marina?ship=13241&siid=177652",
        shipname: "Marina",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/oceania-cruises-riviera?ship=13485&siid=177652",
        shipname: "Riviera",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/oceania-cruises-sirena?ship=13715&siid=177652",
        shipname: "Sirena",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/oceania-cruises-vista?ship=15084&siid=177652",
        shipname: "Vista",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/oceania-cruises-allura?ship=15257&siid=177652",
        shipname: "Allura",
      },
    ],
  },
  {
    title: "Regent Seven Seas Cruises",
    logo: regent,
    online: true,
    resUrl:
      "https://res.cruisemaster-me.com/swift/cruise/cruiseline/regent-seven-seas-?siid=177652&cruiseline=8116",
    url: "/other_cruise_lines/regent_seven",
    cruiseid: "8116",
    urlName: "regent_seven",
    banner: `${CDN_BASE_URL}/img/ships/Regent/RegentSevenSeasCruises.jpg`,
    overview:
      "Every journey is as varied as the travellers taking it. So, at Regent Seven Seas Cruises – the only truly all-inclusive luxury cruise line – we offer thousands of FREE Unlimited Shore Excursions to explore however you please when sailing to the over 450 ports of call that we sail to across the globe. Travel with the security and comfort of The World’s Most Luxurious Fleet™, enjoying the freedom that comes with our Unrivalled Space at Sea™. Standing upon the deck of a luxury cruise ship the fresh, sea air enlivens the spirit. Beckoning guests ashore are the hidden wonders of far-flung regions and favourite ports of call, waiting to be discovered. Wake up each morning in a spacious suite to a new perspective from your private balcony. Enlighten your day with new insights from knowledgeable, onboard speakers and local guides as you tour, taste and savour the many marvellous destinations. Recall the day’s adventures with good friends and exquisite food at our specialty cruise restaurants, then dance or delight in the rhythm of the evening’s onboard entertainment before settling into your private oasis at sea.",

    aboutships:
      "Bringing you the best in all-inclusive luxury begins with bringing you the most luxurious ships. Our award-winning fleet delivers style and class, from bow to stern, throughout every region of the world. We invite you to come aboard and experience your new home away from home.",
    brochures: [],
    cruises: [
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/regent-seven-seas--seven-seas-grandeur?ship=15109",
        shipname: "Seven Seas Grandeur",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/regent-seven-seas--seven-seas-splendor?ship=14106",
        shipname: "Seven Seas Splendor",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/regent-seven-seas--seven-seas-explorer?ship=13713",
        shipname: "Seven Seas Explorer",
      },

      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/regent-seven-seas--seven-seas-voyager?ship=13294",
        shipname: "Seven Seas Voyager",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/regent-seven-seas--seven-seas-mariner?ship=13295",
        shipname: "Seven Seas Mariner",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/regent-seven-seas--seven-seas-navigator?ship=13296",
        shipname: "Seven Seas Navigator",
      },
    ],
  },
  {
    title: "Seabourn",
    logo: seabourn,
    online: true,
    resUrl:
      "https://res.cruisemaster-me.com/swift/cruise/cruiseline/seabourn?siid=177652&cruiseline=11",
    url: "/other_cruise_lines/seabourn",
    cruiseid: "11",
    urlName: "seabourn",
    banner: `${CDN_BASE_URL}/img/ships/Seabourn/Seabourn.jpg`,
    overview:
      "Seabourn welcomes you to all-inclusive, luxury cruising refined to its purest form. Where hand-selected itineraries take you to places beyond the reach of larger ships. At Seabourn, we are passionate about travel. We believe that traveling for pleasure has a redemptive power that enriches people’s lives. And we believe that people should travel well. Cruising on a Seabourn ship is unlike any other form of travel. The experience is luxurious, yet relaxed — elegant, yet casual — sumptuous, yet understated. Our intimate ships visit the most desirable destinations worldwide, sailing to the heart of landmark cities, as well as to hidden gems where larger vessels cannot follow.",

    aboutships:
      "Seabourn welcomes you to all-inclusive, luxury cruising refined to its purest form. Where hand-selected itineraries take you to places beyond the reach of larger ships. At Seabourn, we are passionate about travel. We believe that traveling for pleasure has a redemptive power that enriches people’s lives. And we believe that people should travel well. Cruising on a Seabourn ship is unlike any other form of travel. The experience is luxurious, yet relaxed — elegant, yet casual — sumptuous, yet understated. Our intimate ships visit the most desirable destinations worldwide, sailing to the heart of landmark cities, as well as to hidden gems where larger vessels cannot follow.",
    brochures: [],
    cruises: [
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/seabourn-seabourn-pursuit?ship=15126",
        shipname: "Seabourn Pursuit",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/seabourn-seabourn-venture?ship=14514",
        shipname: "Seabourn Venture",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/seabourn-seabourn-ovation?ship=13824",
        shipname: "Seabourn Ovation",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/seabourn-seabourn-encore?ship=13714",
        shipname: "Seabourn Encore",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/seabourn-seabourn-quest?ship=13255",
        shipname: "Seabourn Quest",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/seabourn-seabourn-sojourn?ship=13237",
        shipname: "Seabourn Sojourn",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/seabourn-seabourn-odyssey?ship=1190",
        shipname: "Seabourn Odyssey",
      },
    ],
  },
  {
    title: "Silversea",
    logo: silversea,
    online: true,
    resUrl:
      "https://res.cruisemaster-me.com/swift/cruise/cruiseline/silversea-?siid=177652&cruiseline=8115",
    url: "/other_cruise_lines/silversea",
    cruiseid: "8115",
    urlName: "silversea",
    banner: `${CDN_BASE_URL}/img/ships/Silversea/Silversea.jpg`,
    overview:
      "Since its inception in the early 90s, Silversea Cruises has been recognised as the gold standard in luxury cruising, acclaimed both for its legendary European lifestyle and global reach of destinations. The cruise line was the brainchild of Antonio Lefebvre d’Ovidio, a respected Italian jurist and maritime law professor. His idea was to create a new class of elite vessels designed with more space for fewer guests and with the highest levels of personalised service. Lefebvre’s maritime background led to his involvement in shipping and eventually the cruise industry.",

    aboutships:
      "There is something uniquely satisfying about a smaller ship. The intimacy. The friendly camaraderie. The excitement of exploring secluded harbours where others cannot go. Our intimate, ultra-luxury ships can sail up narrow waterways into the heart of a city, or tie up right at the pier while others must anchor off shore. And for those who yearn to explore the new and unknown, Silversea’s 4 expedition ships can transport you to the furthermost boundaries of the planet. Travel is the elixir of life at Silversea and every cruise is a voyage of discovery. Come explore the possibilities on a Silversea cruise.",
    brochures: [],
    cruises: [
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/silversea--silver-cloud-expedition?ship=13758",
        shipname: "Silver Cloud Expedition",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/silversea--silver-dawn?ship=14717",
        shipname: "Silver Dawn",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/silversea--silver-endeavour?ship=15220",
        shipname: "Silver Endeavour",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/silversea--silver-moon?ship=14328",
        shipname: "Silver Moon",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/silversea--silver-muse?ship=13761",
        shipname: "Silver Muse",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/silversea--silver-nova?ship=15112",
        shipname: "Silver Nova",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/silversea--silver-origin?ship=14504",
        shipname: "Silver Origin",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/silversea--silver-ray?ship=15219",
        shipname: "Silver Ray",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/silversea--silver-shadow?ship=13287",
        shipname: "Silver Shadow",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/silversea--silver-spirit?ship=13289",
        shipname: "Silver Spirit",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/silversea--silver-whisper?ship=13288",
        shipname: "Silver Whisper",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/silversea--silver-wind?ship=13286",
        shipname: "Silver Wind",
      },
    ],
  },
  {
    title: "The Ritz Carlton Yacht Collection",
    logo: ritz,
    online: false,
    resUrl: "",
    url: "/other_cruise_lines/theritzcarlton",
    cruiseid: "8116",
    urlName: "theritzcarlton",
    banner: `${CDN_BASE_URL}/img/ships/Ritz/ritz.jpeg`,
    overview:
      "A voyage with The Ritz-Carlton Yacht Collection offers an unprecedented opportunity to be untethered. Here, freedom and flexibility allow immersion in life's exceptional experiences; exploration of earth's exquisite and unexpected hideaways; and stirring of passions and curiosities that create new and deepened connections.",

    aboutships:
      "A voyage with The Ritz-Carlton Yacht Collection offers an unprecedented opportunity to be untethered. Here, freedom and flexibility allow immersion in life's exceptional experiences; exploration of earth's exquisite and unexpected hideaways; and stirring of passions and curiosities that create new and deepened connections.",
    brochures: [],
    cruises: [
      {
        resShipUrl: "",
        shipname: "EVRIMA",
      },
      {
        resShipUrl: "",
        shipname: "ILMA",
      },
      {
        resShipUrl: "",
        shipname: "LUMINARA",
      },
    ],
  },
  {
    title: "Viking Ocean Cruises",
    logo: viking,
    online: true,
    resUrl:
      "https://res.cruisemaster-me.com/swift/cruise/cruiseline/viking-ocean?cruiseline=8175",
    url: "/other_cruise_lines/viking_ocean",
    cruiseid: "8115",
    urlName: "viking_ocean",
    banner: `${CDN_BASE_URL}/img/ships/Viking/viking_ocean_wide.jpg`,
    overview:
      "Viking boasts a fleet of small, all-veranda, modern ocean ships. Each sister ship is engineered at a scale that allows direct access into most ports. Classified by Cruise Critic as “small ships,” the fleet features understated elegance and Scandinavian design.",

    aboutships:
      "There is something uniquely satisfying about a smaller ship. The intimacy. The friendly camaraderie. The excitement of exploring secluded harbours where others cannot go. Our intimate, ultra-luxury ships can sail up narrow waterways into the heart of a city, or tie up right at the pier while others must anchor off shore. And for those who yearn to explore the new and unknown, Silversea’s 4 expedition ships can transport you to the furthermost boundaries of the planet. Travel is the elixir of life at Silversea and every cruise is a voyage of discovery. Come explore the possibilities on a Silversea cruise.",
    brochures: [],
    cruises: [
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/viking-ocean-viking-star?ship=13650",
        shipname: "Viking Star",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/viking-ocean-viking-sea?ship=13711",
        shipname: "Viking Sea",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/viking-ocean-viking-sky?ship=13710",
        shipname: "Viking Sky",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/viking-ocean-viking-orion?ship=13785",
        shipname: "Viking Orion",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/viking-ocean-viking-jupiter?ship=14060",
        shipname: "Viking Jupiter",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/viking-ocean-viking-venus?ship=14532",
        shipname: "Viking Venus",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/viking-ocean-viking-mars?ship=14533",
        shipname: "Viking Mars",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/viking-ocean-viking-neptune?ship=14534",
        shipname: "Viking Neptune",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/viking-ocean-viking-saturn?ship=14535",
        shipname: "Viking Saturn",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/viking-ocean-viking-vela?ship=14536",
        shipname: "Viking Vela",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/viking-ocean-viking-yi-dun?ship=15344",
        shipname: "Viking Yi Dun",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/viking-ocean-viking-vesta?ship=15343",
        shipname: "Viking Vesta",
      },
    ],
  },
  {
    title: "Windstar Cruises",
    logo: windstar,
    online: true,
    resUrl:
      "https://res.cruisemaster-me.com/swift/cruise/cruiseline/windstar?siid=177652&cruiseline=9",
    url: "/other_cruise_lines/windstar_cruise",
    cruiseid: "9",
    urlName: "windstar_cruise",
    banner: `${CDN_BASE_URL}/img/ships/Windstar/WindStar_ML.jpg`,
    overview:
      "Any cruise ship lets you see the world. Windstar brings it close enough so you can fully live it. With just 148 to 342 guests per voyage, you will feel like you are on your own private yacht. See, hear, smell, taste, and feel what it's like to sail on a lovely yacht into a small port, explore a hidden beach, see a parade of penguins, taste a spicy Norwegian Bacalao stew, and altogether lose yourself in a strange and wondrous culture. Windstar's small cruise ships and personalized approach bring you closer to each destination and every port and an unpretentious and relaxed ambiance onboard.",

    aboutships:
      "Windstar's 148 to 342-guest small ships and personalized approach bring you closer to each destination and every port, so you can see the world from new perspectives. Share intimate moments away from the crowds, and immerse yourself in local, exotic cultures, all on a small cruise ship where surprise and delight are constant companions.",
    brochures: [],
    cruises: [
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/windstar-wind-surf?ship=92",
        shipname: "Wind Surf",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/windstar-wind-star?ship=91",
        shipname: "Wind Star",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/windstar-wind-spirit?ship=90",
        shipname: "Wind Spirit",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/windstar-star-pride?ship=13649",
        shipname: "Star Pride",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/windstar-star-breeze?ship=13731",
        shipname: "Star Breeze",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/windstar-star-legend?ship=13732",
        shipname: "Star Legend",
      },
    ],
  },
];
