import { CDN_BASE_URL } from "../Config/apiConfig";

export const destinationNarrow = [
  {
    imagepath: `${CDN_BASE_URL}/img/destinations/Slides/med2.jpg`,
    name: "Mediterranean",
    daycount: "7-14 nights",
    price: "899",
    description: "",
    moreInfo:
      "https://res.cruisemaster-me.com/swift/cruise/destination/mediterranean?destinations=75&destinationtype=All&destinationorriverid=destinationId",
  },
  {
    imagepath: `${CDN_BASE_URL}/img/destinations/Slides/north.jpg`,
    daycount: "7-14 nights",
    name: "Northern Europe",
    price: "899",
    description: "",
    moreInfo:
      "https://res.cruisemaster-me.com/swift/cruise/destination/northern-europe?destinations=39&destinationtype=All&destinationorriverid=destinationId",
  },
  {
    imagepath: `${CDN_BASE_URL}/img/destinations/Slides/alaska.jpg`,
    daycount: "7-14 nights",
    name: "Alaska",
    price: "799",
    description: "",
    moreInfo:
      "https://res.cruisemaster-me.com/swift/cruise/destination/alaska-?destinations=1&destinationtype=All&destinationorriverid=destinationId",
  },
  {
    imagepath: `${CDN_BASE_URL}/img/destinations/Slides/asia.jpg`,
    daycount: "7-14 nights",
    name: "Asia",
    price: "799",
    description: "",
    moreInfo:
      "https://res.cruisemaster-me.com/swift/cruise/destination/asia-and-asia-pacific?destinations=19&destinationtype=All&destinationorriverid=destinationId",
  },
];

export const DestinationList = [
  {
    to: "Africa",
    price: "249",
    description: "",
    moreInfo:
      "https://res.cruisemaster-me.com/swift/cruise/destination/africa?destinations=2&destinationtype=All&destinationorriverid=destinationId",
    daycount: "7-14 nights",
    imagepath: `${CDN_BASE_URL}/img/destinations/africa.jpg`,
  },
  {
    to: "Alaska",
    price: "229",
    description: "",
    moreInfo:
      "https://res.cruisemaster-me.com/swift/cruise/destination/alaska-?destinations=1&destinationtype=All&destinationorriverid=destinationId",
    daycount: "7-14 nights",
    imagepath: `${CDN_BASE_URL}/img/destinations/Alaska.jpg`,
  },
  {
    to: "Amazon (South America)",
    price: "3,749",
    description: "",
    moreInfo:
      "https://res.cruisemaster-me.com/swift/cruise/destination/amazon-south-america-?destinations=185&destinationtype=All&destinationorriverid=destinationId",
    daycount: "7-14 nights",
    imagepath: `${CDN_BASE_URL}/img/destinations/amazon_south_america.jpg`,
  },
  {
    to: "Antarctica",
    price: "11,896",
    description: "",
    moreInfo:
      "https://res.cruisemaster-me.com/swift/cruise/destination/antarctica?destinations=65&destinationtype=All&destinationorriverid=destinationId",
    daycount: "7-14 nights",
    imagepath: `${CDN_BASE_URL}/img/destinations/Antartic.jpg`,
  },
  {
    to: "Arabian Gulf",
    price: "49",
    description: "",
    moreInfo:
      "https://res.cruisemaster-me.com/swift/cruise/destination/arabian-gulf?destinations=54&destinationtype=All&destinationorriverid=destinationId",
    daycount: "7-14 nights",
    imagepath: `${CDN_BASE_URL}/img/destinations/arabian_gulf.jpg`,
  },
  {
    to: "Arctic",
    price: "6,616",
    description: "",
    moreInfo:
      "https://res.cruisemaster-me.com/swift/cruise/destination/arctic?destinations=67&destinationtype=All&destinationorriverid=destinationId",
    daycount: "7-14 nights",
    imagepath: `${CDN_BASE_URL}/img/destinations/arctic.jpg`,
  },
  {
    to: "Asia and Asia Pacific",
    price: "289",
    description: "",
    moreInfo:
      "https://res.cruisemaster-me.com/swift/cruise/destination/asia-and-asia-pacific?destinations=19&destinationtype=All&destinationorriverid=destinationId",
    daycount: "7-14 nights",
    imagepath: `${CDN_BASE_URL}/img/destinations/asia_and_asia_pacific.jpg`,
  },
  {
    to: "Australasia",
    price: "430",
    description: "",
    moreInfo:
      "https://res.cruisemaster-me.com/swift/cruise/destination/australasia?destinations=58&destinationtype=All&destinationorriverid=destinationId",
    daycount: "7-14 nights",
    imagepath: `${CDN_BASE_URL}/img/destinations/australasia.jpg`,
  },
  {
    to: "Australia",
    price: "191",
    description: "",
    moreInfo:
      "https://res.cruisemaster-me.com/swift/cruise/destination/australia?destinations=104&destinationtype=All&destinationorriverid=destinationId",
    daycount: "7-14 nights",
    imagepath: `${CDN_BASE_URL}/img/destinations/australia.jpg`,
  },
  {
    to: "Australia / New Zealand",
    price: "191",
    description: "",
    moreInfo:
      "https://res.cruisemaster-me.com/swift/cruise/destination/australia-new-zealand?destinations=29&destinationtype=All&destinationorriverid=destinationId",
    daycount: "7-14 nights",
    imagepath: `${CDN_BASE_URL}/img/destinations/australia-newz.webp`,
  },
  {
    to: "Bahamas",
    price: "139",
    description: "",
    moreInfo:
      "https://res.cruisemaster-me.com/swift/cruise/destination/bahamas?destinations=7&destinationtype=All&destinationorriverid=destinationId",
    daycount: "7-14 nights",
    imagepath: `${CDN_BASE_URL}/img/destinations/bahamas.jpg`,
  },
  {
    to: "Baltic Sea (Europe)",
    price: "179",
    description: "",
    moreInfo:
      "https://res.cruisemaster-me.com/swift/cruise/destination/baltic-sea-europe-?destinations=142&destinationtype=All&destinationorriverid=destinationId",
    daycount: "7-14 nights",
    imagepath: `${CDN_BASE_URL}/img/destinations/baltic.jpg`,
  },
  {
    to: "Bermuda",
    price: "309",
    description: "",
    moreInfo:
      "https://res.cruisemaster-me.com/swift/cruise/destination/bermuda?destinations=8&destinationtype=All&destinationorriverid=destinationId",
    daycount: "7-14 nights",
    imagepath: `${CDN_BASE_URL}/img/destinations/bermuda.webp`,
  },
  {
    to: "British Isles",
    price: "1024",
    description: "",
    moreInfo:
      "https://res.cruisemaster-me.com/swift/cruise/destination/british-isles?destinations=121&destinationtype=All&destinationorriverid=destinationId",
    daycount: "7-14 nights",
    imagepath: `${CDN_BASE_URL}/img/destinations/british_isles.jpg`,
  },
  {
    to: "Canada",
    price: "3143",
    description: "",
    moreInfo:
      "https://res.cruisemaster-me.com/swift/cruise/destination/canada?destinations=171&destinationtype=All&destinationorriverid=destinationId",
    daycount: "7-14 nights",
    imagepath: `${CDN_BASE_URL}/img/destinations/Canada.jpg`,
  },
  {
    to: "Canada / New England",
    price: "479",
    description: "",
    moreInfo:
      "https://res.cruisemaster-me.com/swift/cruise/destination/canada-new-england?destinations=26&destinationtype=All&destinationorriverid=destinationId",
    daycount: "7-14 nights",
    imagepath: `${CDN_BASE_URL}/img/destinations/canada_newEngland.jpg`,
  },
  {
    to: "Canary Islands",
    price: "569",
    description: "",
    moreInfo:
      "https://res.cruisemaster-me.com/swift/cruise/destination/canary-islands?destinations=68&destinationtype=All&destinationorriverid=destinationId",
    daycount: "7-14 nights",
    imagepath: `${CDN_BASE_URL}/img/destinations/Canary_Islands.jpg`,
  },
  {
    to: "Caribbean",
    price: "139",
    description: "",
    moreInfo:
      "https://res.cruisemaster-me.com/swift/cruise/destination/caribbean?destinations=9&destinationtype=All&destinationorriverid=destinationId",
    daycount: "7-14 nights",
    imagepath: `${CDN_BASE_URL}/img/destinations/Caribbean.jpg`,
  },
  {
    to: "Caribbean Eastern",
    price: "230",
    description: "",
    moreInfo:
      "https://res.cruisemaster-me.com/swift/cruise/destination/caribbean-eastern?destinations=10&destinationtype=All&destinationorriverid=destinationId",
    daycount: "7-14 nights",
    imagepath: `${CDN_BASE_URL}/img/destinations/car_eastern.jpg`,
  },
  {
    to: "Caribbean Southern",
    price: "472",
    description: "",
    moreInfo:
      "https://res.cruisemaster-me.com/swift/cruise/destination/caribbean-southern?destinations=13&destinationtype=All&destinationorriverid=destinationId",
    daycount: "7-14 nights",
    imagepath: `${CDN_BASE_URL}/img/destinations/car_southern.jpg`,
  },
  {
    to: "Caribbean Western",
    price: "280",
    description: "",
    moreInfo:
      "https://res.cruisemaster-me.com/swift/cruise/destination/caribbean-western?destinations=14&destinationtype=All&destinationorriverid=destinationId",
    daycount: "7-14 nights",
    imagepath: `${CDN_BASE_URL}/img/destinations/car_western.jpg`,
  },
  {
    to: "Central America",
    price: "240",
    description: "",
    moreInfo:
      "https://res.cruisemaster-me.com/swift/cruise/destination/central-america?destinations=42&destinationtype=All&destinationorriverid=destinationId",
    daycount: "7-14 nights",
    imagepath: `${CDN_BASE_URL}/img/destinations/central_america.jpg`,
  },
  {
    to: "Central Mediterranean",
    price: "2599",
    description: "",
    moreInfo:
      "https://res.cruisemaster-me.com/swift/cruise/destination/central-mediterranean?destinations=57&destinationtype=All&destinationorriverid=destinationId",
    daycount: "7-14 nights",
    imagepath: `${CDN_BASE_URL}/img/destinations/central_med.jpg`,
  },
  {
    to: "Eastern Europe",
    price: "1299",
    description: "",
    moreInfo:
      "https://res.cruisemaster-me.com/swift/cruise/destination/eastern-europe?destinations=38&destinationtype=All&destinationorriverid=destinationId",
    daycount: "7-14 nights",
    imagepath: `${CDN_BASE_URL}/img/destinations/eastern_europe.jpg`,
  },
  {
    to: "Eastern Mediterranean",
    price: "39",
    description: "",
    moreInfo:
      "https://res.cruisemaster-me.com/swift/cruise/destination/eastern-mediterranean?destinations=16&destinationtype=All&destinationorriverid=destinationId",
    daycount: "7-14 nights",
    imagepath: `${CDN_BASE_URL}/img/destinations/eastern_med.jpg`,
  },
  {
    to: "Europe",
    price: "9",
    description: "",
    moreInfo:
      "https://res.cruisemaster-me.com/swift/cruise/destination/europe?destinations=15&destinationtype=All&destinationorriverid=destinationId",
    daycount: "7-14 nights",
    imagepath: `${CDN_BASE_URL}/img/destinations/europe.jpg`,
  },
  {
    to: "Galapagos",
    price: "5379",
    description: "",
    moreInfo:
      "https://res.cruisemaster-me.com/swift/cruise/destination/galapagos?destinations=60&destinationtype=All&destinationorriverid=destinationId",
    daycount: "7-14 nights",
    imagepath: `${CDN_BASE_URL}/img/destinations/galapagos.jpg`,
  },
  {
    to: "Greenland",
    price: "1062",
    description: "",
    moreInfo:
      "https://res.cruisemaster-me.com/swift/cruise/destination/greenland?destinations=61&destinationtype=All&destinationorriverid=destinationId",
    daycount: "7-14 nights",
    imagepath: `${CDN_BASE_URL}/img/destinations/greenland.jpg`,
  },
  {
    to: "Hawaii",
    price: "748",
    description: "",
    moreInfo:
      "https://res.cruisemaster-me.com/swift/cruise/destination/hawaii?destinations=21&destinationtype=All&destinationorriverid=destinationId",
    daycount: "7-14 nights",
    imagepath: `${CDN_BASE_URL}/img/destinations/Hawaii.jpg`,
  },
  {
    to: "Iceland",
    price: "449",
    description: "",
    moreInfo:
      "https://res.cruisemaster-me.com/swift/cruise/destination/iceland-?destinations=122&destinationtype=All&destinationorriverid=destinationId",
    daycount: "7-14 nights",
    imagepath: `${CDN_BASE_URL}/img/destinations/Iceland.jpg`,
  },
  {
    to: "India",
    price: "1999",
    description: "",
    moreInfo:
      "https://res.cruisemaster-me.com/swift/cruise/destination/india?destinations=73&destinationtype=All&destinationorriverid=destinationId",
    daycount: "7-14 nights",
    imagepath: `${CDN_BASE_URL}/img/destinations/India.jpg`,
  },
  {
    to: "Indian Ocean",
    price: "1999",
    description: "",
    moreInfo:
      "https://res.cruisemaster-me.com/swift/cruise/destination/indian-ocean?destinations=63&destinationtype=All&destinationorriverid=destinationId",
    daycount: "7-14 nights",
    imagepath: `${CDN_BASE_URL}/img/destinations/indian_ocean.jpg`,
  },
  {
    to: "Japan",
    price: "998",
    description: "",
    moreInfo:
      "https://res.cruisemaster-me.com/swift/cruise/destination/japan?destinations=124&destinationtype=All&destinationorriverid=destinationId",
    daycount: "7-14 nights",
    imagepath: `${CDN_BASE_URL}/img/destinations/japan.jpg`,
  },
  {
    to: "Mediterranean",
    price: "10",
    description: "",
    moreInfo:
      "https://res.cruisemaster-me.com/swift/cruise/destination/mediterranean?destinations=75&destinationtype=All&destinationorriverid=destinationId",
    daycount: "7-14 nights",
    imagepath: `${CDN_BASE_URL}/img/destinations/mediterranean.webp`,
  },
  {
    to: "Mexican Riviera",
    price: "529",
    description: "",
    moreInfo:
      "https://res.cruisemaster-me.com/swift/cruise/destination/mexican-riviera?destinations=56&destinationtype=All&destinationorriverid=destinationId",
    daycount: "7-14 nights",
    imagepath: `${CDN_BASE_URL}/img/destinations/mexican_riviera.jpg`,
  },
  {
    to: "Mexico",
    price: "240",
    description: "",
    moreInfo:
      "https://res.cruisemaster-me.com/swift/cruise/destination/mexico?destinations=24&destinationtype=All&destinationorriverid=destinationId",
    daycount: "7-14 nights",
    imagepath: `${CDN_BASE_URL}/img/destinations/mexico.jpg`,
  },
  {
    to: "Middle East",
    price: "220",
    description: "",
    moreInfo:
      "https://res.cruisemaster-me.com/swift/cruise/destination/middle-east?destinations=31&destinationtype=All&destinationorriverid=destinationId",
    daycount: "7-14 nights",
    imagepath: `${CDN_BASE_URL}/img/destinations/middle_east.jpg`,
  },
  {
    to: "Mississippi River (USA)",
    price: "2999",
    description: "",
    moreInfo:
      "https://res.cruisemaster-me.com/swift/cruise/destination/mississippi-river-usa-?destinations=112&destinationtype=All&destinationorriverid=riverId",
    daycount: "7-14 nights",
    imagepath: `${CDN_BASE_URL}/img/destinations/Mississippi_river.jpg`,
  },
  {
    to: "New Zealand",
    price: "430",
    description: "",
    moreInfo:
      "https://res.cruisemaster-me.com/swift/cruise/destination/new-zealand?destinations=105&destinationtype=All&destinationorriverid=destinationId",
    daycount: "7-14 nights",
    imagepath: `${CDN_BASE_URL}/img/destinations/New_Zealand.jpg`,
  },
  {
    to: "North America",
    price: "94",
    description: "",
    moreInfo:
      "https://res.cruisemaster-me.com/swift/cruise/destination/north-america?destinations=48&destinationtype=All&destinationorriverid=destinationId",
    daycount: "7-14 nights",
    imagepath: `${CDN_BASE_URL}/img/destinations/North_america.jpg`,
  },
  {
    to: "Northern Europe",
    price: "20",
    description: "",
    moreInfo:
      "https://res.cruisemaster-me.com/swift/cruise/destination/northern-europe?destinations=39&destinationtype=All&destinationorriverid=destinationId",
    daycount: "7-14 nights",
    imagepath: `${CDN_BASE_URL}/img/destinations/Northern_Europe.jpg`,
  },
  {
    to: "Norway",
    price: "449",
    description: "",
    moreInfo:
      "https://res.cruisemaster-me.com/swift/cruise/destination/norway?destinations=188&destinationtype=All&destinationorriverid=destinationId",
    daycount: "7-14 nights",
    imagepath: `${CDN_BASE_URL}/img/destinations/norway.jpg`,
  },
  {
    to: "Oceania",
    price: "72000",
    description: "",
    moreInfo:
      "https://res.cruisemaster-me.com/swift/cruise/destination/oceania?destinations=74&destinationtype=All&destinationorriverid=destinationId",
    daycount: "7-14 nights",
    imagepath: `${CDN_BASE_URL}/img/destinations/oceana.jpg`,
  },
  {
    to: "Pacific",
    price: "839",
    description: "",
    moreInfo:
      "https://res.cruisemaster-me.com/swift/cruise/destination/pacific?destinations=45&destinationtype=All&destinationorriverid=destinationId",
    daycount: "7-14 nights",
    imagepath: `${CDN_BASE_URL}/img/destinations/pacific.jpg`,
  },
  {
    to: "Pacific Coastal",
    price: "94",
    description: "",
    moreInfo:
      "https://res.cruisemaster-me.com/swift/cruise/destination/pacific-coastal?destinations=47&destinationtype=All&destinationorriverid=destinationId",
    daycount: "7-14 nights",
    imagepath: `${CDN_BASE_URL}/img/destinations/pacific_coastal.jpg`,
  },
  {
    to: "Panama Canal",
    price: "539",
    description: "",
    moreInfo:
      "https://res.cruisemaster-me.com/swift/cruise/destination/panama-canal?destinations=49&destinationtype=All&destinationorriverid=destinationId",
    daycount: "7-14 nights",
    imagepath: `${CDN_BASE_URL}/img/destinations/panama_canal.jpg`,
  },
  {
    to: "Repositioning",
    price: "129",
    description: "",
    moreInfo:
      "https://res.cruisemaster-me.com/swift/cruise/destination/repositioning?destinations=52&destinationtype=All&destinationorriverid=destinationId",
    daycount: "7-14 nights",
    imagepath: `${CDN_BASE_URL}/img/destinations/repositioning.jpg`,
  },
  {
    to: "South America",
    price: "119",
    description: "",
    moreInfo:
      "https://res.cruisemaster-me.com/swift/cruise/destination/south-america?destinations=32&destinationtype=All&destinationorriverid=destinationId",
    daycount: "7-14 nights",
    imagepath: `${CDN_BASE_URL}/img/destinations/south_america.jpg`,
  },
  {
    to: "South Pacific",
    price: "425",
    description: "",
    moreInfo:
      "https://res.cruisemaster-me.com/swift/cruise/destination/south-pacific?destinations=44&destinationtype=All&destinationorriverid=destinationId",
    daycount: "7-14 nights",
    imagepath: `${CDN_BASE_URL}/img/destinations/south_pacific.jpg`,
  },
  {
    to: "Southeast Asia",
    price: "924",
    description: "",
    moreInfo:
      "https://res.cruisemaster-me.com/swift/cruise/destination/south-pacific?destinations=44&destinationtype=All&destinationorriverid=destinationId",
    daycount: "7-14 nights",
    imagepath: `${CDN_BASE_URL}/img/destinations/southeast_asia.jpg`,
  },
  {
    to: "Southern Europe",
    price: "9",
    description: "",
    moreInfo:
      "https://res.cruisemaster-me.com/swift/cruise/destination/southern-europe?destinations=40&destinationtype=All&destinationorriverid=destinationId",
    daycount: "7-14 nights",
    imagepath: `${CDN_BASE_URL}/img/destinations/southern_europe.jpg`,
  },
  {
    to: "Transatlantic",
    price: "129",
    description: "",
    moreInfo:
      "https://res.cruisemaster-me.com/swift/cruise/destination/transatlantic?destinations=34&destinationtype=All&destinationorriverid=destinationId",
    daycount: "7-14 nights",
    imagepath: `${CDN_BASE_URL}/img/destinations/transatlantic.jpg`,
  },
  {
    to: "Western Europe",
    price: "99",
    description: "",
    moreInfo:
      "https://res.cruisemaster-me.com/swift/cruise/destination/western-europe?destinations=41&destinationtype=All&destinationorriverid=destinationId",
    daycount: "7-14 nights",
    imagepath: `${CDN_BASE_URL}/img/destinations/western_europe.jpg`,
  },
  {
    to: "Western Mediterranean",
    price: "20",
    description: "",
    moreInfo:
      "https://res.cruisemaster-me.com/swift/cruise/destination/western-mediterranean?destinations=18&destinationtype=All&destinationorriverid=destinationId",
    daycount: "7-14 nights",
    imagepath: `${CDN_BASE_URL}/img/destinations/western_med.jpg`,
  },
  {
    to: "World Cruise",
    price: "2499",
    description: "",
    moreInfo:
      "https://res.cruisemaster-me.com/swift/cruise/destination/world-cruise?destinations=36&destinationtype=All&destinationorriverid=destinationId",
    daycount: "7-14 nights",
    imagepath: `${CDN_BASE_URL}/img/destinations/world_cruise.jpg`,
  },
  {
    to: "USA River",
    price: "3249",
    description: "",
    moreInfo:
      "https://res.cruisemaster-me.com/swift/cruise/destination/usa-river?destinations=180&destinationtype=All&destinationorriverid=riverId",
    daycount: "7-14 nights",
    imagepath: `${CDN_BASE_URL}/img/destinations/usa_river.jpg`,
  },
  {
    to: "Amazon River (South America)",
    price: "5999",
    description: "",
    moreInfo:
      "https://res.cruisemaster-me.com/swift/cruise/destination/amazon-river-south-america-?destinations=99&destinationtype=All&destinationorriverid=riverId",
    daycount: "7-14 nights",
    imagepath: `${CDN_BASE_URL}/img/destinations/Amazon_River.jpg`,
  },
  {
    to: "Danube (Europe)",
    price: "1524",
    description: "",
    moreInfo:
      "https://res.cruisemaster-me.com/swift/cruise/destination/danube-europe-?destinations=77&destinationtype=All&destinationorriverid=riverId",
    daycount: "7-14 nights",
    imagepath: `${CDN_BASE_URL}/img/destinations/danube_europe.jpg`,
  },
  {
    to: "Dordogne (Europe)",
    price: "2499",
    description: "",
    moreInfo:
      "https://res.cruisemaster-me.com/swift/cruise/destination/dordogne-europe-?destinations=82&destinationtype=All&destinationorriverid=riverId",
    daycount: "7-14 nights",
    imagepath: `${CDN_BASE_URL}/img/destinations/dordogne.jpg`,
  },
  {
    to: "Douro (Europe)",
    price: "3049",
    description: "",
    moreInfo:
      "https://res.cruisemaster-me.com/swift/cruise/destination/douro-europe-?destinations=85&destinationtype=All&destinationorriverid=riverId",
    daycount: "7-14 nights",
    imagepath: `${CDN_BASE_URL}/img/destinations/Douro.jpg`,
  },
  {
    to: "Dutchand Belgian Waterways (Europe)",
    price: "2199",
    description: "",
    moreInfo:
      "https://res.cruisemaster-me.com/swift/cruise/destination/dutchand-belgian-waterways-europe-?destinations=80&destinationtype=All&destinationorriverid=riverId",
    daycount: "7-14 nights",
    imagepath: `${CDN_BASE_URL}/img/destinations/Dutchand.jpg`,
  },
  {
    to: "Elbe (Europe)",
    price: "3499",
    description: "",
    moreInfo:
      "https://res.cruisemaster-me.com/swift/cruise/destination/elbe-europe-?destinations=96&destinationtype=All&destinationorriverid=riverId",
    daycount: "7-14 nights",
    imagepath: `${CDN_BASE_URL}/img/destinations/Elbe.jpg`,
  },
  {
    to: "Ganges (India)",
    price: "6399",
    description: "",
    moreInfo:
      "https://res.cruisemaster-me.com/swift/cruise/destination/ganges-india-?destinations=93&destinationtype=All&destinationorriverid=riverId",
    daycount: "7-14 nights",
    imagepath: `${CDN_BASE_URL}/img/destinations/Ganges_River_India.jpg`,
  },
  {
    to: "Garonne (Europe)",
    price: "2599",
    description: "",
    moreInfo:
      "https://res.cruisemaster-me.com/swift/cruise/destination/garonne-europe-?destinations=81&destinationtype=All&destinationorriverid=riverId",
    daycount: "7-14 nights",
    imagepath: `${CDN_BASE_URL}/img/destinations/Garonne.jpg`,
  },
  {
    to: "Gironde (Europe)",
    price: "2599",
    description: "",
    moreInfo:
      "https://res.cruisemaster-me.com/swift/cruise/destination/gironde-europe-?destinations=95&destinationtype=All&destinationorriverid=riverId",
    daycount: "7-14 nights",
    imagepath: `${CDN_BASE_URL}/img/destinations/Gironde.jpg`,
  },
  {
    to: "Main (Europe)",
    price: "2099",
    description: "",
    moreInfo:
      "https://res.cruisemaster-me.com/swift/cruise/destination/main-europe-?destinations=78&destinationtype=All&destinationorriverid=riverId",
    daycount: "7-14 nights",
    imagepath: `${CDN_BASE_URL}/img/destinations/Main_Europe.jpg`,
  },
  {
    to: "Mekong (Asia)",
    price: "3429",
    description: "",
    moreInfo:
      "https://res.cruisemaster-me.com/swift/cruise/destination/mekong-asia-?destinations=86&destinationtype=All&destinationorriverid=riverId",
    daycount: "7-14 nights",
    imagepath: `${CDN_BASE_URL}/img/destinations/mekong.jpg`,
  },
  {
    to: "Mosselle (Europe)",
    price: "2199",
    description: "",
    moreInfo:
      "https://res.cruisemaster-me.com/swift/cruise/destination/mosselle-europe-?destinations=79&destinationtype=All&destinationorriverid=riverId",
    daycount: "7-14 nights",
    imagepath: `${CDN_BASE_URL}/img/destinations/moselle.jpg`,
  },
  {
    to: "Nile (Africa)",
    price: "3949",
    description: "",
    moreInfo:
      "https://res.cruisemaster-me.com/swift/cruise/destination/nile-africa-?destinations=94&destinationtype=All&destinationorriverid=riverId",
    daycount: "7-14 nights",
    imagepath: `${CDN_BASE_URL}/img/destinations/nile_africa.jpg`,
  },
  {
    to: "Po (Europe)",
    price: "1999",
    description: "",
    moreInfo:
      "https://res.cruisemaster-me.com/swift/cruise/destination/po-europe-?destinations=88&destinationtype=All&destinationorriverid=riverId",
    daycount: "7-14 nights",
    imagepath: `${CDN_BASE_URL}/img/destinations/Po_Europe.jpg`,
  },
  {
    to: "Rhine (Europe)",
    price: "2099",
    description: "",
    moreInfo:
      "https://res.cruisemaster-me.com/swift/cruise/destination/rhine-europe-?destinations=76&destinationtype=All&destinationorriverid=riverId",
    daycount: "7-14 nights",
    imagepath: `${CDN_BASE_URL}/img/destinations/Rhine_Europe.jpg`,
  },
  {
    to: "Rhone (Europe)",
    price: "2599",
    description: "",
    moreInfo:
      "https://res.cruisemaster-me.com/swift/cruise/destination/rhone-europe-?destinations=84&destinationtype=All&destinationorriverid=riverId",
    daycount: "7-14 nights",
    imagepath: `${CDN_BASE_URL}/img/destinations/Rhone_Europe.jpg`,
  },
  {
    to: "Saone (Europe)",
    price: "8555",
    description: "",
    moreInfo:
      "https://res.cruisemaster-me.com/swift/cruise/destination/saone-europe-?destinations=102&destinationtype=All&destinationorriverid=riverId",
    daycount: "7-14 nights",
    imagepath: `${CDN_BASE_URL}/img/destinations/Saon_Europe.jpg`,
  },
  {
    to: "Seine (Europe)",
    price: "2499",
    description: "",
    moreInfo:
      "https://res.cruisemaster-me.com/swift/cruise/destination/seine-europe-?destinations=83&destinationtype=All&destinationorriverid=riverId",
    daycount: "7-14 nights",
    imagepath: `${CDN_BASE_URL}/img/destinations/Sein_Europe.jpg`,
  },
  {
    to: "Europe River",
    price: "2099",
    description: "",
    moreInfo:
      "https://res.cruisemaster-me.com/swift/cruise/destination/europe-river?destinations=179&destinationtype=All&destinationorriverid=riverId",
    daycount: "7-14 nights",
    imagepath: `${CDN_BASE_URL}/img/destinations/Europe_River.jpg`,
  },
  {
    to: "Asia River",
    price: "3429",
    description: "",
    moreInfo:
      "https://res.cruisemaster-me.com/swift/cruise/destination/asia-river?destinations=178&destinationtype=All&destinationorriverid=riverId",
    daycount: "7-14 nights",
    imagepath: `${CDN_BASE_URL}/img/destinations/asia_river.jpg`,
  },
];
