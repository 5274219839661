import { Col, Row } from "react-bootstrap";
import { Button } from "antd";
import { useState } from "react";
import { Modal } from "antd";
import { Form } from "antd";
import { notification } from "antd";
import emailjs from "@emailjs/browser";
import { LoadingOutlined } from "@ant-design/icons";
import { Input } from "antd";
import { Alert } from "antd";
import "./styles.scss";

interface OfflineModalType {
  cruiseLine: {
    title: string;
  };
  isModalOpen: boolean;
  handleOk: () => void;
  handleCancel: () => void;
}

export const OfflineCruiseModal: React.FC<OfflineModalType> = ({
  cruiseLine,
  isModalOpen,
  handleOk,
  handleCancel,
}) => {
  const [api, contextHolder] = notification.useNotification();

  const { TextArea } = Input;
  const [loading, setLoading] = useState(false);

  type NotificationType = "success" | "info" | "warning" | "error";

  const openNotificationWithIcon = (type: NotificationType) => {
    api[type]({
      message: "Welcome Aboard!",
      description:
        "Thank you for contacting Cruise Master! We will get back to you soon.",
    });
  };

  const openNotificationWithIconError = (type: NotificationType) => {
    api[type]({
      message: "Something went wrong!",
      description: "Something went wrong!",
    });
  };

  const validateMessages = {
    required: "This field is required!",
    types: {
      email: "Invalid Email!",
    },
  };
  /* eslint-enable no-template-curly-in-string */

  const [form] = Form.useForm();

  const onFinish = async () => {
    try {
      setLoading(true);
      const formValues = form.getFieldsValue();
      formValues["cruise_line"] = cruiseLine?.title;
      const formElement = document.createElement("form");
      Object.entries(formValues).forEach(([key, value]) => {
        const input = document.createElement("input");
        input.name = key;
        input.value = String(value);
        formElement.appendChild(input);
      });
      await emailjs.sendForm(
        "service_neoekk8",
        "template_whk65vn",
        formElement,
        "9u1FcKZAMVif5drQG"
      );
      setLoading(false);
      openNotificationWithIcon("success");
      form.resetFields();
      handleCancel();
    } catch (error) {
      setLoading(true);
      console.error(error);
      openNotificationWithIconError("error");
      form.resetFields();
      handleCancel();
    }
  };

  return (
    <div className="offlineCruiseModal">
      {contextHolder}
      <Modal
        title={null}
        width={800}
        centered
        open={isModalOpen}
        footer={null}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Alert
          message={
            cruiseLine?.title +
            " is not available for online booking. Kindly share your details with us, and we will be glad to assist you with the booking process."
          }
          type="info"
          style={{ marginTop: "32px", marginBottom: "32px" }}
          className="font-regular basic-texts"
        />
        <p className="font-regular basic-texts"></p>
        <Form
          name="contactUsForm"
          onFinish={onFinish}
          layout="vertical"
          form={form}
          validateMessages={validateMessages}
        >
          <Row>
            <Col sm={12} md={12} lg={6} className="mb-8">
              <label style={{ fontWeight: 500, color: "#292929" }}>Name</label>
              <Form.Item
                name="user_name"
                rules={[{ required: true, message: "Please enter your name" }]}
              >
                <Input
                  placeholder="Name"
                  className="bold-500 offline-input"
                  style={{
                    borderColor: "white",
                    outline: "none",
                    boxShadow: "none",
                    borderRadius: 6,
                    padding: 12,
                  }}
                />
              </Form.Item>
            </Col>
            <Col sm={12} md={12} lg={6} className="mb-8">
              <label style={{ fontWeight: 500, color: "#292929" }}>Email</label>
              <Form.Item
                name="user_email"
                rules={[
                  {
                    required: true,
                    message: "Please enter your email address",
                  },
                  {
                    type: "email",
                    message: "Please enter a valid email address",
                  },
                ]}
              >
                <Input
                  placeholder="Email"
                  className="bold-500 offline-input"
                  style={{
                    borderColor: "white",
                    outline: "none",
                    boxShadow: "none",
                    borderRadius: 6,
                    padding: 12,
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={12} lg={6} className="mb-8">
              <label style={{ fontWeight: 500, color: "#292929" }}>Phone</label>
              <Form.Item name="phone">
                <Input
                  placeholder="Phone"
                  className="bold-500 offline-input"
                  style={{
                    borderColor: "white",
                    outline: "none",
                    boxShadow: "none",
                    borderRadius: 6,
                    padding: 12,
                  }}
                />
              </Form.Item>
            </Col>
            <Col sm={12} md={12} lg={6} className="mb-8">
              <label style={{ fontWeight: 500, color: "#292929" }}>
                Organization
              </label>
              <Form.Item name="organization">
                <Input
                  placeholder="Organization"
                  className="bold-500 offline-input"
                  style={{
                    borderColor: "white",
                    outline: "none",
                    boxShadow: "none",
                    borderRadius: 6,
                    padding: 12,
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <label style={{ fontWeight: 500, color: "#292929" }}>Message</label>
            <Form.Item
              name="message"
              rules={[{ required: true, message: "Please enter your message" }]}
            >
              <TextArea
                rows={4}
                name="message"
                placeholder="Message"
                className="bold-500 offline-input"
                style={{
                  borderColor: "white",
                  outline: "none",
                  boxShadow: "none",
                  borderRadius: 6,
                  padding: 12,
                }}
              />
            </Form.Item>
          </Row>
          <Row>
            <Col>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ marginRight: "8px" }}
                >
                  Submit
                  {loading ? (
                    <LoadingOutlined
                      style={{ fontSize: 18, marginBottom: "5px" }}
                      spin
                    />
                  ) : null}
                </Button>
                <Button key="back" onClick={handleCancel}>
                  Cancel
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};
