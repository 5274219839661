import { useState } from "react";
import "./styles.scss";
import { Container, Row, Col } from "react-bootstrap";
import { Button, Divider, Form } from "antd";
import { notification } from "antd";
import { Helmet } from "react-helmet";
import emailjs from "@emailjs/browser";
import { IoSend } from "react-icons/io5";
import { LoadingOutlined } from "@ant-design/icons";
import { MdOutlineAlternateEmail } from "react-icons/md";
import { HiOutlinePhone } from "react-icons/hi";
import { MdOutlineLocationOn } from "react-icons/md";
import { Input } from "antd";
import Banner from "../../Components/Banner";
import { FaWhatsapp } from "react-icons/fa";

type NotificationType = "success" | "info" | "warning" | "error";

function Contact() {
  const [api, contextHolder] = notification.useNotification();
  const [loading, setLoading] = useState(false);
  const { TextArea } = Input;

  const openNotificationWithIcon = (type: NotificationType) => {
    api[type]({
      message: "Welcome Aboard!",
      description:
        "Thank you for contacting Cruise Master! We will get back to you soon.",
    });
  };

  const openNotificationWithIconError = (type: NotificationType) => {
    api[type]({
      message: "Something went wrong!",
      description: "Something went wrong!",
    });
  };

  const validateMessages = {
    required: "This field is required!",
    types: {
      email: "Invalid Email!",
    },
  };
  /* eslint-enable no-template-curly-in-string */

  const [form] = Form.useForm();

  const onFinish = async () => {
    try {
      setLoading(true);
      const formValues = form.getFieldsValue();

      const formElement = document.createElement("form");
      Object.entries(formValues).forEach(([key, value]) => {
        const input = document.createElement("input");
        input.name = key;
        input.value = String(value);
        formElement.appendChild(input);
      });

      await emailjs.sendForm(
        "service_neoekk8",
        "template_whk65vn",
        formElement,
        "9u1FcKZAMVif5drQG"
      );
      setLoading(false);
      openNotificationWithIcon("success");
      form.resetFields();
    } catch (error) {
      setLoading(true);
      console.error(error);
      openNotificationWithIconError("error");
      form.resetFields();
    }
  };

  const handleButtonClick = () => {
    const officeAddress = "Cruise Master LLC, Oud Metha, Dubai,UAE";

    const encodedAddress = encodeURIComponent(officeAddress);

    const mapsUrl = `https://www.google.com/maps?q=${encodedAddress}`;

    window.open(mapsUrl, "_blank");
  };

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "address": {
      "@type": "PostalAddress",
      "addressLocality": "Dubai",
      "streetAddress": "Suite 603, Office Court Bldg - Oud Metha - Dubai",
    },
    "email": "res@cruisemaster-me.com",
    "member": [
      {
        "@type": "Organization",
      },
    ],
    "name": "Cruise Master LLC",
    "telephone": "+971 4 299 9223",
  };

  return (
    <div className="contact column-padding-b">
      <Helmet>
        <title>
          Contact Cruise Master - Leading Cruise Booking Agency, Middle East
        </title>
        <meta
          name="description"
          content="Contact Cruise Master - Your trusted sales and booking agent in the Middle East. Reach out for inquiries, bookings, and assistance with planning your cruise."
        />
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
        <link
          rel="canonical"
          href="https://www.cruisemaster-me.com/contact_us"
        />
        <meta
          property="og:title"
          content="Contact Cruise Master - Sales and Booking Agent in the Middle East"
        />
        <meta
          property="og:image"
          content="https://www.cruisemaster-me.com/favicon.png"
        />
        <meta
          property="og:description"
          content="Contact Cruise Master - Your trusted sales and booking agent in the Middle East. Reach out for inquiries, bookings, and assistance with planning your cruise."
        />
        <meta
          property="og:url"
          content="https://www.cruisemaster-me.com/contact_us"
        />
        <meta property="og:type" content="website" />
      </Helmet>
      <Banner link="Contact Us" link2=""/>
      {contextHolder}
      <Container>
        <Row>
          <Col
            sm={12}
            md={12}
            lg={6}
            className="responsive-column-padding general-shadow"
          >
            <div className="contact-left-col full-height">
              <a
                className="contact-info-card flex pointer"
                href="tel:+97142999223"
                style={{ textDecoration: "none" }}
              >
                <Row className="full-width">
                  <Col xs={2}>
                    {" "}
                    <div className="contact-icon-circle flex center">
                      <HiOutlinePhone className="contact-icon" />
                    </div>
                  </Col>
                  <Col xs={10}>
                    <div className="flex-column">
                      <div className="font-regular bold-600 contact-info-heading font-black mb-8">
                        Phone
                      </div>
                      <div className="number-font font-para-black basic-texts">
                        +971 4 299 9223
                      </div>
                    </div>
                  </Col>
                </Row>
              </a>
              <a
                className="contact-info-card flex pointer"
                href="https://wa.me/+971502784737"
                style={{ textDecoration: "none" }}
                target="_blank"
                rel="noreferrer"
              >
                <Row className="full-width">
                  <Col xs={2}>
                    {" "}
                    <div className="contact-icon-circle flex center">
                      <FaWhatsapp className="contact-icon" />
                    </div>
                  </Col>
                  <Col xs={10}>
                    <div className="flex-column">
                      <div className="font-regular bold-600 contact-info-heading font-black mb-8">
                        Whatsapp
                      </div>
                      <div className="number-font font-para-black basic-texts">
                        +971 50 CRUISES
                      </div>
                    </div>
                  </Col>
                </Row>
              </a>
              <a
                href="mailto:res@cruisemaster-me.com"
                target="_blank"
                rel="noreferrer"
                className="contact-info-card flex pointer"
                style={{ textDecoration: "none" }}
              >
                <Row className="full-width">
                  <Col xs={2}>
                    <div className="contact-icon-circle flex center">
                      <MdOutlineAlternateEmail className="contact-icon" />
                    </div>
                  </Col>
                  <Col xs={10}>
                    <div className="flex-column">
                      <div className="font-regular bold-600 contact-info-heading font-black mb-8">
                        Email
                      </div>
                      <div className="font-regular font-para-black basic-texts">
                        res@cruisemaster-me.com
                      </div>
                    </div>
                  </Col>
                </Row>
              </a>

              <div
                className="contact-info-card flex pointer"
                onClick={handleButtonClick}
              >
                <Row className="full-width">
                  <Col xs={2}>
                    <div className="contact-icon-circle flex center">
                      <MdOutlineLocationOn className="contact-icon" />
                    </div>
                  </Col>
                  <Col xs={10}>
                    <div className="flex-column">
                      <h3 className="font-regular bold-600 contact-info-heading">
                        Address
                      </h3>
                      <div className="font-regular font-para-black basic-texts">
                        Suite 603, Office Court Bldg - Oud Metha - Dubai
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
              <Divider className="contact-divider" />
              <iframe
                className="contact-iframe general-shadow"
                title="Google Map"
                loading="lazy"
                allowFullScreen
                src="https://maps.google.com/\?width=100%&amp;hl=en&amp;coord=25.244982542140523,55.315187867138164
                      &amp;q=Cruise%20Master%20LLC%2C%20Oud Metha%2C%20Dubai&amp;ie=UTF8&amp;
                      t=&amp;z=19&amp;iwloc=B&amp;output=embed"
              ></iframe>
            </div>
          </Col>
          <Col
            sm={12}
            md={12}
            lg={6}
            className="responsive-column-padding general-shadow"
          >
            <div className="contact-form-card full-height">
              <div className="contact-primary-card">
                <h5 className="small-contact-header font-regular">
                  Get In Touch
                </h5>
                <h2 className="large-contact-header font-regular">
                  Let's Chat, Reach Out to Us
                </h2>
                <h6 className="contact-para font-regular">
                  Have questions or feedback? We're here to help. Send us a
                  message, and we'll respond within 24 hours
                </h6>
              </div>
              {/* <h3 className="bold-600 mb-32 description-text">
                Send Us A Message
              </h3> */}
              <Form
                name="contactUsForm"
                onFinish={onFinish}
                layout="vertical"
                form={form}
                validateMessages={validateMessages}
              >
                <Row>
                  <Col sm={12} md={12} lg={6} className="mb-8">
                    <Form.Item
                      name="user_name"
                      rules={[
                        { required: true, message: "Please enter your name" },
                      ]}
                    >
                      <Input
                        placeholder="Name (Required)"
                        className="bold-500 contact-input"
                        style={{
                          borderColor: "white",
                          outline: "none",
                          boxShadow: "none",
                          borderRadius: 6,
                          padding: 12,
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col sm={12} md={12} lg={6} className="mb-8">
                    <Form.Item
                      name="user_email"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your email address",
                        },
                        {
                          type: "email",
                          message: "Please enter a valid email address",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Email (Required)"
                        className="bold-500 contact-input"
                        style={{
                          borderColor: "white",
                          outline: "none",
                          boxShadow: "none",
                          borderRadius: 6,
                          padding: 12,
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} md={12} lg={6} className="mb-8">
                    <Form.Item name="phone">
                      <Input
                        placeholder="Phone"
                        className="bold-500 contact-input"
                        style={{
                          borderColor: "white",
                          outline: "none",
                          boxShadow: "none",
                          borderRadius: 6,
                          padding: 12,
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col sm={12} md={12} lg={6} className="mb-8">
                    <Form.Item name="subject">
                      <Input
                        placeholder="Subject"
                        className="bold-500 contact-input"
                        style={{
                          borderColor: "white",
                          outline: "none",
                          boxShadow: "none",
                          borderRadius: 6,
                          padding: 12,
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Form.Item
                    name="message"
                    rules={[
                      { required: true, message: "Please enter your message" },
                    ]}
                  >
                    <TextArea
                      rows={4}
                      name="message"
                      placeholder="Message (Required)"
                      className="bold-500 contact-input"
                      style={{
                        borderColor: "white",
                        outline: "none",
                        boxShadow: "none",
                        borderRadius: 6,
                        padding: 12,
                      }}
                    />
                  </Form.Item>
                </Row>
                <Row>
                  <Form.Item className="flex end mt-32">
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="card-button btn-small basic-texts"
                    >
                      Send
                      {loading ? (
                        <LoadingOutlined
                          style={{ fontSize: 18, marginBottom: "5px" }}
                          spin
                        />
                      ) : (
                        <IoSend
                          style={{ marginLeft: "10px", marginBottom: "3px" }}
                        />
                      )}
                    </Button>
                  </Form.Item>
                </Row>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Contact;
