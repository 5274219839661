import "./styles.scss";
import { MdOutlineArrowRight } from "react-icons/md";
import { IoMdHome } from "react-icons/io";
import { useNavigate } from "react-router-dom";

interface CruiseLineProps {
  CruiseLine: { logo: string; title: string };
}

export const CruiseLineHeader: React.FC<CruiseLineProps> = ({ CruiseLine }) => {
  const navigate = useNavigate();

  return (
    <div className="flex center flex-column">
      <img
        src={CruiseLine?.logo}
        style={{ backgroundSize: "contain" }}
        className="cruiseimg mb-16"
        alt="cruise"
      />
      <div className="font-faded-black font-regular font-14 bold-500 opacity80 mb-32">
        <span
          className="pointer home-link"
          onClick={() => {
            setTimeout(() => {
              window.scrollTo(0, 0);
            }, 100);
            navigate("/");
          }}
        >
          <IoMdHome
            className="basic-texts"
            style={{ margin: "0px 4px 5px 0px" }}
          />
          Home
        </span>
        <MdOutlineArrowRight className="basic-texts" />{" "}
        <span
          className="pointer home-link"
          onClick={() => {
            setTimeout(() => {
              window.scrollTo(0, 0);
            }, 100);
            navigate("/cruise-lines");
          }}
        >
          Our Partner Cruise Lines
        </span>
        <MdOutlineArrowRight className="basic-texts" /> {CruiseLine?.title}
      </div>
    </div>
  );
};
