import "./styles.scss";
import { Container } from "react-bootstrap";
import { Collapse } from "antd";
import Banner from "../../Components/Banner";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { FaqItemsParent } from "./FaqItemsParent";
import { useApp } from "../../util/AppProvider";

function Faq() {
  const [activeKey, setActiveKey] = useState([]);
  const { setFaqChildActiveKeys } = useApp();

  const onChange = (keys: any) => {
    setActiveKey(keys);
    setFaqChildActiveKeys([]);
    setTimeout(() => {
      scrollToElement(JSON.stringify(Number(keys[0]) - 1));
    }, 500);
  };

  const scrollToElement = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });

      const scrolledY = window.scrollY;
      const elementTop = element.getBoundingClientRect().top + scrolledY;
      const finalScrollY = elementTop - 100;

      window.scrollTo({
        top: finalScrollY,
        behavior: "smooth",
      });
    }
  };

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: [
      {
        "@type": "Question",
        name: "What documents do I need to bring for the cruise?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "<p>You will require a valid passport with necessary visa for destinations in your cruise itinerary. Requirements may vary for different nationality and we recommend you should contact the nearest representative embassies or consulates for proper information and guidance. It is YOUR to provide failing which you may be denied boarding and 100% penalty will be applied by the cruise line. Cruise Master Middle East will not be held responsible for any passengers who are denied boarding due to lack of proper documents.</p>",
        },
      },
      {
        "@type": "Question",
        name: "What are my luggage allowances?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "There is no limit on how much luggage can be taken on the ship, though careful consideration should be given to cabin space available for storing. All luggage has to be kept in guests cabin(s).",
        },
      },
    ],
  };

  return (
    <div className="faq column-padding-b">
      <Helmet>
        <title>Frequently Asked Questions</title>
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
        <meta
          name="description"
          content="Our FAQ section provides essential information about our cruise options, onboard features, and travel policies"
        />
        <meta property="og:title" content="Frequently Asked Questions" />
        <meta
          property="og:description"
          content="Our FAQ section provides essential information about our cruise options, onboard features, and travel policies"
        />
      </Helmet>
      <Banner link="FAQ" link2="FAQ" />
      <Container>
        <div className="flex flex-column">
          <Collapse
            accordion
            ghost
            expandIconPosition="end"
            activeKey={activeKey}
            onChange={onChange}
            className="faq-parent-panel"
          >
            {FaqItemsParent?.map((item: any, index: number) => {
              return (
                <>
                  <Collapse.Panel
                    header={item.label}
                    key={index + 1}
                    className="faq-panel"
                    id={JSON.stringify(index + 1)}
                  >
                    {item.children}
                  </Collapse.Panel>
                  <div>
                    {FaqItemsParent && FaqItemsParent?.length - 1 !== index && (
                      <div
                        style={{
                          marginBottom: 0,
                          marginTop: 0,
                          backgroundColor:"white",
                          height:"6px"
                        }}
                      />
                    )}
                  </div>
                </>
              );
            })}
          </Collapse>
        </div>
      </Container>
    </div>
  );
}

export default Faq;
