import azamara from "../../assets/logos/azamara.png";
import seadream from "../../assets/logos/seadream.png";
import celestyal from "../../assets/logos/celestyal.png";
import hurtigruten from "../../assets/logos/hurtigruten.png";
import ponant from "../../assets/logos/ponant-logo.png";
import paul from "../../assets/logos/paul.png";
import starclippers from "../../assets/logos/starclippers.png";
import nicko from "../../assets/logos/nicko.png";
import variety from "../../assets/logos/variety.jpg";
import havila from "../../assets/logos/havila.jpg";
import { CDN_BASE_URL } from "../../Config/apiConfig";

export const nicheCruiseLines = [
  {
    title: "Celestyal Cruises",
    logo: celestyal,
    online: true,
    resUrl:
      "https://res.cruisemaster-me.com/swift/cruise/cruiseline/celestyal-cruises?siid=177652&cruiseline=8224",
    url: "/other_cruise_lines/celestyal_cruises",
    cruiseid: "8224",
    urlName: "celestyal_cruises",
    banner: `${CDN_BASE_URL}/img/ships/Celestyal/CelestyalCruises.jpg`,
    overview:
      "Celestyal Cruises is the main home-porting cruise operator in Greece and the preeminent cruise line serving the Greek Islands and East Mediterranean. The company operates two mid-sized vessels, each one cosy enough to provide genuine and highly-personalized services. The foundation of the company’s philosophy is the ‘destination.’ Every cruise focuses on true cultural immersion, offering authentic, lifetime experiences both on board and on shore wherever its vessels sail. Our mission is to offer our guests an amazing personalized experience where they will live, feel and taste the best each region has to offer through a unique journey of authentic moments! With our medium-size, cozy vessels, we can take you to all the famous destinations in the Greek islands and Eastern Mediterranean. Join us and we promise you an unforgettable journey\n\n",

    aboutships:
      "Our ships are medium sized which means they can visit ports that larger vessels cannot access, allowing us to offer unique smaller and almost undiscovered destinations in our itineraries. Onboard, our guests get to enjoy a more cosy, homely atmosphere and a less crowded affair and therefore the highest standards of customer care, comfort and personalized service.",
    brochures: [],
    cruises: [
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/celestyal-cruises-celestyal-journey?ship=15259",
        shipname: "Celestyal Journey",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/celestyal-cruises-celestyal-discovery?ship=15337",
        shipname: "Celestyal Discovery",
      },
    ],
  },
  // {
  //   title: "Aroya Cruises",
  //   logo: aroya,
  //   online: false,
  //   resUrl: "",
  //   url: "/other_cruise_lines/aroya",
  //   cruiseid: "",
  //   urlName: "aroya",
  //   banner: `${CDN_BASE_URL}/img/ships/Aroya/aroya.jpg`,
  //   overview:
  //     "Specially designed to meet your needs, our remarkable floating resort includes various facilities on 18 decks, with a dedicated deck to water games, and a unique selection of international cuisines and exceptional dining options.",

  //   aboutships:
  //     "Specially designed to meet your needs, our remarkable floating resort includes various facilities on 18 decks, with a dedicated deck to water games, and a unique selection of international cuisines and exceptional dining options.",
  //   brochures: [],
  //   cruises: [
  //     {
  //       resShipUrl: "",
  //       shipname: "Aroya Ship",
  //     },
  //   ],
  // },
  {
    title: "Azamara",
    logo: azamara,
    online: true,
    resUrl:
      "https://res.cruisemaster-me.com/swift/cruise/cruiseline/azamara-?siid=177652&cruiseline=1043",
    url: "/other_cruise_lines/azamara",
    cruiseid: "1043",
    urlName: "azamara",
    banner: `${CDN_BASE_URL}/img/ships/Azamara/AZA_ML.jpg`,
    overview:
      "Welcome. Here at Azamara, we continue our efforts to be the world's top up-market cruise line. As such, we are committed to providing you with unparalleled service; the finest dining at sea; a glorious spa and wellness retreat, and innovations such as Destination Immersion® experiences. With our hallmark of stay longer, experience more, we'll not only take you to awe-inspiring destinations, but also give you more time to fall in love with them.",

    aboutships:
      "The Azamara experience doesn’t pause when you depart a destination, it continues onboard with our top-tier crew, premiere service and fleet of boutique ships. Azamara ships are not too big, and they’re not too small, so you won’t run out of new things to do and new people to meet. Plus, with the newest addition to our fleet—the Azamara Onward℠ — you have even more choices in immersive itineraries that call on the world’s most unparalleled destinations. Our smaller ships Azamara Journey®, Azamara Quest®, Azamara Pursuit®, and our newest addition Azamara Onward℠, are perfectly sized to cross oceans, sail into intimate rivers, cruise along scenic waterways, and dock at locations bigger ships can’t reach. From the doorsteps of Bangkok, to the city center of Bordeaux, the heart of Seville, and within a short walk to Hong Kong’s buzzing nightlife.",
    brochures: [],
    cruises: [
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/azamara--azamara-journey?ship=1187",
        shipname: "Azamara Journey",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/azamara--azamara-onward?ship=15071",
        shipname: "Azamara Onward",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/azamara--azamara-pursuit?ship=14038",
        shipname: "Azamara Pursuit",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/azamara--azamara-quest?ship=1188",
        shipname: "Azamara Quest",
      },
    ],
  },
  {
    title: "Havila Voyages",
    logo: havila,
    online: false,
    resUrl: "",
    url: "/other_cruise_lines/havila_voyages",
    cruiseid: "0",
    urlName: "havila_voyages",
    banner: `${CDN_BASE_URL}/img/ships/Havila/havila.png`,
    overview:
      "Experience the power of nature; the majestic mountains, the vivid fjords, the stunning Northern lights dancing across the night sky, or the mesmerizing midnight sun, all while enjoying the modern comfort of a Havila Voyages coastal cruise ship.",

    aboutships:
      "Experience the power of nature; the majestic mountains, the vivid fjords, the stunning Northern lights dancing across the night sky, or the mesmerizing midnight sun, all while enjoying the modern comfort of a Havila Voyages coastal cruise ship.",
    brochures: [],
    cruises: [
      {
        resShipUrl: "",
        shipname: "Havila Capella",
      },
      {
        resShipUrl: "",
        shipname: "Havila Castor",
      },
      {
        resShipUrl: "",
        shipname: "Havila Polaris",
      },
      {
        resShipUrl: "",
        shipname: "Havila Pollux",
      },
    ],
  },
  {
    title: "Hurtigruten",
    logo: hurtigruten,
    online: false,
    resUrl: "",
    url: "/other_cruise_lines/hurtigruten",
    cruiseid: "0",
    urlName: "hurtigruten",
    banner: `${CDN_BASE_URL}/img/ships/Hurtigruten/Hurtigruten_ML.jpg`,
    overview:
      "Hurtigruten Group is the world’s leading adventure travel group, offering unique small-ship and land-based adventures from pole to pole – and anywhere in between. We take our guests to the most spectacular areas on the planet, offering a mix of pristine wilderness and visits to genuine local communities. Hurtigruten Group is revolutionising the travel industry’s approach to sustainability, allowing us to offer authentic local experiences while leaving a footprint we are proud of — both in nature and with the communities we visit.",

    aboutships:
      "Since 1893, our ships have been a hallmark of the Norwegian coast & beyond. On all of the ships in today’s fleet you will find modern facilities carefully blended with true character.",
    brochures: [],
    cruises: [
      {
        resShipUrl: "",
        shipname: "MS Trollfjord",
      },
      {
        resShipUrl: "",
        shipname: "MS Kong Harald",
      },
      {
        resShipUrl: "",
        shipname: "MS Midnatsol",
      },
      {
        resShipUrl: "",
        shipname: "MS Nordkapp",
      },
      {
        resShipUrl: "",
        shipname: "MS Nordlys",
      },
      {
        resShipUrl: "",
        shipname: "MS Nordnorge",
      },
      {
        resShipUrl: "",
        shipname: "MS Nordstjernen",
      },
      {
        resShipUrl: "",
        shipname: "MS Otto Sverdrup",
      },
      {
        resShipUrl: "",
        shipname: "MS Polarys",
      },
      {
        resShipUrl: "",
        shipname: "MS Richard With",
      },
      {
        resShipUrl: "",
        shipname: "MS Vesterålen",
      },
    ],
  },
  {
    title: "Nicko Cruises",
    logo: nicko,
    online: false,
    resUrl: "",
    url: "/other_cruise_lines/nicko_cruises",
    cruiseid: "0",
    urlName: "nicko_cruises",
    banner: `${CDN_BASE_URL}/img/ships/Nicko/Nicko.jpg`,
    overview:
      "The nicko cruises fleet is as diverse as our travel program with our 20 river ships and our classic ocean VASCO DA GAMA. Basically, all nicko cruises ships correspond to an upscale standard, which is almost everywhere comparable to a 4- to 5-star hotel on land. However, there are gradual differences depending on ship, cruising area and technical requirements. For better orientation, we have therefore given each of our ships our own nicko classification, which ranges from three nicko waves to five nicko waves.",

    aboutships:
      "The nicko cruises fleet is as diverse as our travel program with our 20 river ships and our classic ocean VASCO DA GAMA. Basically, all nicko cruises ships correspond to an upscale standard, which is almost everywhere comparable to a 4- to 5-star hotel on land. However, there are gradual differences depending on ship, cruising area and technical requirements. For better orientation, we have therefore given each of our ships our own nicko classification, which ranges from three nicko waves to five nicko waves.",
    brochures: [],
    cruises: [
      {
        resShipUrl: "",
        shipname: "nickoVISION",
      },
      {
        resShipUrl: "",
        shipname: "Maxima",
      },
      {
        resShipUrl: "",
        shipname: "nickoSPIRIT",
      },
      {
        resShipUrl: "",
        shipname: "Viktoria",
      },
      {
        resShipUrl: "",
        shipname: "Belvedere",
      },
      {
        resShipUrl: "",
        shipname: "Bolero",
      },
      {
        resShipUrl: "",
        shipname: "Rhein Symphonie",
      },
      {
        resShipUrl: "",
        shipname: "Belvedere",
      },
      {
        resShipUrl: "",
        shipname: "Rhein Melodie",
      },
      {
        resShipUrl: "",
        shipname: "Douro Queen",
      },
      {
        resShipUrl: "",
        shipname: "Bijou Du Rhône",
      },
      {
        resShipUrl: "",
        shipname: "Seine Comtesse",
      },
      {
        resShipUrl: "",
        shipname: "Katharina Von Bora",
      },
      {
        resShipUrl: "",
        shipname: "Princess",
      },
      {
        resShipUrl: "",
        shipname: "Mekong Navigator",
      },
      {
        resShipUrl: "",
        shipname: "Steigenberger Legacy",
      },
      {
        resShipUrl: "",
        shipname: "Steig. Omar El Khayam",
      },
      {
        resShipUrl: "",
        shipname: "Steigenberger Minerva",
      },
      {
        resShipUrl: "",
        shipname: "Nile Monarch",
      },
      {
        resShipUrl: "",
        shipname: "Vasco Da Gama",
      },
    ],
  },
  {
    title: "Paul Gauguin Cruises",
    logo: paul,
    online: false,
    resUrl: "",
    url: "/other_cruise_lines/paul_gauguin",
    cruiseid: "0",
    urlName: "paul_gauguin",
    banner: `${CDN_BASE_URL}/img/ships/Paul/PaulGauguin.jpg`,
    overview:
      "Paul Gauguin Cruises operates the award-winning luxury cruise ship the m/s Paul Gauguin, which provides an exceptional cruise experience tailored to the unparalleled wonders of Tahiti, the South Pacific, Fiji and the South Pacific. In September 2019, Paul Gauguin Cruises joined PONANT's family, the world leader of small-ship cruising. ",

    aboutships:
      "The renowned, 5+-star m/s Paul Gauguin was built specifically to sail the waters of Tahiti, French Polynesia, and the South Pacific, and is the longest continually operating luxury cruise ship in this enchanting destination. No other luxury ship in history has offered this level of single-destination focus and expertise on a year-round basis for such an extended period of time.",
    brochures: [],
    cruises: [
      {
        resShipUrl: "",
        shipname: "MS Paul Gauguin",
      },
    ],
  },
  {
    title: "Ponant",
    logo: ponant,
    online: false,
    resUrl: "",
    url: "/other_cruise_lines/ponant",
    cruiseid: "0",
    urlName: "ponant",
    banner: `${CDN_BASE_URL}/img/ships/Ponant/Ponant.jpg`,
    overview:
      "Refinement, curiosity, authenticity, our unique travel philosophy reinterprets cruising and invites you to discover or rediscover iconic destinations. Our 5-Star Culture and Relaxation cruises offer you a new way to travel, by combining cultural enrichment with time to unwind in a luxury setting and sites that only insiders know about. Classical music and opera, food and wine, art and museums, theatre and literature, photography, golf... take advantage of our theme cruises to combine your taste for travel with your hobbies. You will have unique opportunities on board and on land, with moments of true cultural enrichment and exchanges with artists and passionate specialists.",

    aboutships:
      "Life on board our ships is an intrinsic part of the PONANT cruise experience. Whether you choose a Mediterranean cruise or an expedition journey to the heart of icy Antarctica, you can be sure of exceptional comfort on board our ships. Our bespoke, attentive, excellent service will make you feel like you're travelling on a private yacht.",
    brochures: [],
    cruises: [
      {
        resShipUrl: "",
        shipname: "Le Ponant",
      },
      {
        resShipUrl: "",
        shipname: "Le Austral",
      },
      {
        resShipUrl: "",
        shipname: "Le Boreal",
      },
      {
        resShipUrl: "",
        shipname: "Le Lyrial",
      },
      {
        resShipUrl: "",
        shipname: "Le Soleal",
      },
      {
        resShipUrl: "",
        shipname: "Le Laperouse",
      },
      {
        resShipUrl: "",
        shipname: "Le Champlain",
      },
      {
        resShipUrl: "",
        shipname: "Le Bougainville",
      },
      {
        resShipUrl: "",
        shipname: "Le Dumont-D'urville",
      },
      {
        resShipUrl: "",
        shipname: "Le Bellot",
      },
      {
        resShipUrl: "",
        shipname: "Le Jacques Cartier",
      },
      {
        resShipUrl: "",
        shipname: "Le Commandant Charcot",
      },
      {
        resShipUrl: "",
        shipname: "Le Paul Gauguin",
      },
    ],
  },
  {
    title: "SeaDream Yacht Club",
    logo: seadream,
    online: true,
    resUrl:
      "https://res.cruisemaster-me.com/swift/cruise/cruiseline/sea-dream-yacht-club?siid=177652&cruiseline=8126",
    url: "/other_cruise_lines/sea_dream_yacht",
    cruiseid: "8126",
    urlName: "sea_dream_yacht",
    banner: `${CDN_BASE_URL}/img/ships/Seadream/SeaDreamYachtClub.jpg`,
    overview:
      "SeaDream Yacht Club is a family-owned company. SeaDream’s sole Founder, Owner & Chairman, Atle Brynestad, founded Seabourn Cruise Line in 1987 and was its Chairman and CEO during its initial years. He has also served as Chairman of Cunard Line Ltd. and as a board member of Carnival Cruise Lines. Established in 2001, SeaDream Yacht Club launched with twin mega-yachts, SeaDream I and SeaDream II, and has achieved the industry’s highest accolades. “It’s Yachting, Not Cruising” defines SeaDream’s mission: to provide a casual yachting experience that exceeds guests’ expectations as they explore the world’s most amazing destinations. ",

    aboutships:
      "Named “Best Small Luxury Cruise Ship of 2015” in ForbesLife, twin mega-yachts SeaDream I & II are intimate boutique ships that provide a unique travelling experience unlike any other cruise vacation. The expression “yachting” is not only a statement about size; it’s a lifestyle aboard our intimate vessels with the service of 95 crew members and 112 guests that make each guest feel like they are on their very own private luxury yacht. Chic and stylish, SeaDream’s 56-stateroom yachts are favored for its elegant informality, highly personal service, inclusive open bar and gratuities, all ocean views staterooms and suites, luxury accommodations and world-class cuisine.",
    brochures: [],
    cruises: [
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/sea-dream-yacht-club-seadream-i?ship=13342",
        shipname: "SeaDream 1",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/sea-dream-yacht-club-seadream-ii?ship=13343",
        shipname: "SeaDream 2",
      },
    ],
  },
  {
    title: "Star Clippers",
    logo: starclippers,
    online: false,
    resUrl: "",
    url: "/other_cruise_lines/star_clippers",
    cruiseid: "0",
    urlName: "star_clippers",
    banner: `${CDN_BASE_URL}/img/ships/Star Clipper/StarClipper_ML.jpg`,
    overview:
      "Since 1991 Star Clippers has offered sophisticated travellers the ultimate cruising vacation alternative – a tall ship “mega-yacht” experience aboard authentic re-creations of the classic sailing clipper ships that ruled the waves during the 19th century.",
    aboutships:
      "The brainchild of Swedish entrepreneur, Mikael Krafft, Star Clippers operates three of the largest barquentine and full-rigged sailing ships in the world. Star Clipper, Star Flyer and flagship, Royal Clipper are a triumvirate of four and five-masted sailing ships that offer the activities, amenities and atmosphere of a private yacht. With Star Clippers, Mikael Krafft has realized his lifelong dream to provide a unique sailing experience for his passengers in full harmony with the environment, the sea and often away from ports congested with tourists.",
    brochures: [],
    cruises: [
      {
        resShipUrl: "",
        shipname: "Royal Clipper",
      },
      {
        resShipUrl: "",
        shipname: "Star Clipper",
      },
      {
        resShipUrl: "",
        shipname: "Star Flyer",
      },
    ],
  },
  {
    title: "Variety Cruises",
    logo: variety,
    online: false,
    resUrl: "",
    url: "/other_cruise_lines/variety_cruises",
    cruiseid: "0",
    urlName: "variety_cruises",
    banner: `${CDN_BASE_URL}/img/ships/Variety/Pegasos.jpg`,
    overview:
      "Variety Cruises is the number one fleet of small ships hosting no more than 71 guests. Designed and handcrafted from inception to sailing by our experienced family of ship-builders with a history spanning over 50 years, with you in mind, Variety Cruises has established itself as the industry expert of yacht cruise experiences.",
    aboutships:
      "Variety Cruises is the number one fleet of small ships hosting no more than 71 guests. Designed and handcrafted from inception to sailing by our experienced family of ship-builders with a history spanning over 50 years, with you in mind, Variety Cruises has established itself as the industry expert of yacht cruise experiences.",
    brochures: [],
    cruises: [
      {
        resShipUrl: "",
        shipname: "Variety Voyager",
      },
      {
        resShipUrl: "",
        shipname: "Harmony V",
      },
      {
        resShipUrl: "",
        shipname: "Harmony G",
      },
      {
        resShipUrl: "",
        shipname: "Galileo",
      },
      {
        resShipUrl: "",
        shipname: "Panorama",
      },
      {
        resShipUrl: "",
        shipname: "Panorama II",
      },
      {
        resShipUrl: "",
        shipname: "Pegasos",
      },
      {
        resShipUrl: "",
        shipname: "Callisto",
      },
    ],
  },
];
