import "./App.scss";
import { Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { FloatButton } from "antd";
import HeaderComponent from "./Components/Header";
import HomeScreen from "./Screens/Home";
import Footer from "./Components/Footer";
import NewsLetter from "./Components/NewsLetter";
import CruiseLine from "./Screens/CruiseLine";
import DestinationsScreen from "./Screens/Destinations";
import Contact from "./Screens/Contact";
import AllCruiseLines from "./Screens/AllCruiseLines";
import PrivacyPolicy from "./Screens/PrivacyPolicy";
import HowtoBook from "./Screens/HowToBook";
import FaqScreen from "./Screens/Faq";
import AgentPortal from "./Screens/Agent";
import useInactivityTimeout from "./Config/InactivityTimer";
import DeparturePorts from "./Screens/Ports";
import WhyCruise from "./Screens/WhyCruise";
import NotFound from "./Screens/404";

const App = () => {
  useInactivityTimeout();
  return (
    <div className="layout-container">
      <Routes>
        <Route path="/agent_portal" element={<AgentPortal />} />
        <Route
          path="/*"
          element={
            <div>
              <HeaderComponent />
              <Routes>
                <Route index path="/" element={<HomeScreen />} />
                <Route
                  path="/more_destinations"
                  element={<DestinationsScreen />}
                />
                <Route path="/departure_ports" element={<DeparturePorts />} />
                <Route path="/destinations" element={<DestinationsScreen />} />
                <Route
                  path="/major_cruise_lines/:urlName"
                  element={<CruiseLine />}
                />
                <Route
                  path="/other_cruise_lines/:urlName"
                  element={<CruiseLine />}
                />
                <Route path="/cruise-lines" element={<AllCruiseLines />} />
                <Route path="/how_to_book" element={<HowtoBook />} />
                <Route path="/contact_us" element={<Contact />} />
                <Route path="/privacy_policy" element={<PrivacyPolicy />} />
                <Route path="/faq" element={<FaqScreen />} />
                <Route path="/whycruise" element={<WhyCruise />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
              {/* <BookNow /> */}
              <NewsLetter />
              <Footer />
              <FloatButton.BackTop type={"primary"} />
            </div>
          }
        />
      </Routes>
    </div>
  );
};

export default App;
