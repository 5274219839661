import React, { createContext, useContext, useState, useEffect } from "react";

const AppContext = createContext<any>(null);

const AppProvider = ({ children }: { children: React.ReactNode }) => {
  const [currentUser, setCurrentUser] = useState<any>(null);
  const [faqChildActiveKeys, setFaqChildActiveKeys] = useState([]);

  useEffect(() => {
    const storedUser = localStorage.getItem("currentUser");
    if (storedUser) {
      setCurrentUser(storedUser);
    }

    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === "currentUser") {
        setCurrentUser(event.newValue);
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const login = (user: any) => {
    setCurrentUser(user?.username);
    localStorage.setItem("currentUser", user?.username);
  };

  const logout = () => {
    setCurrentUser(null);
    localStorage.removeItem("currentUser");
  };

  return (
    <AppContext.Provider
      value={{
        currentUser,
        setCurrentUser,
        login,
        logout,
        faqChildActiveKeys,
        setFaqChildActiveKeys,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

const useApp = () => useContext(AppContext);

export { AppProvider, useApp };
