import { Container } from "react-bootstrap";
import "./styles.scss";
import { Button, Form } from "antd";
import { Input } from "antd";
import { notification } from "antd";
import { Fade } from "react-awesome-reveal";
import { RiSendPlaneFill } from "react-icons/ri";
import { LoadingOutlined } from "@ant-design/icons";
import { useState } from "react";
import emailjs from "@emailjs/browser";
type NotificationType = "success" | "info" | "warning" | "error";

function NewsLetter() {
  const [api, contextHolder] = notification.useNotification();
  const [loading, setLoading] = useState(false);

  const openNotificationWithIcon = (type: NotificationType) => {
    api[type]({
      message: "Welcome Aboard!",
      description:
        "Thank you for joining our cruise community! Prepare to receive exclusive booking alerts and exciting offers. Welcome aboard!",
    });
  };
  const openNotificationWithIconError = (type: NotificationType) => {
    api[type]({
      message: "Something went wrong!",
      description: "Something went wrong!",
    });
  };

  const validateMessages = {
    required: "This field is required!",
    types: {
      email: "Invalid Email!",
    },
  };
  /* eslint-enable no-template-curly-in-string */

  const onFinish = async () => {
    try {
      setLoading(true);
      const formValues = form.getFieldsValue();

      const formElement = document.createElement("form");
      Object.entries(formValues).forEach(([key, value]) => {
        const input = document.createElement("input");
        input.name = key;
        input.value = String(value);
        formElement.appendChild(input);
      });

      await emailjs.sendForm(
        "service_neoekk8",
        "template_e3chnif",
        formElement,
        "9u1FcKZAMVif5drQG"
      );
      setLoading(false);
      openNotificationWithIcon("success");
      form.resetFields();
    } catch (error) {
      setLoading(true);
      console.error(error);
      openNotificationWithIconError("error");
      form.resetFields();
    }
  };

  const [form] = Form.useForm();

  return (
    <div className="registration column-padding-h column-padding-b column-padding-t">
      {contextHolder}
      <Container>
        <div className="flex center flex-column">
          <Fade duration={2000} triggerOnce>
            <h1 className="font-medium font-black mb-16">
              Sign Up for Our Special Offers
            </h1>
          </Fade>
        </div>
        <Form
          name="nest-messages"
          onFinish={onFinish}
          validateMessages={validateMessages}
          form={form}
          className="flex center"
        >
          <div className="flex center full-width input-div bg-white">
            <Form.Item
              name="user_email"
              rules={[{ type: "email", required: true }]}
              className="full-width"
            >
              <Input
                placeholder="Your Email"
                className="bold-500 newesletter-input"
                style={{
                  borderColor: "white",
                  outline: "none",
                  boxShadow: "none",
                  borderRadius: 0,
                }}
              />
            </Form.Item>
            <Form.Item className="flex center">
              <Button
                htmlType="submit"
                type="primary"
                icon={
                  loading ? (
                    <LoadingOutlined style={{ fontSize: 18 }} spin />
                  ) : (
                    <RiSendPlaneFill />
                  )
                }
              />
            </Form.Item>
          </div>
          <div></div>
        </Form>
      </Container>
    </div>
  );
}

export default NewsLetter;
