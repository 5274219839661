import "./styles.scss";
import { Container } from "react-bootstrap";
import { Divider } from "antd";
import { Collapse } from "antd";
import React from "react";
import { useApp } from "../../util/AppProvider";

function FaqItemChild(item: any) {
  const { faqChildActiveKeys, setFaqChildActiveKeys } = useApp();

  const onChange = (keys: any) => {
    setFaqChildActiveKeys(keys);
  };

  return (
    <div className="faq">
      <Container>
        <div className="flex flex-column">
          <Collapse
            accordion
            ghost
            expandIconPosition="end"
            activeKey={faqChildActiveKeys}
            onChange={onChange}
            style={{backgroundColor:"white"}}
          >
            {item?.item?.length > 0 &&
              item.item?.map((child: any, index: number) => {
                return (
                  <React.Fragment key={index + 1}>
                    <Collapse.Panel
                      header={child.label}
                      key={index + 1}
                      className="faq-panel"
                    >
                      {child.children}
                    </Collapse.Panel>
                    <div>
                      {index !== item.item?.length - 1 && (
                        <Divider style={{ margin: 0 }} />
                      )}
                    </div>
                  </React.Fragment>
                );
              })}
          </Collapse>
        </div>
      </Container>
    </div>
  );
}

export default FaqItemChild;
