import "./styles.scss";
import { Col, Container, Row } from "react-bootstrap";
import { Link as RouterLink } from "react-router-dom";
import logo_white from "../../assets/logos/cm-white-tagline.png";
import soon_img from "../../assets/img/soon.png";

// type NotificationType = "success" | "info" | "warning" | "error";

function AgentPortal() {
  // const { currentUser, login, logout } = useApp();

  // const [loading, setLoading] = useState(false);
  // const [error, setError] = useState(false);

  // const [api, contextHolder] = notification.useNotification();

  // const validateMessages = {
  //   required: "This field is required!",
  // };
  // /* eslint-enable no-template-curly-in-string */

  // const [form] = Form.useForm();

  // const openNotificationWithIconError = (type: NotificationType) => {
  //   api[type]({
  //     message: "Login Failed!",
  //     description:
  //       "Please Try Again Later.",
  //   });
  // };

  // const onFinish = async (values: any) => {
  //   try {
  //     setLoading(true);
  //     setError(false);
  //     await axios
  //       .get("https://cruisemaster-me.com/api/GET_USERS.php", {
  //         params: {
  //           username: values?.username,
  //           password: values?.password,
  //         },
  //       })
  //       .then((res: any) => {
  //         console.log(res?.data[0], "here");
  //         if (res?.data && res?.data?.length > 0) {
  //           login(res?.data[0]);
  //           setError(false);
  //           setLoading(false);
  //           form.resetFields();
  //         } else {
  //           setError(true);
  //           setLoading(false);
  //           form.resetFields();
  //         }
  //       });
  //   } catch (error) {
  //     setLoading(false);
  //     setError(false);
  //     console.error(error);
  //     openNotificationWithIconError("error");
  //     form.resetFields();
  //   }
  // };

  return (
    <div className="agent">
      <div className="agent-header flex center">
        <Container>
          <Row>
            <Col> </Col>
            <Col className="flex center">
              <RouterLink
                to="/"
                onClick={() => {
                  window.scroll(0, 0);
                }}
              >
                <img
                  src={logo_white}
                  style={{ height: "50px" }}
                  className="pointer"
                  alt="logo"
                />
              </RouterLink>
            </Col>
            <Col className="flex end"></Col>{" "}
          </Row>
        </Container>
      </div>
      <div className="agent-content flex center flex-column">
        <img src={soon_img} height={"300px"} width="auto" alt="coming soon"/>
        <h4 className="font-medium opacity80">Under Maintenance</h4>
      </div>

      <div className="agent-footer flex center font-regular">
        <RouterLink
          to="/"
          onClick={() => {
            window.scroll(0, 0);
          }}
          className="font-14 font-white no-decoration"
        >
          Cruise Master 2024 - All Rights Reserved
        </RouterLink>
      </div>
    </div>
  );
}

export default AgentPortal;
