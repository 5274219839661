import "./styles.scss";
import Destinations from "../../Components/Destinations";
import CruiseLines from "../../Components/CruiseLines";
import HeroSection from "./HeroSection";
import { Helmet } from "react-helmet";
import SearchForm from "./SearchForm";
import BookNow from "../../Components/BookNow";

function HomeScreen() {
  const destinationsRichResults = [
    {
      "@context": "https://schema.org/",
      "@type": "Product",
      "name": "Mediterranean",
      "image": [
        "https://cdn.jsdelivr.net/gh/CruiseMaster-ME/CruiseMaster-CDN@v1.1.2/assets/img/destinations/Slides/med2.jpg",
      ],
      "description":
        "The Mediterranean region is one of the most beautiful regions of the world. The Eastern Mediterranean region comprises of countries located to the east of the Mediterranean Sea. It includes countries like Greece, Turkey, Egypt, Cyprus, Lebanon, Syria, Palestine, Israel, Jordan and Libya. The region has charming people and delicious cuisine. The region is regarded as the cradle of western civilization and is a destination which amazes everyone with its beaches, cuisine and history. Western Mediterranean is the region which comprises of countries on the western side of the Mediterranean Sea. It includes countries like Spain, Italy, France, Tunisia, Morocco and Algeria. The region offers delicious cuisine, has magnificent architecture, natural beauty and jovial people. The Central Mediterranean consists of countries like Italy, Greece, Croatia and Albania. The region is known for bright sunshine, natural beauty and delicious sunshine. Cruise ships visit the Mediterranean region all-round the year. The length of cruise is recommended to be minimum 7 days.",
      "offers": {
        "@type": "Offer",
        "price": 899.99,
        "priceCurrency": "USD",
      },
    },
    {
      "@context": "https://schema.org/",
      "@type": "Product",
      "name": "Northern Europe",
      "image": [
        "https://cdn.jsdelivr.net/gh/CruiseMaster-ME/CruiseMaster-CDN@v1.1.2/assets/img/destinations/Slides/north.jpg",
      ],
      "description":
        "Northern Europe comprises of countries like Sweden, Finland, Estonia, Latvia, Norway and Denmark. Sweden’s capital Stockholm is a major attraction having red, orange and cream buildings in its old town. Finland is a beautiful country and its capital Helsinki is known as ‘the white city of the north’. In Norway, one must visit the 14th century fortress and the museums of Oslo. Bergen is gateway to the Fjords, which are a major tourist attraction. Denmark’s attraction are its historic towns, fairytale castles and majestic fjords. It’s capital, Copenhagen has scenic locales around the Town Hall square. Latvia is an ideal place to unwind and refresh yourself. It has golden beaches, crumbling castles and dark forests. Estonia is a mix of medieval and modern times, borders the Baltic Sea and Gulf of Finland. Estonia’s capital, Tallinn has a medieval center, which is still preserved. The best time to visit is from May to September. The length of the cruise can be 7 days or more.",
      "offers": {
        "@type": "Offer",
        "price": 899.99,
        "priceCurrency": "USD",
      },
    },
    {
      "@context": "https://schema.org/",
      "@type": "Product",
      "name": "Alaska",
      "image": [
        "https://cdn.jsdelivr.net/gh/CruiseMaster-ME/CruiseMaster-CDN@v1.1.2/assets/img/destinations/Slides/alaska.jpg",
      ],
      "description":
        "Alaska is a picturesque destination and one of the most geographically diverse places on earth. Alaska is home to mountains, forests, glaciers, parks, unique flora & fauna and even volcanoes. It is the largest state of USA but is sparsely populated as well. Cruises are considered to be the best way to explore the diversity of Alaska. Anchorage, Fairbanks and Juneau are the top three largest cities of Alaska. It has five distinct regions which are Far North, Inside Passage, Interior, South Central and South West. Alaska has a huge number of parks and public lands which offer outdoor adventures for tourists. Tourists can indulge in a variety of activities both indoors and outdoors which include kayaking, rafting, visiting museums, cultural centers, hiking, climbing, fishing, city tours, day tours, wildlife excursions and the list goes on. The best time to visit is from mid-May to mid-September. To explore all these opportunities, even three weeks may not suffice for tourists.",
      "offers": {
        "@type": "Offer",
        "price": 799.99,
        "priceCurrency": "USD",
      },
    },
    {
      "@context": "https://schema.org/",
      "@type": "Product",
      "name": "Asia",
      "image": [
        "https://cdn.jsdelivr.net/gh/CruiseMaster-ME/CruiseMaster-CDN@v1.1.2/assets/img/destinations/Slides/asia.jpg",
      ],
      "description":
        "This region comprises of south-east Asia, including countries like China, Japan, Malaysia, Singapore, Thailand, Vietnam and Myanmar amongst others. It is a melting pot of various cultures and has rich history. It has world-class cities like Hong Kong, Singapore, Beijing, Shanghai, Tokyo and Kuala Lumpur. It is a popular tourist destination having cultural as well as natural attractions. The Bali islands which are a part of Indonesia is a popular tourist destination. Other attractions include The Great Wall of China, islands of Maldives and temples of Cambodia. The best time to visit is from November to February. The length of the cruise has to be minimum ten days.",
      "offers": {
        "@type": "Offer",
        "price": 799.99,
        "priceCurrency": "USD",
      },
    },
  ];

  return (
    <div style={{ position: "relative" }} className="home">
      <Helmet>
        <title>Cruise Master – Leading Cruise Booking Agency in The Middle East</title>
        {destinationsRichResults.map((destination, index) => (
          <script key={index} type="application/ld+json">
            {JSON.stringify(destination)}
          </script>
        ))}

        <meta
          name="description"
          content="
            Cruise Master is the leading cruise agency in the Middle East with a selection of over two dozen cruise lines to choose from. No matter the style of cruise, or destination, or time of the year, you can plan the perfect cruise holiday with us. From ocean cruises, to river cruises, to exploration cruises, we have them all."
        />
        <link rel="canonical" href="https://www.cruisemaster-me.com/" />
        <meta
          property="og:title"
          content="Cruise Master – Leading Cruise Booking Agency in The Middle East"
        />
        <meta
          property="og:image"
          content="https://www.cruisemaster-me.com/assets/CruiseMaster_Sailing_Adventures.jpg"
        />
        <meta
          property="og:description"
          content="Cruise Master is the leading cruise agency in the Middle East with a selection of over two dozen cruise lines to choose from. No matter the style of cruise, or destination, or time of the year, you can plan the perfect cruise holiday with us. From ocean cruises, to river cruises, to exploration cruises, we have them all."
        />
        <meta property="og:url" content="https://www.cruisemaster-me.com/" />
        <meta property="og:type" content="website" />
      </Helmet>

      <HeroSection />
      <div className="search-form-sm">
        <SearchForm />
      </div>
      <Destinations />
      <CruiseLines />
      <BookNow />
    </div>
  );
}

export default HomeScreen;
