import { Divider } from "antd";
import { Link as RouterLink } from "react-router-dom";
import { useState } from "react";
import "./styles.scss";
import { Drawer } from "antd";
import LoginModal from "../Modals/LoginModal";

interface CustomDrawerProps {
  open: boolean;
  onClose: () => void;
}

const CustomDrawer: React.FC<CustomDrawerProps> = ({ onClose, open }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  // const showModal = () => {
  //   setIsModalOpen(true);
  // };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <Drawer
      placement="left"
      onClose={onClose}
      open={open}
      width={"300px"}
      style={{ zIndex: 2000 }}
    >
      <RouterLink
        to={"/"}
        style={{
          cursor: "pointer",
          padding: "16px 0px",
          fontWeight: "600",
          display: "flex",
          alignItems: "center",
          color: "#0054a0",
          justifyContent: "flex-start",
          fontSize: "14px",
          textDecoration: "none",
        }}
        onClick={() => {
          onClose();
          setTimeout(() => {
            window.scrollTo(0, 0);
          }, 100);
        }}
      >
        HOME
      </RouterLink>
      <Divider style={{ margin: 0 }} />
      <div
        style={{
          cursor: "pointer",
          padding: "16px 0px",
        }}
        onClick={() => {
          onClose();
        }}
      >
        <a
          href="https://res.cruisemaster-me.com/"
          style={{
            fontWeight: "600",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            textDecoration: "none",
            color: "#0054a0",
            fontSize: "14px",
          }}
          target="_self"
          rel="noopener noreferrer"
        >
          PLAN A CRUISE
        </a>
      </div>
      <Divider style={{ margin: 0 }} />
      <RouterLink
        to={"/destinations"}
        style={{
          cursor: "pointer",
          padding: "16px 0px",
          fontWeight: "600",
          display: "flex",
          alignItems: "center",
          color: "#0054a0",
          justifyContent: "flex-start",
          fontSize: "14px",
          textDecoration: "none",
        }}
        onClick={() => {
          onClose();
          setTimeout(() => {
            window.scrollTo(0, 0);
          }, 100);
        }}
      >
        DESTINATIONS
      </RouterLink>
      <Divider style={{ margin: 0 }} />
      <RouterLink
        to={"/cruise-lines"}
        style={{
          cursor: "pointer",
          padding: "16px 0px",
          fontWeight: "600",
          display: "flex",
          alignItems: "center",
          color: "#0054a0",
          justifyContent: "flex-start",
          fontSize: "14px",
          textDecoration: "none",
        }}
        onClick={() => {
          onClose();
          setTimeout(() => {
            window.scrollTo(0, 0);
          }, 100);
        }}
      >
        CRUISE LINES
      </RouterLink>
      {/* <Divider style={{ margin: 0 }} />
      <RouterLink
        to={"/departure_ports"}
        style={{
          cursor: "pointer",
          padding: "16px 0px",
          fontWeight: "600",
          display: "flex",
          alignItems: "center",
          color: "#0054a0",
          justifyContent: "flex-start",
          fontSize: "14px",
          textDecoration: "none",
        }}
        onClick={() => {
          onClose();
          setTimeout(() => {
            window.scrollTo(0, 0);
          }, 100);
        }}
      >
        DEPARTURE PORTS
      </RouterLink> */}
      <Divider style={{ margin: 0 }} />
      <div
        style={{
          cursor: "pointer",
          padding: "16px 0px",
        }}
        onClick={() => {
          onClose();
        }}
      >
        <a
          href="https://cruisemaster-me.com/ebrochure/cruise-holidays-2024"
          style={{
            fontWeight: "600",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            textDecoration: "none",
            color: "#0054a0",
            fontSize: "14px",
          }}
          target="_blank"
          rel="noopener noreferrer"
        >
          CRUISE HOLIDAYS 2024
        </a>
      </div>
      <Divider style={{ margin: 0 }} />
      <RouterLink
        to={"/whycruise"}
        style={{
          cursor: "pointer",
          padding: "16px 0px",
          fontWeight: "600",
          display: "flex",
          alignItems: "center",
          color: "#0054a0",
          justifyContent: "flex-start",
          fontSize: "14px",
          textDecoration: "none",
        }}
        onClick={() => {
          onClose();
          setTimeout(() => {
            window.scrollTo(0, 0);
          }, 100);
        }}
      >
        WHY CRUISE
      </RouterLink>
      <Divider style={{ margin: 0 }} />
      <RouterLink
        to={"/how_to_book"}
        style={{
          cursor: "pointer",
          padding: "16px 0px",
          fontWeight: "600",
          display: "flex",
          alignItems: "center",
          color: "#0054a0",
          justifyContent: "flex-start",
          fontSize: "14px",
          textDecoration: "none",
        }}
        onClick={() => {
          onClose();
          setTimeout(() => {
            window.scrollTo(0, 0);
          }, 100);
        }}
      >
        HOW TO BOOK
      </RouterLink>
      <Divider style={{ margin: 0 }} />
      <RouterLink
        to={"/faq"}
        style={{
          cursor: "pointer",
          padding: "16px 0px",
          fontWeight: "600",
          display: "flex",
          alignItems: "center",
          color: "#0054a0",
          justifyContent: "flex-start",
          fontSize: "14px",
          textDecoration: "none",
        }}
        onClick={() => {
          onClose();
          setTimeout(() => {
            window.scrollTo(0, 0);
          }, 100);
        }}
      >
        FAQ
      </RouterLink>
      <Divider style={{ margin: 0 }} />
      {/* <div
        style={{
          cursor: "pointer",
          padding: "16px 0px",
          fontWeight: "600",
          display: "flex",
          alignItems: "center",
          color: "#0054a0",
          justifyContent: "flex-start",
          fontSize: "14px",
          textDecoration: "none",
        }}
        onClick={() => {
          onClose();
          showModal();
        }}
      >
        TRAVEL AGENT PORTAL
      </div> */}
      <RouterLink
        to={"/agent_portal"}
        style={{
          cursor: "pointer",
          padding: "16px 0px",
          fontWeight: "600",
          display: "flex",
          alignItems: "center",
          color: "#0054a0",
          justifyContent: "flex-start",
          fontSize: "14px",
          textDecoration: "none",
        }}
        onClick={() => {
          onClose();
          setTimeout(() => {
            window.scrollTo(0, 0);
          }, 100);
        }}
      >
        TRAVEL AGENT PORTAL
      </RouterLink>
      <Divider style={{ margin: 0 }} />
      <RouterLink
        to={"/contact_us"}
        style={{
          cursor: "pointer",
          padding: "16px 0px",
          fontWeight: "600",
          display: "flex",
          alignItems: "center",
          color: "#0054a0",
          justifyContent: "flex-start",
          fontSize: "14px",
          textDecoration: "none",
        }}
        onClick={() => {
          onClose();
          setTimeout(() => {
            window.scrollTo(0, 0);
          }, 100);
        }}
      >
        CONTACT US
      </RouterLink>
      <Divider style={{ margin: 0 }} />
      <RouterLink
        to={"/privacy_policy"}
        style={{
          cursor: "pointer",
          padding: "16px 0px",
          fontWeight: "600",
          display: "flex",
          alignItems: "center",
          color: "#0054a0",
          justifyContent: "flex-start",
          fontSize: "14px",
          textDecoration: "none",
        }}
        onClick={() => {
          onClose();
          setTimeout(() => {
            window.scrollTo(0, 0);
          }, 100);
        }}
      >
        PRIVACY POLICY
      </RouterLink>

      <LoginModal isModalOpen={isModalOpen} handleCancel={handleCancel} />
    </Drawer>
  );
};
export default CustomDrawer;
