import { Col, Container, Row } from "react-bootstrap";
import "./styles.scss";
import { destinationNarrow } from "../../DataStore/destinationList";
import { useNavigate } from "react-router-dom";
import { Button } from "antd";

type destinationsType = {
  moreInfo: string;
  name: string;
  imagepath: string;
  daycount: string;
  price: string;
};

function Destinations() {
  const navigate = useNavigate();

  return (
    <>
      <div className="destinations-component column-padding-b">
        <Container className="lg-d-none">
          <div className="flex flex-column center">
            <h2 className="font-medium font-black large-heading mt-16">
              Destinations
            </h2>
            <p className="font-regular font-para-black mb-32 text-align-center">
              Discover the different themes of each destination. Cruise to the
              most sought-after landmarks in Europe, Alaska, Japan, the
              Caribbean, the Mediterranean, Canada & New England, and more.
              Whether you are looking forward to a city break, a romantic
              getaway, a family or beach holiday, a cultural or gastronomic
              trip, alone or with friends, you will be spoiled for choice. Each
              port of call offers its own unique blend of history, culture,
              scenic beauty, geography, adventure, and activities
            </p>
          </div>
          <Row>
            {destinationNarrow
              ?.slice(0, 4)
              ?.map((item: destinationsType, key: number) => {
                return (
                  <Col
                    sm={12}
                    md={6}
                    lg={4}
                    className="mb-32 pointer"
                    key={key}
                    onClick={() => {
                      window.open(`${item?.moreInfo}`, "_self");
                    }}
                  >
                    <div className="">
                      <div style={{ display: "inline-block" }}>
                        <div className="info-div flex center">
                          <h3
                            className="card-info font-medium"
                            style={{ marginBottom: "3px" }}
                          >
                            {item?.name}
                          </h3>
                        </div>
                      </div>
                      <div className="destination-popular-image mb-16">
                        <img src={item?.imagepath} alt="Destinations" />
                      </div>

                      <h4 className="card-info font-medium mt-16">
                        {item?.daycount}
                      </h4>
                      <h4 className="card-info font-medium mt-16">
                        Price starting from{" "}
                        <span className="font-secondary">
                          USD {item?.price}
                        </span>
                      </h4>
                    </div>
                  </Col>
                );
              })}
          </Row>
          <div className="flex center">
            <Button
              type="primary"
              size="large"
              onClick={() => {
                setTimeout(() => {
                  window.scrollTo(0, 0);
                }, 100);
                navigate("/more_destinations");
              }}
              className="btn-small btn-primary card-button font-regular font-white"
            >
              View All Destinations
            </Button>
          </div>
        </Container>
        <Container className="sm-d-none">
          <div className="flex flex-column center">
            <p className="font-regular font-para-black mb-32 text-align-center">
              Discover the different themes of each destination. Cruise to the
              most sought-after landmarks in Europe, Alaska, Japan, the
              Caribbean, the Mediterranean, Canada & New England, and more.
              Whether you are looking forward to a city break, a romantic
              getaway, a family or beach holiday, a cultural or gastronomic
              trip, alone or with friends, you will be spoiled for choice. Each
              port of call offers its own unique blend of history, culture,
              scenic beauty, geography, adventure, and activities
            </p>
          </div>
          <div className="destination-image-parent destinationNarrow">
            {destinationNarrow?.slice(0, 4)?.map((item: destinationsType) => {
              return (
                <div
                  className="flex center pointer destination-image-child"
                  style={{
                    backgroundImage: `linear-gradient( to bottom , rgba(0, 0, 0,0.3) , rgba(0, 0, 0, 0.3)),url(${item?.imagepath})`,
                    objectPosition: "center center",
                    backgroundSize: "cover",
                    flexDirection: "column",
                  }}
                  onClick={() => {
                    window.open(`${item?.moreInfo}`, "_self");
                  }}
                >
                  <h4 className="destinationName heading font-white font-bold mb-16">
                    {item?.name}
                  </h4>
                  <h3 className="destinationName-small font-white font-bold">
                    {item?.name}
                  </h3>

                  <h4 className="destinationName font-white card-info number-font">
                    {item?.daycount}
                  </h4>
                  <h4 className="destinationName font-white card-info number-font bold-700">
                    <span>USD&nbsp;</span>
                    {item?.price}
                  </h4>
                  <h4 className="font-14 number-font font-white destination-details-small">
                    {item?.daycount}
                  </h4>
                  <h4 className="font-14 number-font font-white  destination-details-small bold-700">
                    <span className="number-font">USD&nbsp;</span>
                    {item?.price}
                  </h4>
                </div>
              );
            })}
          </div>

          <div className="flex center mt-32">
            <Button
              type="primary"
              size="large"
              onClick={() => {
                setTimeout(() => {
                  window.scrollTo(0, 0);
                }, 100);
                navigate("/more_destinations");
              }}
              className="btn-small btn-primary card-button font-regular font-white"
            >
              View All Destinations
            </Button>
          </div>
        </Container>
      </div>
    </>
  );
}

export default Destinations;
