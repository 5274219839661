import { Container } from "react-bootstrap";
import Banner from "../../Components/Banner";
import { Helmet } from "react-helmet";

function WhyCruise() {
  return (
    <div className="privacy column-padding-b">
      <Helmet>
        <title>Why Cruise</title>
        <meta
          name="description"
          content="Learn why a cruise is the ideal vacation, offering diverse destinations, exceptional amenities, and memorable experiences."
        />
        <meta property="og:title" content="Why Cruise" />
        <meta
          property="og:description"
          content="Learn why a cruise is the ideal vacation, offering diverse destinations, exceptional amenities, and memorable experiences."
        />
      </Helmet>
      <Banner link="Why Cruise" link2="Why Cruise Holidays!" />
      <Container>
        <h2 className="font-regular para font-para-black  mb-32 text-align-center">
          Top 10 reasons why your next break should be a cruising holiday
        </h2>
        <ol>
          <li>
            <p className="font-regular font-para-black  mb-16">
              The cost of your cruise includes your accommodation,
              transportation, all meals, activities and entertainment. Some
              cruise lines include gratuities too. There are no surprise
              expenses like extra meals, room service, incidentals etc.
            </p>
          </li>
          <li>
            <p className="font-regular font-para-black  mb-16">
              Every morning you wake up with a new country or island waiting to
              be explored. The ship offers optional organised excursions or you
              may go out on your own.
            </p>
          </li>
          <li>
            <p className="font-regular font-para-black  mb-16">
              Every day on board ship is different. Different theme days for
              dining, menus and shows offer you a unique experience every night.
              You never have to do the same thing twice.
            </p>
          </li>
          <li>
            <p className="font-regular font-para-black  mb-16">
              Visit different destinations without living out of a suitcase, or
              spending most of your holiday running from one airport to the
              next, catching buses and taxis.
            </p>
          </li>
          <li>
            <p className="font-regular font-para-black  mb-16">
              On board ship the whole family can enjoy a proper holiday. Married
              or single, with or without children, the modern floating resorts
              offer facilities and entertainment for different people of
              different backgrounds and with different needs.
            </p>
          </li>
          <li>
            <p className="font-regular font-para-black  mb-16">
              If you're concerned about being stranded in the middle of the
              ocean, relax. Cruise ships are like floating cities with
              everything you could possibly want onboard. Today's vessels are
              outfitted with Wi-Fi, cell service and satellite TV so you can
              stay in touch with the real world during your cruise.
            </p>
          </li>
          <li>
            <p className="font-regular font-para-black  mb-16">
              No matter how active or inactive you want your holiday to be,
              these resorts offer you exactly that! Tennis, mini golf, video
              arcade, basket ball, libraries, video libraries, card games,
              aerobics, fully equipped gyms, health spas, massage, facials and
              much more are on offer every day.
            </p>
          </li>
          <li>
            <p className="font-regular font-para-black  mb-16">
              Cruising is not only for the rich and famous. It is cheaper to
              enjoy a cruise holiday than a similar land based one. With prices
              starting from as little as US$ 75.00 per per day, cruising offers
              unbeatable value for money!
            </p>
          </li>
          <li>
            <p className="font-regular font-para-black  mb-16">
              Married or single, with or without children, the modern floating
              resorts offer facilities and entertainment for different people of
              different backgrounds and with different needs.
            </p>
          </li>
          <li>
            <p className="font-regular font-para-black  mb-16">
              Cruising is guaranteed to be the most relaxed and enjoyable
              holiday of your life.
            </p>
          </li>
        </ol>
        <p className="font-regular font-para-black">
          You can have the vacation of a lifetime, see the world, completely
          unwind, take a walk on the wild side, dance the night away, feast on
          fabulous cuisine, learn something new, make friends, bond with your
          family, and steal quiet moments with your spouse. Whatever your heart
          desires, is yours, when you choose to cruise.
        </p>
      </Container>
    </div>
  );
}

export default WhyCruise;
