import { Container } from "react-bootstrap";
import "./styles.scss";
import Banner from "../../Components/Banner";
import { Helmet } from "react-helmet";
import CruiseListComponent from "../../Components/CruiseListComponent/CruiseListComponent";
import { popularCruiseLines } from "../../DataStore/CruiseLines/popularCruiseLines";
import { luxuryCruiseLines } from "../../DataStore/CruiseLines/luxuryCruiseLines";
import { riverCruiseLines } from "../../DataStore/CruiseLines/riverCruiseLines";
import { expeditionCruiseLines } from "../../DataStore/CruiseLines/expeditionCruiseLines";
import { nicheCruiseLines } from "../../DataStore/CruiseLines/nicheCruiseLines";

function AllCruiseLines() {
  return (
    <>
      <div className="column-padding-b">
        <Helmet>
          <title>Our Partner Cruise Lines</title>
          <meta
            name="description"
            content="Choose from a wide variety of the world’s best contemporary, premium, luxury, river, and niche cruise lines."
          />
          <meta property="og:title" content="Our Partner Cruise Lines" />
          <meta
            property="og:description"
            content="Choose from a wide variety of the world’s best contemporary, premium, luxury, river, and niche cruise lines."
          />
        </Helmet>
        <Banner link="Our Partner Cruise Lines" link2="" />
        <Container>
          <div className="flex flex-column center">
            <h2 className="font-regular para font-para-black mb-32 text-align-center">
              Choose from a wide variety of the world’s best contemporary,
              premium, luxury, river, and niche cruise lines
            </h2>
          </div>
          <CruiseListComponent
            CruiseLine={popularCruiseLines}
            heading={"POPULAR CRUISE LINES"}
            cruiseType="popular"
          />
          <CruiseListComponent
            CruiseLine={luxuryCruiseLines}
            heading={"LUXURY CRUISE LINES"}
            cruiseType="luxury"
          />
          <CruiseListComponent
            CruiseLine={riverCruiseLines}
            heading={"RIVER CRUISE LINES"}
            cruiseType="river"
          />
          <CruiseListComponent
            CruiseLine={expeditionCruiseLines}
            heading={"EXPEDITION CRUISE LINES"}
            cruiseType="expedition"
          />
          <CruiseListComponent
            CruiseLine={nicheCruiseLines}
            heading={"NICHE CRUISE LINES"}
            cruiseType="niche"
          />
        </Container>
      </div>
    </>
  );
}

export default AllCruiseLines;
