import { CDN_BASE_URL } from "../Config/apiConfig";

export const SlideList = [
  {
    imagepath: `${CDN_BASE_URL}/img/Slides/NCL.jpg`,
  },
  {
    imagepath: `${CDN_BASE_URL}/img/Slides/Cunard.jpg`,
  },
  {
    imagepath: `${CDN_BASE_URL}/img/Slides/PCL.jpg`,
  },
  {
    imagepath: `${CDN_BASE_URL}/img/Slides/Celestyal.jpg`,
  },
  {
    imagepath: `${CDN_BASE_URL}/img/Slides/DCL.jpg`,
  },
  {
    imagepath: `${CDN_BASE_URL}/img/Slides/RCI.jpg`,
  },
  {
    imagepath: `${CDN_BASE_URL}/img/Slides/MSC.jpg`,
  },
  {
    imagepath: `${CDN_BASE_URL}/img/Slides/PO.jpg`,
  },
  {
    imagepath: `${CDN_BASE_URL}/img/Slides/Windstar.jpg`,
  },
];
