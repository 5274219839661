import { type CollapseProps } from "antd";
import FaqItemChild from "./FaqItemChild";
import {
  Boarding,
  BookingAndFees,
  Communication,
  CostAndPayments,
  Entartainment,
  FaqItemsFamily,
  FaqItemsGeneral,
  Gifts,
  SpecialNeeds,
  SpecialRequests,
  Stateroom,
} from "./FaqItems";

export const FaqItemsParent: CollapseProps["items"] = [
  // General
  {
    key: "1",
    label: (
      <>
        <div className="font-medium font-black opacity80   bold-500 description-text opacity80 ">
          General Information
        </div>
      </>
    ),
    children: (
      <>
        <FaqItemChild item={FaqItemsGeneral} />
      </>
    ),
  },
  {
    key: "2",
    label: (
      <>
        <div className="font-medium font-black opacity80   bold-500 description-text opacity80 ">
          Family and Children
        </div>
      </>
    ),
    children: (
      <>
        <FaqItemChild item={FaqItemsFamily} />
      </>
    ),
  },
  {
    key: "3",
    label: (
      <>
        <div className="font-medium font-black opacity80   bold-500 description-text opacity80 ">
          Costs and Payments
        </div>
      </>
    ),
    children: (
      <>
        <FaqItemChild item={CostAndPayments} />
      </>
    ),
  },
  {
    key: "4",
    label: (
      <>
        <div className="font-medium font-black opacity80   bold-500 description-text opacity80 ">
          Dining and Special Requests
        </div>
      </>
    ),
    children: (
      <>
        <FaqItemChild item={SpecialRequests} />
      </>
    ),
  },
  {
    key: "5",
    label: (
      <>
        <div className="font-medium font-black opacity80   bold-500 description-text opacity80 ">
          Boarding and Departure
        </div>
      </>
    ),
    children: (
      <>
        <FaqItemChild item={Boarding} />
      </>
    ),
  },
  {
    key: "6",
    label: (
      <>
        <div className="font-medium font-black opacity80   bold-500 description-text opacity80 ">
          Stateroom and Facilities
        </div>
      </>
    ),
    children: (
      <>
        <FaqItemChild item={Stateroom} />
      </>
    ),
  },
  {
    key: "7",
    label: (
      <>
        <div className="font-medium font-black opacity80   bold-500 description-text opacity80 ">
          Communication
        </div>
      </>
    ),
    children: (
      <>
        <FaqItemChild item={Communication} />
      </>
    ),
  },
  {
    key: "8",
    label: (
      <>
        <div className="font-medium font-black opacity80   bold-500 description-text opacity80 ">
          Entertainment and Activities
        </div>
      </>
    ),
    children: (
      <>
        <FaqItemChild item={Entartainment} />
      </>
    ),
  },
  {
    key: "9",
    label: (
      <>
        <div className="font-medium font-black opacity80   bold-500 description-text opacity80 ">
          Special Needs
        </div>
      </>
    ),
    children: (
      <>
        <FaqItemChild item={SpecialNeeds} />
      </>
    ),
  },
  {
    key: "10",
    label: (
      <>
        <div className="font-medium font-black opacity80   bold-500 description-text opacity80 ">
          Gifts and Packages
        </div>
      </>
    ),
    children: (
      <>
        <FaqItemChild item={Gifts} />
      </>
    ),
  },
  {
    key: "11",
    label: (
      <>
        <div className="font-medium font-black opacity80   bold-500 description-text opacity80 ">
          Booking and Fees
        </div>
      </>
    ),
    children: (
      <>
        <FaqItemChild item={BookingAndFees} />
      </>
    ),
  },
];
