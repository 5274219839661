import { Link as RouterLink } from "react-router-dom";
import { useState } from "react";
import logo from "../../assets/logos/Cruise Master Logo Blue Tag Line.png";
import "./styles.scss";
import { Col, Container, Row } from "react-bootstrap";
import { VscMenu } from "react-icons/vsc";
import { MdLocalPhone } from "react-icons/md";
import { MdWhatsapp } from "react-icons/md";
import CustomDrawer from "./CustomDrawer";

function HeaderComponent() {
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(!open);
  };

  const onClose = () => {
    setOpen(false);
  };
  return (
    <>
      <div className="bg-primary flex center" style={{ height: "30px" }}>
        <Container>
          <Row>
            <Col>
              <div className="flex responsive-center-end">
                <a
                  href="tel:+97142999223"
                  style={{ textDecoration: "none" }}
                  className="font-white font-regular font-14"
                >
                  <MdLocalPhone
                    style={{ marginBottom: "4px", marginRight: "4px" }}
                  />{" "}
                  +971 4 299 9223 &nbsp;
                </a>
                <div
                  className="font-white opacity-80"
                  style={{
                    marginTop: "-4.8px",
                    marginRight: "4px",
                    marginLeft: "2px",
                  }}
                >
                  |
                </div>{" "}
                &nbsp;
                <a
                  href="https://wa.me/+971502784737"
                  target="_blank"
                  style={{ textDecoration: "none" }}
                  className="font-white font-regular font-14"
                  rel="noreferrer"
                >
                  <MdWhatsapp
                    style={{ marginBottom: "4px", marginRight: "3px" }}
                  />{" "}
                  +971 50 CRUISES
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="common-header general-shadow flex">
        <Container fluid className="full-height lg-d-none">
          <div className="full-height flex center">
            <div
              className={"pointer"}
              style={{ position: "absolute", left: "24px" }}
              onClick={showDrawer}
            >
              <VscMenu style={{ fontSize: "28px", color: "black" }} />
            </div>
            <div>
              <RouterLink
                to="/"
                onClick={() => {
                  window.scroll(0, 0);
                }}
              >
                <div className="header-brand-small">
                  <img src={logo} alt="logo" />{" "}
                </div>
              </RouterLink>
            </div>
          </div>
        </Container>

        <Container className="sm-d-none">
          <div className="full-height space-between header-content ">
            <div className="header-left">
              <div
                className={"pointer menu-lg-screen"}
                style={{ opacity: "0.7", margin: "0px 24px 0px 0px" }}
                onClick={showDrawer}
              >
                {/* Your menu icon */}
                <VscMenu style={{ fontSize: "28px", color: "black" }} />
              </div>
              <RouterLink
                to="/"
                onClick={() => {
                  window.scroll(0, 0);
                }}
              >
                {/* Your logo */}
                <div className="header-brand">
                  <img src={logo} alt="logo" />
                </div>
              </RouterLink>
            </div>
            <div className="header-right">
              {/* Navigation links */}
              <a
                target="_self"
                className={"custom-link  font-black navbar-font letter-spacing"}
                href="https://res.cruisemaster-me.com"
              >
                PLAN A CRUISE
              </a>
              <RouterLink
                to={"/destinations"}
                className={"custom-link  font-black navbar-font letter-spacing"}
                onClick={() => {
                  window.scroll(0, 0);
                }}
              >
                DESTINATIONS
              </RouterLink>
              <RouterLink
                to={"/cruise-lines"}
                className={
                  "custom-link-last  font-black navbar-font letter-spacing"
                }
                onClick={() => {
                  window.scroll(0, 0);
                }}
              >
                CRUISE LINES
              </RouterLink>
            </div>
          </div>
        </Container>
      </div>

      <CustomDrawer open={open} onClose={onClose} />
    </>
  );
}

export default HeaderComponent;
