import amawaterways from "../../assets/logos/amawaterways.png";
import aquaexpedition from "../../assets/logos/aquaexpedition.png";
import avalon from "../../assets/logos/avalon.png";
import uniworld from "../../assets/logos/uniworld.png";
import viking from "../../assets/logos/viking.png";
import { CDN_BASE_URL } from "../../Config/apiConfig";

export const riverCruiseLines = [
  {
    title: "Ama WaterWays",
    logo: amawaterways,
    online: true,
    resUrl:
      "https://res.cruisemaster-me.com/swift/cruise/cruiseline/amawaterways?siid=177652&cruiseline=8138",
    url: "/other_cruise_lines/amawaterways",
    cruiseid: "8138",
    urlName: "amawaterways",
    banner: `${CDN_BASE_URL}/img/ships/Ama/AmaWaterways.jpg`,
    overview:
      "With 19 years of experience on the world’s rivers, our family-owned and operated company has remained focused on what we do best: providing our guests with innovative and unforgettable travel experiences. Our visionary leaders were the pioneers of modern luxury river cruising and have received numerous prestigious awards and accolades. However, we are most fulfilled by the positive feedback we receive from our guests, whom we have always considered a part of our family. Join us, stay awhile and discover Europe, Asia or Africa with us – one river at a time. ",

    aboutships:
      "Our ships are inspired by luxury yachts and designed specifically to maximize every aspect of our guests’ experience. You will notice the difference the minute you step on board and are welcomed in the beautiful lobby. From then on, you will be treated to an array of unique and luxurious features as you journey along in your floating hotel. Relax and delight in stunning views in our spacious staterooms, many of which feature twin balconies — a French balcony and an outside balcony. Enjoy exclusive amenities like massage services, a fitness room and a pool while on board. Indulge in award-winning cuisine at a variety of dining venues, including The Chef’s Table specialty restaurant — an experience you won’t find elsewhere. ",
    brochures: [],
    cruises: [
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/amawaterways-amabella?ship=13393",
        shipname: "AmaBella",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/amawaterways-amacello?ship=13385",
        shipname: "AmaCello",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/amawaterways-amacerto?ship=13500",
        shipname: "AmaCerto",
      },

      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/amawaterways-amadante?ship=13386",
        shipname: "AmaDante",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/amawaterways-amadolce?ship=13380",
        shipname: "AmaDolce",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/amawaterways-amakristina?ship=13772",
        shipname: "AmaKristina",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/amawaterways-amalea?ship=13796",
        shipname: "AmaLea",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/amawaterways-amalucia?ship=14712",
        shipname: "AmaLucia",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/amawaterways-amalyra?ship=13383",
        shipname: "AmaLyra",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/amawaterways-amamagna?ship=14047",
        shipname: "AmaMagna",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/amawaterways-amamora?ship=14045",
        shipname: "AmaMora",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/amawaterways-amaprima?ship=13522",
        shipname: "AmaPrima",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/amawaterways-amareina?ship=13628",
        shipname: "AmaReina",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/amawaterways-amaserena?ship=13695",
        shipname: "AmaSerena",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/amawaterways-amasiena?ship=14472",
        shipname: "AmaSiena",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/amawaterways-amasonata?ship=13627",
        shipname: "AmaSonata",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/amawaterways-amastella?ship=13733",
        shipname: "AmaStella",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/amawaterways-amavenita?ship=13771",
        shipname: "AmaVenita",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/amawaterways-amaverde?ship=13392",
        shipname: "AmaVerde",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/amawaterways-amaviola?ship=13734",
        shipname: "AmaViola",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/amawaterways-amadouro?ship=13387",
        shipname: "AmaDouro",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/amawaterways-amavida?ship=13523",
        shipname: "AmaVida",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/amawaterways-amasintra?ship=15305",
        shipname: "AmaSintra",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/amawaterways-amadara?ship=13696",
        shipname: "AmaDara",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/amawaterways-zambezi-queen?ship=13524",
        shipname: "Zambezi Queen",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/amawaterways-amadahlia?ship=14829",
        shipname: "AmaDahlia",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/amawaterways-amalilia?ship=15225",
        shipname: "AmaLilia",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/amawaterways-amamagdalena?ship=15260",
        shipname: "AmaMagdalena",
      },

      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/amawaterways-amamelodia?ship=15261",
        shipname: "AmaMelodia",
      },
    ],
  },
  {
    title: "Aqua Expeditions",
    logo: aquaexpedition,
    online: false,
    resUrl: "",
    url: "/other_cruise_lines/aqua_expeditions",
    cruiseid: "0",
    urlName: "aqua_expeditions",
    banner: `${CDN_BASE_URL}/img/ships/Aqua/AquaExpeditions.jpg`,
    overview:
      "Since founding and starting Aqua Expeditions thirteen years ago, it has been the vision and mission of Francesco Galli Zugaro to create intimate, small ship expeditions to distant parts of the world. Now, more than ever, access is the new measure of luxury. What Aqua has stood for since the very beginning comes to the forefront, demonstrating the advantages of small explorer vessels in bringing families and friends on travels to the remote Amazon River of Peru, the Mekong River in Southeast Asia and the pristine coastal waters of Eastern Indonesia",
    aboutships:
      "From the Peruvian Amazon to Cambodia, Vietnam and Indonesia, Aqua Expeditions’ cutting-edge vessels stand out for their sophisticated luxurious contemporary design aesthetic combined with a strong sense of place and immaculate attention to detail. With specially-curated experiences and an engaging 1:1 guest to guide ratio, our boutique expedition vessels offer personalized service, onboard our spacious private and entertaining spaces and on our tailor-made excursions. Venturing into unspoiled sanctuaries to witness the beauty of nature and wildlife, we sail to remote destinations with minimised human interaction, secluded from crowds.",
    brochures: [],
    cruises: [
      {
        resShipUrl: "",
        shipname: "Aqua Mekong",
      },
      {
        resShipUrl: "",
        shipname: "Aria Amazon",
      },
      {
        resShipUrl: "",
        shipname: "Aqua Nera",
      },
      {
        resShipUrl: "",
        shipname: "Aqua Blu",
      },
      {
        resShipUrl: "",
        shipname: "Aqua Mare",
      },
    ],
  },
  {
    title: "Avalon Waterways",
    logo: avalon,
    online: true,
    resUrl:
      "https://res.cruisemaster-me.com/swift/cruise/cruiseline/avalon-waterways?siid=177652&cruiseline=8121",
    url: "/other_cruise_lines/avalon_waterways",
    cruiseid: "8121",
    urlName: "avalon_waterways",
    banner: `${CDN_BASE_URL}/img/ships/Avalon/AvalonWaterways.jpg`,
    overview:
      "No other fleet of river cruise ships offers you a view of the world like Avalon Suite Ships,® featuring Avalon Panorama SuitesSM. Thirty percent larger than the industry standard, they’re actually river cruising’s only Open–Air Balcony® — with nothing between you and the view. While other river cruise cabins are designed with beds against a wall for a view of another wall, our Comfort Collection BedsSM actually face the view with the widest-opening, wall-to-wall, floor-to-ceiling panoramic windows in river cruising. Think of it as a view with a room!",

    aboutships:
      "In order to deliver a cruise unlike any other, we're constantly evolving the Avalon experience, adding intriguing features every year! So, what makes a Suite Ship® so sweet? Our exclusive Panorama SuitesSM — exquisite accommodations with more space to stretch out and enjoy your cruise, innovative room configuration to highlight every view, and a huge Open–Air Balcony® that provides an indoor/outdoor seating area without sacrificing room space. Plus, we offer Avalon Choice® ... for a uniquely customizable river cruise adventure.",
    brochures: [],
    cruises: [
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/avalon-waterways-avalon-alegria?ship=15224",
        shipname: "Avalon Alegria",
      },
      // {
      //   resShipUrl:
      //     "",
      //   shipname: "Artistry II",
      // }not in res,
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/avalon-waterways-avalon-view?ship=14478",
        shipname: "Avalon View",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/avalon-waterways-avalon-envision?ship=14084",
        shipname: "Avalon Envision",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/avalon-waterways-avalon-imagery-ii?ship=13306",
        shipname: "Avalon Imagery ll",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/avalon-waterways-avalon-passion?ship=14085",
        shipname: "Avalon Passion",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/avalon-waterways-avalon-tranquility-ii?ship=13697",
        shipname: "Avalon Tranquility ll",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/avalon-waterways-avalon-tapestry-ii?ship=13307",
        shipname: "Avalon Tapestry ll",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/avalon-waterways-avalon-poetry-ii?ship=13308",
        shipname: "Avalon Poetry ll",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/avalon-waterways-avalon-illumination?ship=13652",
        shipname: "Avalon Illumination",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/avalon-waterways-avalon-impression?ship=13653",
        shipname: "Avalon Impression",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/avalon-waterways-avalon-expression?ship=13528",
        shipname: "Avalon Expression",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/avalon-waterways-avalon-vista?ship=13490",
        shipname: "Avalon Vista",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/avalon-waterways-avalon-panorama?ship=13299",
        shipname: "Avalon Panorama",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/avalon-waterways-avalon-saigon?ship=14086",
        shipname: "Avalon Saigon",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/avalon-waterways-delfin-iii?ship=14088",
        shipname: "Delfin lll",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/avalon-waterways-treasure-of-gal%c3%a1pagos?ship=14087",
        shipname: "Treasure of Galápagos",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/avalon-waterways-ms-farah?ship=14740",
        shipname: "MS Farah",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/avalon-waterways-avalon-visionary?ship=13489",
        shipname: "Avalon Visionary",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/avalon-waterways-sonesta-st-george?ship=15175",
        shipname: "Sonesta St George",
      },
    ],
  },
  {
    title: "Uniworld",
    logo: uniworld,
    online: true,
    resUrl:
      "https://res.cruisemaster-me.com/swift/cruise/cruiseline/uniworld-boutique-river-cruises?siid=177652&cruiseline=8149",
    url: "/other_cruise_lines/uniworldboutiqueriver",
    cruiseid: "8149",
    urlName: "uniworldboutiqueriver",
    banner: `${CDN_BASE_URL}/img/ships/Uniworld/Uniworld.jpg`,
    overview:
      "Service is at the heart of everything we do. We treat our guests like family–a philosophy instilled in our company culture as a family-owned business with 100 years in the hospitality industry. It’s the driving force behind our guest-centric atmosphere and the reason you leave your cruise feeling like part of the family. At Uniworld, we all live by the credo “No request is too large, no detail is too small.” Our goal is to give you the unique and unforgettable experiences you seek, with the personalized, industry-leading service you deserve.",
    aboutships:
      "Uniworld Boutique River Cruise Collection is modeled after our sister company, the five-star Red Carnation Hotel Collection. Their collection of boutique hotels is known for their destination-inspired interior décor, gracious service and carefully selected amenities—all of which provide an intimate, elegant and relaxed experience. Likewise, our boutique river cruise ships are stylish floating hotels with inviting spaces, exquisite antiques, custom fabrics and original works of art.",
    brochures: [],
    cruises: [
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/uniworld-boutique-river-cruises-river-duchess?ship=13469",
        shipname: "River Duchess",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/uniworld-boutique-river-cruises-river-princess?ship=13471",
        shipname: "River Princess",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/uniworld-boutique-river-cruises-river-queen?ship=13472",
        shipname: "River Queen",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/uniworld-boutique-river-cruises-s-s-antoinette?ship=13465",
        shipname: "S.S. Antoinette",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/uniworld-boutique-river-cruises-s-s-beatrice?ship=14046",
        shipname: "S.S. Beatrice",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/uniworld-boutique-river-cruises-s-s-bon-voyage?ship=14295",
        shipname: "S.S. Bon Voyage",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/uniworld-boutique-river-cruises-s-s-catherine?ship=13658",
        shipname: "S.S. Catherine",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/uniworld-boutique-river-cruises-s-s-elisabeth?ship=15333",
        shipname: "S.S. Elisabeth",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/uniworld-boutique-river-cruises-s-s-joie-de-vivre?ship=13773",
        shipname: "S.S. Joie de Vivre",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/uniworld-boutique-river-cruises-s-s-la-venezia?ship=14476",
        shipname: "S.S. La Venezia",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/uniworld-boutique-river-cruises-s-s-maria-theresa?ship=13736 ",
        shipname: "S.S. Maria Theresa",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/uniworld-boutique-river-cruises-s-s-s%c3%a3o-gabriel?ship=14475",
        shipname: "S.S. Sao Gabriel",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/uniworld-boutique-river-cruises-s-s-victoria?ship=15332",
        shipname: "S.S. Victoria",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/uniworld-boutique-river-cruises-river-tosca?ship=13477",
        shipname: "River Tosca",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/uniworld-boutique-river-cruises-s-s-sphinx?ship=14479",
        shipname: "S.S. Sphinx",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/uniworld-boutique-river-cruises-mekong-jewel?ship=14473",
        shipname: "Mekong Jewel",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/uniworld-boutique-river-cruises-ganges-voyager-ii?ship=13735",
        shipname: "Ganges Voyager II",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/uniworld-boutique-river-cruises-aria-amazon?ship=14759",
        shipname: "Aria Amazon",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/uniworld-boutique-river-cruises-aqua-nera?ship=15346",
        shipname: "Aqua Nera",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/uniworld-boutique-river-cruises-river-empress?ship=13470",
        shipname: "River Empress",
      },
    ],
  },
  {
    title: "Viking River Cruises",
    logo: viking,
    online: true,
    resUrl:
      "https://res.cruisemaster-me.com/swift/cruise/cruiseline/viking-river?siid=177652&cruiseline=8112",
    url: "/other_cruise_lines/viking_cruises",
    cruiseid: "8112",
    urlName: "viking_cruises",
    banner: `${CDN_BASE_URL}/img/ships/Viking/VikingCruises.jpg`,
    overview:
      "Viking was founded in 1997 with the purchase of four ships in Russia. In 2000, the company purchased Europe’s KD River Cruises in order to grow its fleet, to leverage 40 years of river cruising experience and to acquire rights to prime docking locations in key European cities.",
    aboutships: "",
    brochures: [],
    cruises: [
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/viking-river-viking-longship-aegir?ship=13534",
        shipname: "Viking Longship Aegir",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/viking-river-viking-longship-alruna?ship=13718",
        shipname: "Viking Longship Alruna",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/viking-river-viking-longship-alsvin?ship=13630",
        shipname: "Viking Longship Alsvin",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/viking-river-viking-longship-atla?ship=13537",
        shipname: "Viking Longship Atla",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/viking-river-viking-longship-baldur?ship=13612",
        shipname: "Viking Longship Baldur",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/viking-river-viking-longship-bragi?ship=13536",
        shipname: "Viking Longship Bragi",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/viking-river-viking-longship-buri?ship=13621",
        shipname: "Viking Longship Buri",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/viking-river-viking-longship-delling?ship=13613",
        shipname: "Viking Longship Delling",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/viking-river-viking-egdir?ship=15076",
        shipname: "Viking Egdir",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/viking-river-viking-longship-egil?ship=13719",
        shipname: "Viking Longship Egil",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/viking-river-viking-longship-einar?ship=14056",
        shipname: "Viking Longship Einar",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/viking-river-viking-longship-eir?ship=13682",
        shipname: "Viking Longship Eir",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/viking-river-viking-longship-embla?ship=13535",
        shipname: "Viking Longship Embla",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/viking-river-viking-longship-forseti?ship=13538",
        shipname: "Viking Longship Forseti",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/viking-river-viking-longship-freya?ship=13492",
        shipname: "Viking Longship Freya",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/viking-river-viking-longship-gefjon?ship=13676",
        shipname: "Viking Longship Gefjon",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/viking-river-viking-gersemi?ship=14516",
        shipname: "Viking Gersemi",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/viking-river-viking-longship-gullveig?ship=13614",
        shipname: "Viking Longship Gullveig",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/viking-river-viking-gymir?ship=15075",
        shipname: "Viking Gymir",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/viking-river-viking-longship-heimdal?ship=13615",
        shipname: "Viking Longship Heimdal",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/viking-river-viking-herja?ship=13775",
        shipname: "Viking Herja",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/viking-river-viking-longship-hermod?ship=13622",
        shipname: "Viking Longship Hermod",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/viking-river-viking-hervor?ship=14517",
        shipname: "Viking Hervor",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/viking-river-viking-hild?ship=13774",
        shipname: "Viking Hild",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/viking-river-viking-longship-hlin?ship=13660",
        shipname: "Viking Longship Hlin",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/viking-river-viking-longship-idi?ship=13616",
        shipname: "Viking Longship Idi",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/viking-river-viking-longship-idun?ship=13493",
        shipname: "Viking Longship Idun",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/viking-river-viking-longship-ingvi?ship=13633",
        shipname: "Viking Longship Ingvi",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/viking-river-viking-longship-jarl?ship=13539",
        shipname: "Viking Longship Jarl",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/viking-river-viking-longship-kadlin?ship=13720",
        shipname: "Viking Longship Kadlin",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/viking-river-viking-kara?ship=13661",
        shipname: "Viking Kara",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/viking-river-viking-longship-kvasir?ship=13617",
        shipname: "Viking Longship Kvasir",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/viking-river-viking-longship-lif?ship=13618",
        shipname: "Viking Longship Lif",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/viking-river-viking-longship-lofn?ship=13674",
        shipname: "Viking Longship Lofn",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/viking-river-viking-longship-magni?ship=13619",
        shipname: "Viking Longship Magni",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/viking-river-viking-longship-mani?ship=13681",
        shipname: "Viking Longship Mani",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/viking-river-viking-longship-mimir?ship=13679",
        shipname: "Viking Longship Mimir",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/viking-river-viking-longship-modi?ship=13677",
        shipname: "Viking Longship Modi",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/viking-river-viking-longship-njord?ship=13494",
        shipname: "Viking Longship Njord",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/viking-river-viking-longship-rinda?ship=13540",
        shipname: "Viking Longship Rinda",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/viking-river-viking-longship-rolf?ship=13721",
        shipname: "Viking Longship Rolf",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/viking-river-viking-longship-sigrun?ship=14058",
        shipname: "Viking Longship Sigrun",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/viking-river-viking-longship-sigyn?ship=14059",
        shipname: "Viking Longship Sigyn",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/viking-river-viking-longship-skadi?ship=13541",
        shipname: "Viking Longship Skadi",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/viking-river-viking-longship-skirnir?ship=13685",
        shipname: "Viking Longship Skirnir",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/viking-river-viking-longship-tialfi?ship=13722",
        shipname: "Viking Longship Tialfi",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/viking-river-viking-longship-tir?ship=14054",
        shipname: "Viking Longship Tir",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/viking-river-viking-longship-tor?ship=13543",
        shipname: "Viking Longship Tor",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/viking-river-viking-longship-ullur?ship=14055",
        shipname: "Viking Longship Ullur",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/viking-river-viking-longship-vali?ship=14057",
        shipname: "Viking Longship Vali",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/viking-river-viking-longship-var?ship=13542",
        shipname: "Viking Longship Var",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/viking-river-viking-longship-ve?ship=13678",
        shipname: "Viking Longship Ve",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/viking-river-viking-longship-vidar?ship=13675",
        shipname: "Viking Longship Vidar",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/viking-river-viking-longship-vilhjalm?ship=13760",
        shipname: "Viking Longship Vilhjalm",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/viking-river-viking-longship-vili?ship=13680",
        shipname: "Viking Longship Vili",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/viking-river-viking-hemming?ship=13623",
        shipname: "Viking Hemming",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/viking-river-viking-torgil?ship=13624",
        shipname: "Viking Torgil",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/viking-river-viking-osfrid?ship=13723",
        shipname: "Viking Osfrid",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/viking-river-viking-helgrim?ship=14072",
        shipname: "Viking Helgrim",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/viking-river-viking-astrild?ship=13673",
        shipname: "Viking Astrild",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/viking-river-viking-beyla?ship=13672",
        shipname: "Viking Beyla",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/viking-river-viking-longship-kari?ship=14509",
        shipname: "Viking Longship Kari",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/viking-river-viking-longship-radgrid?ship=14508",
        shipname: "Viking Longship Radgrid",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/viking-river-viking-skaga?ship=14510",
        shipname: "Viking Skaga",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/viking-river-viking-fjorgyn?ship=14511",
        shipname: "Viking Fjorgyn",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/viking-river-viking-aton?ship=15058",
        shipname: "Viking Aton",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/viking-river-viking-osiris?ship=14512",
        shipname: "Viking Osiris",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/viking-river-viking-ra?ship=14006",
        shipname: "Viking Ra",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/viking-river-ms-antares?ship=14155",
        shipname: "MS Antares",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/viking-river-viking-hathor?ship=15295",
        shipname: "Viking Hathor",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/viking-river-viking-sobek?ship=15319",
        shipname: "Viking Sobek",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/viking-river-viking-mississippi?ship=14835",
        shipname: "Viking Mississippi",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/viking-river-viking-saigon?ship=14949",
        shipname: "Viking Saigon",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/viking-river-viking-akun?ship=13657",
        shipname: "Viking Akun",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/viking-river-viking-emerald?ship=13339",
        shipname: "Viking Emerald",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/viking-river-viking-helgi?ship=13547",
        shipname: "Viking Helgi",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/viking-river-viking-ingvar?ship=13548",
        shipname: "Viking Ingvar",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/viking-river-viking-longship-odin?ship=13495",
        shipname: "Viking Longship Odin",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/viking-river-viking-mekong?ship=13626",
        shipname: "Viking Mekong",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/viking-river-viking-rurik?ship=13549",
        shipname: "Viking Rurik",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/viking-river-viking-sineus?ship=13634",
        shipname: "Viking Sineus",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/viking-river-viking-tonle?ship=15339",
        shipname: "Viking Tonle",
      },
      {
        resShipUrl:
          "https://res.cruisemaster-me.com/swift/cruise/ship/viking-river-viking-truvor?ship=13550",
        shipname: "Viking Truvor",
      },
    ],
  },
];
