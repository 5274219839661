import { Button } from "antd";
import "./styles.scss";
import { Fade } from "react-awesome-reveal";
import { Col, Container, Row } from "react-bootstrap";

function BookNow() {
  return (
    <div className="column-padding-t column-padding-b booking-cmp">
      <Container>
        <Row>
          <Col className="flex center flex-column mb-32">
            <div className="flex center flex-column">
              <Fade duration={2000} triggerOnce className="flex center">
                <h1 className="font-medium font-white mb-16">
                  Make It an Incredible Journey
                </h1>
                <p
                  className="font-regular font-white mb-32 text-align-center"
                  style={{ letterSpacing: "0.3px" }}
                >
                  Book now for an effortless cruise experience! Seamlessly plan
                  your dream voyage with Cruise Master. From reservation to
                  adventure, we ensure a smooth journey for an unforgettable
                  experience. Your bon voyage begins with a click!
                </p>
              </Fade>
              <Button
                type="primary"
                className="btn-small btn-primary card-button font-regular font-white"
                onClick={() =>
                  window.open("https://res.cruisemaster-me.com", "_self")
                }
                rel="noopener noreferrer"
                aria-label="Book Now Button"
              >
                <span style={{ marginBottom: "3px" }}>Plan a Cruise</span>
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default BookNow;
