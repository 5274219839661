import brocImg from "../../assets/img/broucher-thump.jpg";
import "./styles.scss";
import { FaFacebookF } from "react-icons/fa";
import { GrLinkedinOption } from "react-icons/gr";
import { Col, Container, Row } from "react-bootstrap";
import { useState } from "react";
import { Link } from "react-router-dom";
import LoginModal from "../Modals/LoginModal";
import { LuCopyright } from "react-icons/lu";
import { RiInstagramFill } from "react-icons/ri";
import { Divider } from "antd";

function Footer() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  // const showModal = () => {
  //   setIsModalOpen(true);
  // };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div className="footer">
        <div className="footer-padding">
          <Container>
            <Row>
              <Col xs={12} lg={3} md={6} className="flex start-center">
                <div style={{ width: "200px" }}>
                  <div className="flex start">
                    <h4 className="font-medium font-white footer-links mb-16">
                      USEFUL LINKS
                    </h4>
                  </div>
                  <div className="flex start">
                    {" "}
                    <a
                      className="font-white footer-links opacity-80 mb-16 no-decoration"
                      href="https://res.cruisemaster-me.com"
                      rel="noreferrer"
                      target="_self"
                    >
                      Plan a Cruise
                    </a>
                  </div>
                  <div className="flex start">
                    {" "}
                    <Link
                      to="/destinations"
                      className="font-white footer-links opacity-80 mb-16 pointer no-decoration"
                      onClick={() => {
                        setTimeout(() => {
                          window.scrollTo(0, 0);
                        }, 100);
                      }}
                    >
                      Destinations
                    </Link>
                  </div>
                  <div className="flex start">
                    {" "}
                    <Link
                      to="/cruise-lines"
                      className="font-white footer-links opacity-80 mb-16  pointer no-decoration"
                      onClick={() => {
                        setTimeout(() => {
                          window.scrollTo(0, 0);
                        }, 100);
                      }}
                    >
                      Cruise Lines
                    </Link>
                  </div>
                  <div className="flex start">
                    {" "}
                    <Link
                      to="/whycruise"
                      className="font-white footer-links opacity-80 mb-16 pointer no-decoration"
                      onClick={() => {
                        setTimeout(() => {
                          window.scrollTo(0, 0);
                        }, 100);
                      }}
                    >
                      Why Cruise
                    </Link>
                  </div>
                  <div className="flex start">
                    {" "}
                    <Link
                      to="/how_to_book"
                      className="font-white footer-links opacity-80 mb-16 pointer no-decoration"
                      onClick={() => {
                        setTimeout(() => {
                          window.scrollTo(0, 0);
                        }, 100);
                      }}
                    >
                      How to Book
                    </Link>
                  </div>
                  <div className="flex start">
                    {" "}
                    <Link
                      to="/faq"
                      className="font-white footer-links opacity-80 mb-16 pointer no-decoration"
                      onClick={() => {
                        setTimeout(() => {
                          window.scrollTo(0, 0);
                        }, 100);
                      }}
                    >
                      FAQ
                    </Link>
                  </div>
                  <div className="flex start">
                    {" "}
                    <Link
                      to="/agent_portal"
                      className="font-white footer-links opacity-80 mb-16 pointer no-decoration"
                      onClick={() => {
                        setTimeout(() => {
                          window.scrollTo(0, 0);
                        }, 100);
                      }}
                    >
                      Travel Agent Portal
                    </Link>
                  </div>
                  {/* <div className="flex start">
                    {" "}
                    <div
                      className="font-white footer-links opacity-80 mb-16 pointer no-decoration"
                      onClick={showModal}
                    >
                      Travel Agent Portal
                    </div>
                  </div> */}

                  {/* <div className="flex start">
                    {" "}
                    <Link
                      to="/cmdigest"
                      className="font-white footer-links opacity-80 mb-16 pointer no-decoration"
                      onClick={() => {
                        setTimeout(() => {
                          window.scrollTo(0, 0);
                        }, 100);
                      }}
                    >
                      Cruise Master Digest
                    </Link>
                  </div> */}

                  <div className="flex start">
                    {" "}
                    <Link
                      to="/contact_us"
                      className="font-white footer-links opacity-80 mb-16  pointer no-decoration"
                      onClick={() => {
                        setTimeout(() => {
                          window.scrollTo(0, 0);
                        }, 100);
                      }}
                    >
                      Contact Us
                    </Link>
                  </div>
                  <div className="flex start">
                    {" "}
                    <Link
                      to="/privacy_policy"
                      className="font-white footer-links opacity-80 mb-32  pointer no-decoration"
                      onClick={() => {
                        setTimeout(() => {
                          window.scrollTo(0, 0);
                        }, 100);
                      }}
                    >
                      Privacy Policy
                    </Link>
                  </div>
                </div>
              </Col>

              <Col xs={12} lg={3} md={6} className="flex start-center">
                <div style={{ width: "200px" }}>
                  <div className="flex start">
                    <h4 className="font-medium font-white footer-links mb-16">
                      POPULAR DESTINATIONS
                    </h4>
                  </div>
                  <div className="flex start">
                    {" "}
                    <a
                      className="font-white footer-links opacity-80 mb-16 no-decoration"
                      href="https://res.cruisemaster-me.com/swift/cruise/destination/europe?destinations=15&destinationtype=All&destinationorriverid=destinationId"
                      rel="noreferrer"
                      target="_self"
                    >
                      Europe
                    </a>
                  </div>
                  <div className="flex start">
                    {" "}
                    <a
                      className="font-white footer-links opacity-80 mb-16 no-decoration"
                      href="https://res.cruisemaster-me.com/swift/cruise/destination/alaska-?destinations=1&destinationtype=All&destinationorriverid=destinationId"
                      rel="noreferrer"
                      target="_self"
                    >
                      Alaska
                    </a>
                  </div>
                  <div className="flex start">
                    {" "}
                    <a
                      className="font-white footer-links opacity-80 mb-16 no-decoration"
                      href="https://res.cruisemaster-me.com/swift/cruise/destination/asia-and-asia-pacific?destinations=19&destinationtype=All&destinationorriverid=destinationId"
                      rel="noreferrer"
                      target="_self"
                    >
                      Asia
                    </a>
                  </div>
                  <div className="flex start">
                    {" "}
                    <a
                      className="font-white footer-links opacity-80 mb-16 no-decoration"
                      href="https://res.cruisemaster-me.com/swift/cruise/destination/caribbean?destinations=9&destinationtype=All&destinationorriverid=destinationId"
                      rel="noreferrer"
                      target="_self"
                    >
                      Caribbean
                    </a>
                  </div>
                  <div className="flex start">
                    {" "}
                    <a
                      className="font-white footer-links opacity-80 mb-16 no-decoration"
                      href="https://res.cruisemaster-me.com/swift/cruise/destination/arabian-gulf?destinations=54&destinationtype=All&destinationorriverid=destinationId"
                      rel="noreferrer"
                      target="_self"
                    >
                      Arabian Gulf
                    </a>
                  </div>
                  <div className="flex start">
                    {" "}
                    <a
                      className="font-white footer-links opacity-80 mb-16 no-decoration"
                      href="https://res.cruisemaster-me.com/swift/cruise/destination/australia-new-zealand?destinations=29&destinationtype=All&destinationorriverid=destinationId"
                      rel="noreferrer"
                      target="_self"
                    >
                      Australia & New Zealand
                    </a>
                  </div>
                  <div className="flex start">
                    {" "}
                    <a
                      className="font-white footer-links opacity-80 mb-32 no-decoration"
                      href="https://res.cruisemaster-me.com/swift/cruise/destination/south-america?destinations=32&destinationtype=All&destinationorriverid=destinationId"
                      rel="noreferrer"
                      target="_self"
                    >
                      South America
                    </a>
                  </div>
                </div>
              </Col>
              <Col xs={12} lg={3} md={6} className="flex start-center">
                <div style={{ width: "200px" }}>
                  <div className="flex start">
                    <h4 className="font-medium font-white footer-links mb-16">
                      POPULAR CRUISE LINES
                    </h4>
                  </div>
                  <div className="flex start">
                    {" "}
                    <Link
                      to="/major_cruise_lines/princess"
                      className="font-white footer-links opacity-80 mb-16 pointer no-decoration"
                      onClick={() => {
                        setTimeout(() => {
                          window.scrollTo(0, 0);
                        }, 100);
                      }}
                    >
                      Princess
                    </Link>
                  </div>
                  <div className="flex start">
                    {" "}
                    <Link
                      to="/major_cruise_lines/cunard"
                      className="font-white footer-links opacity-80 mb-16 pointer no-decoration"
                      onClick={() => {
                        setTimeout(() => {
                          window.scrollTo(0, 0);
                        }, 100);
                      }}
                    >
                      Cunard
                    </Link>
                  </div>
                  <div className="flex start">
                    {" "}
                    <Link
                      to="/major_cruise_lines/pnocruises"
                      className="font-white footer-links opacity-80 mb-16 pointer no-decoration"
                      onClick={() => {
                        setTimeout(() => {
                          window.scrollTo(0, 0);
                        }, 100);
                      }}
                    >
                      P&O Cruises
                    </Link>
                  </div>
                  <div className="flex start">
                    {" "}
                    <Link
                      to="/other_cruise_lines/windstar_cruise"
                      className="font-white footer-links opacity-80 mb-16 pointer no-decoration"
                      onClick={() => {
                        setTimeout(() => {
                          window.scrollTo(0, 0);
                        }, 100);
                      }}
                    >
                      Windstar Cruises
                    </Link>
                  </div>
                  <div className="flex start">
                    {" "}
                    <Link
                      to="/other_cruise_lines/celestyal_cruises"
                      className="font-white footer-links opacity-80 mb-16 pointer no-decoration"
                      onClick={() => {
                        setTimeout(() => {
                          window.scrollTo(0, 0);
                        }, 100);
                      }}
                    >
                      Celestyal Cruises
                    </Link>
                  </div>
                  <div className="flex start">
                    {" "}
                    <Link
                      to="/major_cruise_lines/norwegiancruiseline"
                      className="font-white footer-links opacity-80 mb-16 pointer no-decoration"
                      onClick={() => {
                        setTimeout(() => {
                          window.scrollTo(0, 0);
                        }, 100);
                      }}
                    >
                      Norwegian Cruise Line
                    </Link>
                  </div>
                  <div className="flex start">
                    {" "}
                    <Link
                      to="/major_cruise_lines/royalcaribbeaninternational"
                      className="font-white footer-links opacity-80 mb-16 pointer no-decoration"
                      onClick={() => {
                        setTimeout(() => {
                          window.scrollTo(0, 0);
                        }, 100);
                      }}
                    >
                      Royal Caribbean International
                    </Link>
                  </div>
                  <div className="flex start">
                    {" "}
                    <Link
                      to="/other_cruise_lines/celebrity"
                      className="font-white footer-links opacity-80 mb-16 pointer no-decoration"
                      onClick={() => {
                        setTimeout(() => {
                          window.scrollTo(0, 0);
                        }, 100);
                      }}
                    >
                      Celebrity Cruises
                    </Link>
                  </div>
                  <div className="flex start">
                    {" "}
                    <Link
                      to="/major_cruise_lines/disneycruiseline"
                      className="font-white footer-links opacity-80 mb-16 pointer no-decoration"
                      onClick={() => {
                        setTimeout(() => {
                          window.scrollTo(0, 0);
                        }, 100);
                      }}
                    >
                      Disney Cruise Line
                    </Link>
                  </div>
                  <div className="flex start">
                    {" "}
                    <Link
                      to="/major_cruise_lines/msccruises"
                      className="font-white footer-links opacity-80 mb-32 pointer no-decoration"
                      onClick={() => {
                        setTimeout(() => {
                          window.scrollTo(0, 0);
                        }, 100);
                      }}
                    >
                      MSC Cruises
                    </Link>
                  </div>
                </div>
              </Col>

              <Col xs={12} lg={3} md={6} className="flex start-center">
                <div style={{ width: "200px" }}>
                  {/* <div className="flex start">
                    <h4
                      className="font-medium font-white footer-links"
                      style={{ marginBottom: "25px" }}
                    >
                      CRUISE HOLIDAYS 2024
                    </h4>
                  </div> */}
                  <div className="flex start">
                    <div className="cruise-main-brouchre mb-32">
                      <a
                        target="_blank"
                        href="https://cruisemaster-me.com/ebrochure/cruise-holidays-2024"
                        rel="noreferrer"
                      >
                        <img src={brocImg} width="150px" alt="" />
                      </a>
                    </div>
                  </div>
                  <div className="flex flex-column start-center">
                    <h4 className="font-medium font-white footer-links mb-16">
                      FOLLOW US
                    </h4>
                    <div className="social-links flex  space-between full-width">
                      <a
                        className="font-regular"
                        href="https://www.facebook.com/mycruisemaster/"
                        rel="noreferrer"
                        target="_blank"
                      >
                        <FaFacebookF className="font-white" />
                      </a>
                      <a
                        className="font-regular"
                        href="https://www.instagram.com/mycruisemaster/"
                        rel="noreferrer"
                        target="_blank"
                      >
                        <RiInstagramFill className="font-white" />
                      </a>
                      <a
                        target="_blank"
                        href="https://www.linkedin.com/company/cruise-master---middle-east"
                        rel="noreferrer"
                        className="linkedin"
                      >
                        <GrLinkedinOption className="font-white" />
                      </a>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <Divider className="m-0 bg-white opacity80" />
        <div className="flex center p-24">
          <div className="font-white font-regular text-align-center pointer smaller-texts no-decoration mb-0 opacity80">
            <LuCopyright
              style={{ marginBottom: "2.5px", marginRight: "2px" }}
            />
            {new Date().getFullYear()} Cruise Master LLC - All rights reserved
          </div>
        </div>
      </div>
      <LoginModal isModalOpen={isModalOpen} handleCancel={handleCancel} />
    </>
  );
}

export default Footer;
