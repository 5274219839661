import { type CollapseProps } from "antd";
import { RiAnchorFill } from "react-icons/ri";

const FaqItemPanel = ({ item }: { item: string }) => (
  <div className="font-regular font-black opacity80 bold-500 description-text opacity80 faq-heading">
    <RiAnchorFill
      className="font-primary"
      style={{ marginBottom: "3px", marginRight: "15px",fontSize:"16px" }}
    />
    {item}
  </div>
);

export const FaqItemsGeneral: CollapseProps["items"] = [
  // General
  {
    key: "1",
    label: (
      <FaqItemPanel item="Do I have to participate in scheduled activities, or can I just relax?" />
    ),
    children: (
      <>
        <p className="font-faded-black  opacity80">
          There are plenty of activities going on all over the ship every day.
          And the best ones can be "doing nothing at all". Relaxation is what
          attracts many people to cruise. Every ship has one or more pools that
          you can lay around with a good book, most have a library and several
          lounges where you can sit and relax by yourself or with friends.
        </p>
      </>
    ),
  },
  {
    key: "2",
    label: <FaqItemPanel item="Will there be people like me?" />,
    children: (
      <p className="font-faded-black  opacity80">
        No matter what you've heard to the contrary, there's no such thing as a
        typical cruise passenger! All kinds of people take cruises now...all
        ages...from all walks of life...singles, couples and families. Just ask
        your travel agent about what the best ship is for you based on your
        tastes and lifestyle.
      </p>
    ),
  },
  {
    key: "3",
    label: <FaqItemPanel item="Is there a chance I might get bored?" />,
    children: (
      <p className="font-faded-black  opacity80">
        That is very unlikely! However, if you are concerned about having enough
        to do, choose one of the larger ships (40,000 tons and above), they will
        typically have more activities due to the sheer numbers of people on
        board. Most ships have dozens of planned and spontaneous activities
        going from sunrise to way past midnight. Pool activities, sports, bingo,
        casino, wine tastings, dance lessons, dancing, karaoke, live
        entertainment, movies, television, fitness centers, shore excursions,
        talent contests, masquerade parties, Captain's party, country western
        night, just to name a few!
      </p>
    ),
  },
  {
    key: "4",
    label: (
      <FaqItemPanel item="What documents do I need to bring for the cruise?" />
    ),
    children: (
      <>
        <p className="font-faded-black  opacity80">
          You will require a valid passport with necessary visa for destinations
          in your cruise itinerary. Requirements may vary for different
          nationality and we recommend you should contact the nearest
          representative embassies or consulates for proper information and
          guidance. It is YOUR to provide failing which you may be denied
          boarding and 100% penalty will be applied by the cruise line. Cruise
          Master Middle East will not be held responsible for any passengers who
          are denied boarding due to lack of proper documents.
        </p>
      </>
    ),
  },
  {
    key: "5",
    label: <FaqItemPanel item="Is cruising suitable for honeymooners?" />,
    children: (
      <p className="font-faded-black  opacity80">
        Without a doubt. Cruising offers an atmosphere that's just right for
        romance...cozy dinners for two, strolling on deck at sunset, dancing the
        night away (even under the stars) and so much more to remember forever.
      </p>
    ),
  },
  {
    key: "6",
    label: (
      <FaqItemPanel item="What should I know about hurricane season and cruising?" />
    ),
    children: (
      <p className="font-faded-black  opacity80">
        Officially, the National Hurricane Service states that hurricane season
        begins June 1 and ends November 31. This makes up 6 months of the year.
        The greatest amount of hurricane and tropical storm activity in the
        Caribbean is between late August and mid-October. Ships are equipped
        with state of the art weather equipment that keep the Captain and his
        crew aware of storm position and the latest changes in weather
        conditions. If the storm poses any danger to the ship, the Captain will
        reroute the ship to a destination in order to avoid the storm. The
        passenger's safety is of the utmost importance to the Captain & his
        crew.
      </p>
    ),
  },
  {
    key: "7",
    label: <FaqItemPanel item="What should I pack for the trip?" />,
    children: (
      <p className="font-faded-black  opacity80">
        <p>
          Planning what to wear on your cruise holiday is easy if you keep in
          mind three types of clothing; casual shipboard attire or day wear;
          resort wear for sightseeing or shopping; and evening wear. On a
          7-nights cruise there are two formal nights, one informal night and
          four casual nights. For evening wear allow for:
        </p>
        <p>
          Casual Nights - sports shirt and trousers for men, trousers and top or
          skirt and blouse for women
        </p>
        <p>
          Informal Nights - dress or trouser suit for women, jacket, shirt and
          tie are requested for men
        </p>
        <p>
          Formal Nights - cocktail or evening dress for women, dark suit and tie
          or tuxedo for men.
        </p>
        <p>
          Medication Your prescription medication should be kept close at hand
          in your purse or carry on bag rather than in your checked luggage. Be
          sure to bring a sufficient supply of medication and transport it in
          the original container. It is also a good idea to carry a written list
          of your medications in case they are lost. The list should include the
          name of the drug, dosage, and times taken.
        </p>
      </p>
    ),
  },
  {
    key: "8",
    label: <FaqItemPanel item="What are my luggage allowances?" />,
    children: (
      <>
        <p className="font-faded-black  opacity80">
          There is no limit on how much luggage can be taken on the ship, though
          careful consideration should be given to cabin space available for
          storing. All luggage has to be kept in guests cabin(s).
        </p>
      </>
    ),
  },
  {
    key: "9",
    label: <FaqItemPanel item="What should I do if I get seasick?" />,
    children: (
      <p className="font-faded-black  opacity80">
        It is unlikely that you will notice any motion on a large cruise ship,
        even if you are sensitive to motion in automobiles or airplanes. All
        ships today have stabilizers to smooth the ride. Some cruises sail
        calmer waters than others. Caribbean and Alaska cruises enjoy smoother
        sailings than say Bermuda. If seas do get rough, there are many
        preventative medications available on board.
      </p>
    ),
  },
];
export const FaqItemsFamily: CollapseProps["items"] = [
  // Family and Children
  {
    key: "10",
    label: (
      <FaqItemPanel item="What activities are available for children on the cruise?" />
    ),
    children: (
      <p className="font-faded-black  opacity80">
        Most cruise lines offer programs for children. Friendly and professional
        children's counselors will keep your kids on the go from morning to
        night. Action-packed children's programs are available on most ships,
        but facilities vary. Many offer special teen and pre-teen scheduled
        activities. The larger ships almost always include a video arcade, kids
        disco or other designated "kids area". Cruises are a great way for
        families to travel and see a variety of countries and ports. Some ships
        also offer babysitting services and daycare centers at a nominal charge.
      </p>
    ),
  },
  {
    key: "11",
    label: (
      <FaqItemPanel item="How are cruise lines accommodating families with kids?" />
    ),
    children: (
      <>
        <p className="font-faded-black  opacity80">
          More and more families with children are taking a cruise vacations.
          Most cruise lines provide plenty of supervised activities for
          youngsters, especially during school holidays. If your children enjoy
          swimming, sports, games, movies, and the adventure of new places,
          they'll love a family cruise. You'll find that children adapt to
          shipboard life with ease, and you won't have to wonder what they're up
          to every minute. The youth counselors will help keep them busy and
          entertained. Ships even offer youth camps that cater to all ages, from
          toddlers to teens. Best of all, children generally travel at a reduced
          rate.
        </p>
      </>
    ),
  },
  {
    key: "12",
    label: <FaqItemPanel item="What are the age restrictions for guests?" />,
    children: (
      <p className="font-faded-black  opacity80">
        Minimum age for most cruise lines is twenty-one (21). Exceptions are
        minor children sailing with the parents or guardians in adjacent
        staterooms. Under-age married couple will require proof of marriage at
        the time of check-in.
      </p>
    ),
  },
  {
    key: "13",
    label: (
      <FaqItemPanel item=" What should pregnant guests know about cruising?" />
    ),
    children: (
      <p className="font-faded-black  opacity80">
        Most cruise lines will require a medical certificate establishing your
        due date and fitness to travel, the cruise lines will make every effort
        to accommodate you. Please inform your cruise specialist of your
        condition so that they may make you aware of any other conditions or
        restrictions that may apply by each individual cruise line.
      </p>
    ),
  },
];
export const CostAndPayments: CollapseProps["items"] = [
  //Cost and payments
  {
    key: "14",
    label: (
      <FaqItemPanel item="What are the costs associated with the cruise?" />
    ),
    children: (
      <p className="font-faded-black  opacity80">
        One of the most attractive features of a cruise vacation is the price.
        Even most expensive cruises are a good value for the money. You can
        spend anywhere from $400 for a 3-night Bahamas cruise to $30,000 for a
        suite on a 30-day cruise to Africa!No matter how much you spend, you pay
        one price that covers virtually everything: meals, accommodations,
        taxes, on-board entertainment. The only extras are shore excursions, spa
        treatment, liquor, tips, photos etc.
      </p>
    ),
  },
  {
    key: "15",
    label: (
      <FaqItemPanel item="What amenities and services are included in the cruise price?" />
    ),
    children: (
      <p className="font-faded-black  opacity80">
        <p>Just about everything! Cruise Price includes:</p>
        <ul>
          <li>
            Selected accommodation on board - all cabins have en-suite
            facilities.
          </li>
          <li>All meals on board.</li>
          <li>Applicable taxes.</li>
          <li>
            Most on board activities, themed lectures and culinary
            demonstrations.
          </li>
          <li>Most cruise lines have children's club at no extra cost.</li>
          <li>Cabin attendant and room service available 24 hours a day.</li>
          <li>Entertainment including shows, live bands and nightclub.</li>
          <li>
            Fitness centre, fitness classes, sauna, Jacuzzi and steam room.
          </li>
          <li>Captain's cocktail party and gala buffet.</li>
        </ul>
      </p>
    ),
  },
  {
    key: "16",
    label: <FaqItemPanel item="How can I pay for purchases made onboard?" />,
    children: (
      <p className="font-faded-black  opacity80">
        Most cruise lines operate on a 'cashless' system. Simply validate your
        boarding card account card with an acceptable credit or charge card
        (Most major credits are accepted) or sign for payment if paying by cash
        or traveller's cheques. You can then sign your on board services and
        purchases with this card. At the end of your cruise you will receive an
        itemised statement. Personal cheques are not accepted on board.
      </p>
    ),
  },
  {
    key: "17",
    label: <FaqItemPanel item="How does tipping work on the cruise?" />,
    children: (
      <p className="font-faded-black  opacity80">
        <p>
          Tipping is up to you. However, it is customary to offer gratuities to
          staff members you feel have given you exceptional service and personal
          attention. Generally speaking, a daily total of say USD 10 per day per
          guest should take care of your waiter, bus boy and cabin steward.
          There are a few cruise lines that have a "no tipping" policy.
        </p>
        <p>
          On the last night of the cruise an envelope will be left in your cabin
          in which any gratuities should be placed and passed to the relevant
          member of staff. Alternatively, you may pre-pay your gratuities during
          the booking process and vouchers will then be provided in your cabin
          for you to pass onto the relevant members of staff.
        </p>
        <p>
          A standard gratuity of 15% is automatically added to the price of
          drinks from the bar and beauty treatments.
        </p>
      </p>
    ),
  },
];
export const SpecialRequests: CollapseProps["items"] = [
  // /Dining and Special Requests
  {
    key: "18",
    label: <FaqItemPanel item="When and where can I dine on the ship?" />,
    children: (
      <>
        <p className="font-faded-black  opacity80">
          Although Cruise Lines offer open seating for breakfast and lunch,
          there are two seating for Dinner - approximately at 6:00 PM and 8:00
          PM. You may request either sitting, as well as table size and with
          whom you wish to sit. All such requests should be made in advance of
          sailing through the travel agent. Requests for early or late sitting
          preference will be confirmed or waitlisted at the time of booking.
          Table numbers and table size are on request only and will be assigned
          by the Maitre d' on board. Most major cruise lines now offer meals in
          alternative locations. A room service menu is also available 24 hours
          a day. PLEASE NOTE: These preferences are assigned on a first-come
          first-serve basis.
        </p>
      </>
    ),
  },
  {
    key: "19",
    label: (
      <FaqItemPanel item="How do I request special dining arrangements?" />
    ),
    children: (
      <p className="font-faded-black  opacity80">
        The cruise lines are happy to meet your requests with low sodium, low
        fat, low sugar, vegetarian and kosher meals if they are notified at
        least 60 days before sailing. While we shall do our utmost to fulfill
        all requests, agricultural and perishability restrictions may apply.
        Kosher meals are prepared off the ship in a Kosher kitchen, frozen and
        brought to the table sealed in their original containers.
      </p>
    ),
  },
];
export const Boarding: CollapseProps["items"] = [
  // /Boarding and Departure
  {
    key: "20",
    label: <FaqItemPanel item="How does embarkation work?" />,
    children: (
      <p className="font-faded-black  opacity80">
        On the day of the cruise, you may board around midday according to the
        cruise line. Simply present your cruise documents at the cruise
        terminal. You will be required to complete necessary forms for boarding.
        This can be completed online on cruise line's website. Upon check-in,
        you will be given your boarding card which is generally like the size of
        a credit card - this is your identification card for re-boarding the
        ship, your cabin key, your charge credit card for on board purchases as
        well as has information on your seating and table number for meals. Your
        luggage will be delivered to our cabin usually within two hours after
        boarding. This also depends on the size of ship as well. <br />
        <br />
        <b>Reminder:</b> You should board the ship at least two hours prior to
        ship's scheduled sailing time failing which your booking may be
        cancelled and 100% cancellation penalty applied.
      </p>
    ),
  },
  {
    key: "21",
    label: <FaqItemPanel item="What is the procedure for disembarkation?" />,
    children: (
      <p className="font-faded-black  opacity80">
        On the last day of the cruise, the ship will arrive in the port between
        7:00 & 8:00 am. Disembarkation starts after the ship has been cleared by
        customs. This is usually between 8:30 & 9:00 am. Guests are disembarked
        at different times and schedules as per the colour of luggage tags
        provided by ship. Early disembarkation needs should be discussed at the
        time of booking and reconfirmed on-board the ship. Guests who have
        purchased tours or transfer from cruise lines are given 1st priority on
        disembarkation.
      </p>
    ),
  },
];
export const Stateroom: CollapseProps["items"] = [
  //Stateroom and Facilities
  {
    key: "22",
    label: (
      <FaqItemPanel item="What amenities are provided in the staterooms?" />
    ),
    children: (
      <p className="font-faded-black  opacity80">
        <p>
          All staterooms have twin beds which can be converted into one
          queen-size bed, private bathroom, vanity area, television, radio and
          phone.
        </p>
        <ul>
          <li>Electricity Voltage is 110/220 volts on all ships.</li>
          <li>Hair Dryers are available in all cabins on all ships.</li>
          <li>Safes are available in staterooms on most ships and</li>
          <li>Safety deposit boxes are available at the Purser's Desk.</li>
        </ul>
      </p>
    ),
  },
  {
    key: "23",
    label: (
      <FaqItemPanel item="Are electric appliances allowed in the staterooms?" />
    ),
    children: (
      <p className="font-faded-black  opacity80">
        Most staterooms on all cruise lines are equipped with standard 110 AC
        outlets. Additionally, most staterooms are equipped with 220 AC outlets.
        If your shaver operates on standard 110 AC, by all means bring it. You
        may wish to bring a clock and an extension cord. Hairdryers are
        available on some cruise lines in certain staterooms.
      </p>
    ),
  },
  {
    key: "24",
    label: (
      <FaqItemPanel item="Are laundry and dry-cleaning services available?" />
    ),
    children: (
      <>
        <p className="font-faded-black  opacity80">
          Take a vacation from the washing and ironing and let the professional
          laundry service do all the work for you. Just put everything in the
          capable hands of your stateroom steward or stewardess who will have
          everything returned within 48 hours, or even the same day with the
          express service offered by all cruise lines.
        </p>
      </>
    ),
  },
  {
    key: "25",
    label: <FaqItemPanel item="Is there a medical facility on the ship?" />,
    children: (
      <p className="font-faded-black  opacity80">
        On most ships, there is a medical centre on board with well-trained
        medical staff to handle emergencies. Ship doctors and nurses keep daily
        office hours and are always available for emergencies. However, there is
        a charge for all medical services and adequate medical insurance is
        recommended. If you suffer from a medical condition, check with your
        travel agent before booking to make sure the ship on which you are
        cruising can handle your needs.
      </p>
    ),
  },
];
export const Communication: CollapseProps["items"] = [
  //Communication
  {
    key: "26",
    label: <FaqItemPanel item="How can I call home from the ship?" />,
    children: (
      <p className="font-faded-black  opacity80">
        Many of the newer or recently refurbished ships provide Ship to Shore
        telephone service via satellite. On some ships you can call from your
        stateroom. Ship to Shore rates apply so you may want to limit your
        conversations.
      </p>
    ),
  },
  {
    key: "27",
    label: <FaqItemPanel item="Can I send or receive emails while onboard?" />,
    children: (
      <p className="font-faded-black  opacity80">
        Many of the newer ships do have a business center or an internet centre
        for guests to send and receive e-mail. You must have your own web based
        email account to use this service.
      </p>
    ),
  },
];
export const Entartainment: CollapseProps["items"] = [
  //Entertainment and Activities
  {
    key: "28",
    label: (
      <FaqItemPanel item="Does the entertainment onboard have an additional cost?" />
    ),
    children: (
      <>
        <p className="font-faded-black  opacity80">
          The entertainment is on the house. There's no cover. No minimum. No
          charge for an admission ticket. The shows are live. The movies are
          first-rate. The variety is limitless.
        </p>
      </>
    ),
  },
  {
    key: "29",
    label: (
      <FaqItemPanel item="What options are available for celebrating special occasions?" />
    ),
    children: (
      <p className="font-faded-black  opacity80">
        If you are celebrating a birthday, anniversary or honeymoon during your
        cruise, some cruise lines will be happy to honor the event with a
        special cake in the dining room. Please notify your cruise specialist no
        later than 4 weeks prior to sailing.
      </p>
    ),
  },
  {
    key: "30",
    label: <FaqItemPanel item="How can I go sightseeing when in port?" />,
    children: (
      <p className="font-faded-black  opacity80">
        <p>
          Your ship will typically arrive in port in the morning at which time
          you are free to go ashore and return back to ship at least 30minutes
          before the ship sails. This gives you more than enough time to enjoy
          one of the cruise lines' shore excursions, or, go off on your own to
          do some shopping, sightseeing, swimming, scuba diving, etc.
        </p>
        <p>
          We recommend the cruise ship sponsored tours and excursions. In some
          ports, when you debark from the ship, local sightseeing companies (in
          some cases a local with a van) will be waiting to temp you with their
          own tour. While these tours may be safe and in some cases quite good,
          it is always a safe bet to stick with your cruise ship's
          recommendation.
        </p>
        <p>
          You can pre book and pay your shore excursions in advance via cruise
          line's web site. In some popular places, space is limited and we
          recommend pre-ordering to avoid disappointment.
        </p>
      </p>
    ),
  },
];
export const SpecialNeeds: CollapseProps["items"] = [
  //Special Needs
  {
    key: "31",
    label: (
      <FaqItemPanel item="How does the cruise accommodate guests with disabilities?" />
    ),
    children: (
      <>
        <p className="font-faded-black  opacity80">
          <p>
            A majority of cruise lines offer staterooms accessible for
            passengers whether physically challenged or able-bodied. In order to
            accommodate your special needs, please advise one of our cruise
            specialists with complete details of your requirements.
          </p>
          <p>
            Ships are now built to be wheelchair accessible and can provide
            special seating arrangements to avoid stairs and raised areas in the
            dining rooms.
          </p>
          <p>
            If you require the use of a wheelchair, please make your own private
            arrangements prior to your cruise as the limited number of
            wheelchairs onboard are for emergency use only. For your
            convenience, we highly recommend collapsible wheelchairs as the
            width of stateroom doors varies from ship to ship. Should you
            require further information on stateroom dimensions, please ask one
            of our cruise specialists who will be happy to assist you.
          </p>
          <p>
            In some ports of call ships are required to anchor the ship and
            transport passengers to the pier via ship's tender. Conditions, even
            when the ship is docked, may also limit pier accessibility for
            physically challenged passengers. The decision to permit or prohibit
            passengers from going ashore will be made on each occasion by the
            ship's Captain, with your safety and comfort in mind, and his
            decision is final. Since cruise ships are not staffed with specially
            trained personnel to assist passengers with physical limitations, we
            recommend that you be accompanied by someone who is able to assist
            you both ashore and aboard if necessary.
          </p>
        </p>
      </>
    ),
  },
];
export const Gifts: CollapseProps["items"] = [
  // /Gifts and Packages
  {
    key: "32",
    label: (
      <FaqItemPanel item="What bon voyage gifts and gift packages are available?" />
    ),
    children: (
      <p className="font-faded-black  opacity80">
        If you would like to send a bon voyage gift to one of your fellow guests
        or set up your own private bon voyage toasting, you may certainly do so.
        Your travel agent has information about gift order items, which include
        a variety of alcoholic and nonalcoholic beverages, gift baskets,
        clothing, gifts for children, casino chips and flowers at very
        reasonable prices.
      </p>
    ),
  },
];
export const BookingAndFees: CollapseProps["items"] = [
  //Booking and Fees
  {
    key: "33",
    label: <FaqItemPanel item="What is the fee for changing my booking?" />,
    children: (
      <p className="font-faded-black  opacity80">
        Changing your itinerary after reservations have been made can result in
        loss of advantages gained by early planning. In addition, the results of
        last-minute changes may be disappointing. To cover administrative costs,
        a charge of up to US $50 per person will be made if you request a change
        in your travel arrangements after the final payment due date. This
        charge is in addition to any charges levied by the cruise line. Change
        charges are not assessed for stateroom upgrades or for the addition of
        services.
      </p>
    ),
  },
];
